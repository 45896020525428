import React, { useEffect, useState, Suspense } from "react";

import { useStore } from "../../../store";
import { Loading, Footer } from "../../../components";
import Container from "src/components/shared/Container";

import styles from "./home.module.css";

const HomeComponents = {
  Header: React.lazy(() => import("../../../components/pages/home/Header")),
  PopularCourses: React.lazy(
    () => import("../../../components/pages/home/PopularCourses")
  ),
  UpcomingCohorts: React.lazy(
    () => import("../../../components/pages/home/UpcomingCohorts")
  ),
  Partners: React.lazy(() => import("../../../components/pages/home/Partners")),
  Topics: React.lazy(() => import("../../../components/pages/home/Topics")),
  Steps: React.lazy(() => import("../../../components/pages/home/Steps")),
  Feedbacks: React.lazy(
    () => import("../../../components/pages/home/Feedbacks")
  ),
  Questions: React.lazy(
    () => import("../../../components/pages/home/Questions")
  ),
  BecomeHost: React.lazy(
    () => import("../../../components/pages/home/BecomeHost")
  ),
  BecomeTeacher: React.lazy(
    () => import("../../../components/pages/home/BecomeTeacher")
  ),
  Blogs: React.lazy(() => import("../../../components/pages/home/blogs")),
  BecomeMember: React.lazy(
    () => import("../../../components/pages/home/BecomeMember")
  ),
};

export default function Home() {
  const { userStore, courseStore } = useStore();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const domains = courseStore.getDomains();
    const categories = courseStore.getCategories();
    const topRatedCourses = courseStore.getTopRatedCourses(8);
    const upcomingSessions = courseStore.getUpcomingSessions();
    const user = userStore.user
      ? Promise.resolve(userStore.user)
      : userStore.getUser();
    await Promise.all([
      domains,
      categories,
      topRatedCourses,
      upcomingSessions,
      user,
    ]);
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <HomeComponents.Header />
        <Container>
          <main className={styles.container}>
            <HomeComponents.PopularCourses inSearch={false} />
            <hr />
            <HomeComponents.UpcomingCohorts page="home" />
            <HomeComponents.Partners />
            <HomeComponents.Topics />
            <HomeComponents.Steps />
            <HomeComponents.Feedbacks />
            <HomeComponents.Questions />
            <HomeComponents.BecomeHost />
            <HomeComponents.BecomeTeacher />
            <HomeComponents.Blogs />
            <hr />
            <HomeComponents.BecomeMember />
          </main>
        </Container>
        <Footer />
      </Suspense>
    </>
  );
}
