import React, { useEffect, useMemo } from "react";

import styles from "./blogs.module.css";
import Blog from "../BlogItem";
import { Button } from "src/components/shared";
import { useStore } from "src/store";
import { useTranslation } from "react-i18next";

const Blogs: React.FC = () => {
  const { t } = useTranslation();
  const { courseStore } = useStore();

  const [isBlogsLoaded, setIsBlogsLoaded] = React.useState(false);
  const [blogsList, setBlogsList] = React.useState<any[]>([]);

  useEffect(() => {
    const fetchBlogs = () => {
      courseStore
        .getRecentBlogs()
        .then((res) => {
          if (res) {
            setBlogsList(res);
            setIsBlogsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setBlogsList([]);
        });
    };

    fetchBlogs();
  }, [courseStore]);

  const memoizedBlogsList = useMemo(() => blogsList, [blogsList]);

  const renderSkeleton = () => (
    <div  className={styles.blogs_list}>
      {[...Array(4)].map((_, index) => (
        <div key={index} className="flex flex-col space-y-4">
          <div className="w-full h-56 bg-gray-200 animate-pulse rounded-lg"></div>
          <div className="space-y-2">
            <div className="w-2/3 h-4 bg-gray-200 animate-pulse rounded"></div>
            <div className="w-1/2 h-4 bg-gray-200 animate-pulse rounded"></div>
            <div className="w-1/4 h-4 bg-gray-200 animate-pulse rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className={styles.blogs}>
        <div className="text-body-large font-body-large-bold sm:text-subheading flex justify-between items-center">
          <h2>{t("blogs.heading")}</h2>
          <a
            href="https://blog.classx.tech/"
            target="_blank"
            className="hidden sm:block rounded-full overflow-hidden"
            rel="noreferrer"
          >
            <Button
              type="secondary"
              text={t("blogs.viewMore")}
              width="fit-content"
              customStyle={{ height: "50px" }}
            />
          </a>
        </div>

        {!isBlogsLoaded && renderSkeleton()}

        {isBlogsLoaded && (
          <div className={styles.blogs_list}>
            {memoizedBlogsList.map((blog, index) => (
              <Blog key={index} blog={blog} />
            ))}
          </div>
        )}
        <a
          href="https://blog.classx.tech/"
          target="_blank"
          className="block sm:hidden"
          rel="noreferrer"
        >
          <Button
            type="secondary"
            text={t("blogs.viewMore")}
            customStyle={{ height: "50px" }}
          />
        </a>
      </div>
    </>
  );
};

export default Blogs;
