import React from "react";
import Modal from "react-modal";

interface InputProps {
  name: string;
  location: string;
  equipments: string[];
  isOpen: boolean;
  closePopup: () => void;
}

const Popup: React.FC<InputProps> = ({
  name,
  location,
  equipments,
  isOpen,
  closePopup,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={closePopup} style={modalStyles}>
      <div className="w-[350px] sm:w-[478px]">
        <div className="flex justify-between items-center px-5 py-3 border-b border-primary-gray-100 text-primary-purple-800">
          <h3 className="body-medium-bold">Amenities</h3>
          <img
            className="cursor-pointer"
            src="/images/icons/close.svg"
            onClick={closePopup}
            width={40}
            height={40}
          />
        </div>

        <div className="flex flex-col gap-2 p-4 sm:p-5">
          <div>
            <div className="body-medium">{name}</div>
            <div className="caption-text flex items-center gap-1.5">
              <img
                src="/images/icons/location-purple.svg"
                width={14}
                height={14}
              />
              <span>{location}</span>
            </div>
          </div>

          <div className="flex flex-wrap gap-1.5 pt-4 border-t border-primary-gray-200">
            {equipments.map((equipment) => (
              <div
                key={equipment}
                className="body-small px-5 py-2 text-primary-purple-800 bg-primary-purple-100 border border-primary-purple-200 rounded-full"
              >
                {equipment}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "24px",
    border: "1px solid var(--Primary-Gray-100, rgba(245, 245, 245, 1))",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "var(--Primary-Gray-50)",
    // margin: "20px",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: "30",
  },
};

export default Popup;
