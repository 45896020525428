import { useEffect, useState } from "react";
import { Button, RangeSlider } from "src/components/shared";

interface PrincingFilterProps {
  priceRange: number[];
  pricePerHour: boolean;
  percentagePerStudent: boolean;
  setPriceRange: (range: number[]) => void;
  setPricePerHour: (pricePerHour: boolean) => void;
  setPercentagePerStudent: (percentagePerStudent: boolean) => void;
  resetPricingFilter: () => void;
  handlePricingFilterDone: () => void;
}
const PrincingFilter = ({
  priceRange,
  pricePerHour,
  percentagePerStudent,
  setPriceRange,
  setPricePerHour,
  setPercentagePerStudent,
  resetPricingFilter,
  handlePricingFilterDone,
}: PrincingFilterProps) => {
  return (
    <div>
      <h4 className="body-small-bold mb-3.5 text-primary-paradise-pink-800">
        Classroom Pricing
      </h4>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <input
              className="cursor-pointer"
              id="pricePerHour"
              type="checkbox"
              checked={pricePerHour}
              onChange={() => setPricePerHour(!pricePerHour)}
            />
            <label
              className="body-medium text-primary-gray-600 cursor-pointer"
              htmlFor="pricePerHour"
            >
              Price / hour listings
            </label>
          </div>

          <div className="flex items-center gap-3">
            <input
              className="cursor-pointer"
              id="percentagePerStudent"
              type="checkbox"
              checked={percentagePerStudent}
              onChange={() => setPercentagePerStudent(!percentagePerStudent)}
            />
            <label
              className="body-medium text-primary-gray-600 cursor-pointer"
              htmlFor="percentagePerStudent"
            >
              Percentage / student listings
            </label>
          </div>
        </div>

        <RangeSlider
          title="Price per Hour"
          min={0}
          max={100}
          currency="USD"
          range={priceRange}
          setRange={setPriceRange}
          disabled={!pricePerHour}
        />
      </div>

      <div className="hidden sm:flex justify-end gap-1.5 mt-10">
        <Button
          type="outline"
          text="Reset"
          onClick={resetPricingFilter}
          width="fit-content"
        />
        <Button
          type="primary_dark"
          text="Done"
          onClick={handlePricingFilterDone}
          width="fit-content"
        />
      </div>
    </div>
  );
};

interface CapacityFilterProps {
  capacityRange: number[];
  setCapacityRange: (range: number[]) => void;
  resetCapacityFilter: () => void;
  handleCapacityFilterDone: () => void;
}

const CapacityFilter = ({
  capacityRange,
  setCapacityRange,
  resetCapacityFilter,
  handleCapacityFilterDone,
}: CapacityFilterProps) => {
  return (
    <div>
      <h4 className="body-small-bold mb-3.5 text-primary-paradise-pink-800">
        Students Capacity
      </h4>

      <div className="flex flex-col gap-4">
        <RangeSlider
          min={1}
          max={50}
          range={capacityRange}
          setRange={setCapacityRange}
        />
      </div>

      <div className="hidden sm:flex justify-end gap-1.5 mt-10">
        <Button
          type="outline"
          text="Reset"
          onClick={resetCapacityFilter}
          width="fit-content"
        />
        <Button
          type="primary_dark"
          text="Done"
          onClick={handleCapacityFilterDone}
          width="fit-content"
        />
      </div>
    </div>
  );
};

interface FiltersProps {
  classrooms: any[];
  filteredClassrooms: any[];
  setFilteredClassrooms: (classrooms: any[]) => void;
}

const Filters = ({
  classrooms,
  filteredClassrooms,
  setFilteredClassrooms,
}: FiltersProps) => {
  const [mobileFilter, setMobileFilter] = useState(false);

  const [pricingFilter, setPricingFilter] = useState(false);
  const [capacityFilter, setCapacityFilter] = useState(false);

  const [priceRange, setPriceRange] = useState([4, 25]);
  const [pricePerHour, setPricePerHour] = useState(true);
  const [percentagePerStudent, setPercentagePerStudent] = useState(true);
  const [pricingFilterDone, setPricingFilterDone] = useState(false);
  const [capacityRange, setCapacityRange] = useState([5, 40]);
  const [capacityFilterDone, setCapacityFilterDone] = useState(false);

  useEffect(() => {
    console.log("Filters changed");
    if (capacityFilterDone || pricingFilterDone) {
      const filteredClassrooms = classrooms.filter((classroom) => {
        const capacity = classroom.size;

        if (
          (capacityFilterDone &&
            capacityRange[0] <= capacity &&
            capacity <= capacityRange[1]) ||
          (pricingFilterDone &&
            pricePerHour &&
            priceRange[0] <= classroom.pricePerHour &&
            classroom.pricePerHour <= priceRange[1]) ||
          (pricingFilterDone && percentagePerStudent && !classroom.pricePerHour)
        ) {
          return true;
        }
        return false;
      });

      setFilteredClassrooms(filteredClassrooms);
    }
  }, [capacityFilterDone, pricingFilterDone]);

  const resetFilter = () => {
    resetPricingFilter();
    resetCapacityFilter();
  };

  const handlePricingFilterClick = () => {
    setPricingFilterDone(false);
    setPricingFilter(!pricingFilter);
  };

  const resetPricingFilter = () => {
    setPricePerHour(true);
    setPercentagePerStudent(true);
    setPriceRange([4, 25]);
    setPricingFilterDone(false);
    setPricingFilter(false);
    setFilteredClassrooms(classrooms);
  };

  const handlePricingFilterDone = () => {
    console.log("handle pricing filter");
    setPricingFilterDone(true);
    setPricingFilter(false);
  };

  const handleCapacityFilterClick = () => {
    setCapacityFilterDone(false);
    setCapacityFilter(!capacityFilter);
  };

  const resetCapacityFilter = () => {
    setCapacityRange([5, 40]);
    setCapacityFilterDone(false);
    setCapacityFilter(false);
    setFilteredClassrooms(classrooms);
  };

  const handleCapacityFilterDone = () => {
    console.log("handle Capacity filter");
    setCapacityFilterDone(true);
    setCapacityFilter(false);
  };

  const applyMobileFilter = () => {
    handlePricingFilterDone();
    handleCapacityFilterDone();
    setMobileFilter(false);
  };

  return (
    <>
      <div
        className="sm:hidden p-3 bg-primary-purple-50 border border-primary-purple-100 rounded-xl cursor-pointer"
        onClick={() => setMobileFilter(true)}
      >
        <img src="/images/icons/menu2.svg" />
      </div>

      {mobileFilter && (
        <div className="sm:hidden absolute top-0 left-0 z-50 flex flex-col justify-between p-5 bg-white w-screen h-screen">
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <h4 className="body-large">Filter Classrooms</h4>
              <img
                src="/images/icons/close.svg"
                className="size-9"
                onClick={() => setMobileFilter(false)}
              />
            </div>

            <div className="flex flex-col gap-8">
              <CapacityFilter
                capacityRange={capacityRange}
                setCapacityRange={setCapacityRange}
                resetCapacityFilter={resetCapacityFilter}
                handleCapacityFilterDone={handleCapacityFilterDone}
              />
              <PrincingFilter
                priceRange={priceRange}
                pricePerHour={pricePerHour}
                percentagePerStudent={percentagePerStudent}
                setPriceRange={setPriceRange}
                setPricePerHour={setPricePerHour}
                setPercentagePerStudent={setPercentagePerStudent}
                resetPricingFilter={resetPricingFilter}
                handlePricingFilterDone={handlePricingFilterDone}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div
              className="body-medium underline cursor-pointer"
              onClick={resetFilter}
            >
              Reset Changes
            </div>
            <Button
              type="primary_dark"
              text="Apply Filter"
              onClick={applyMobileFilter}
              width="fit-content"
            />
          </div>
        </div>
      )}

      <div className="hidden sm:flex items-center gap-2.5">
        <div
          className="body-small underline cursor-pointer"
          onClick={resetFilter}
        >
          Reset Filter
        </div>

        <div className="relative">
          <button
            className={`flex items-center gap-2 px-5 py-2 border border-primary-paradise-pink-100 hover:bg-primary-paradise-pink-100 rounded-xl transition-all duration-200 ${
              pricingFilter
                ? "bg-primary-paradise-pink-100 border-primary-paradise-pink-500"
                : "bg-primary-paradise-pink-50"
            } ${
              pricingFilterDone && pricePerHour
                ? "border-primary-paradise-pink-500"
                : ""
            }`}
            onClick={handlePricingFilterClick}
          >
            <img src="/images/icons/price.svg" />
            <span className="medium-button text-primary-paradise-pink-500">
              {pricingFilterDone && pricePerHour
                ? `$${priceRange[0]} - $${priceRange[1]}`
                : "Pricing"}
            </span>
          </button>

          {pricingFilter && (
            <div
              className="absolute z-10 top-[54px] right-0 w-[488px] p-6 bg-white border border-primary-purple-100 rounded-[22px]"
              style={{
                boxShadow: "0px 18px 24px -8px #00000012",
              }}
            >
              <PrincingFilter
                priceRange={priceRange}
                pricePerHour={pricePerHour}
                percentagePerStudent={percentagePerStudent}
                setPriceRange={setPriceRange}
                setPricePerHour={setPricePerHour}
                setPercentagePerStudent={setPercentagePerStudent}
                resetPricingFilter={resetPricingFilter}
                handlePricingFilterDone={handlePricingFilterDone}
              />
            </div>
          )}
        </div>

        <div className="relative">
          <button
            className={`flex items-center gap-2 px-5 py-2 border border-primary-paradise-pink-100 hover:bg-primary-paradise-pink-100 rounded-xl transition-all duration-200 ${
              capacityFilter
                ? "bg-primary-paradise-pink-100 border-primary-paradise-pink-500"
                : "bg-primary-paradise-pink-50"
            } ${capacityFilterDone ? "border-primary-paradise-pink-500" : ""}`}
            onClick={handleCapacityFilterClick}
          >
            <img src="/images/icons/people-pink.svg" />
            <span className="medium-button text-primary-paradise-pink-500">
              {capacityFilterDone
                ? `${capacityRange[0]} - ${capacityRange[1]} Students`
                : "Capacity"}
            </span>
          </button>

          {capacityFilter && (
            <div
              className="absolute z-10 top-[54px] right-0 w-[488px] p-6 bg-white border border-primary-purple-100 rounded-[22px]"
              style={{
                boxShadow: "0px 18px 24px -8px #00000012",
              }}
            >
              <CapacityFilter
                capacityRange={capacityRange}
                setCapacityRange={setCapacityRange}
                resetCapacityFilter={resetCapacityFilter}
                handleCapacityFilterDone={handleCapacityFilterDone}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Filters;
