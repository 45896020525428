import React from "react";

import styles from "./input.module.css";

interface InputProps {
  max?: number;
  min?: number;
  fullWidth?: boolean;
  label?: string;
  value: string;
  type?: "text" | "password" | "number" | "email" | "textarea";
  readOnly?: boolean;
  required?: boolean;
  placeholder?: string;
  icon?: string;
  maxLength?: number;
  validation?: (value: string) => boolean;
  onChange: (e: any) => void;
}

const Input: React.FC<InputProps> = ({
  max,
  min,
  fullWidth,
  label,
  value,
  type,
  readOnly,
  required,
  placeholder,
  icon,
  maxLength,
  validation,
  onChange,
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [inputType, setInputType] = React.useState<string>(type || "text");
  const [textareaLength, setTextareaLength] = React.useState<number>(0);
  const handleTypeChange = () => {
    if (type == "password")
      showPassword ? setInputType("password") : setInputType("text");
    setShowPassword((prev) => !prev);
  };

  const isError = isFocused && validation && !validation(value);

  const handleRightPadding = () => {
    if (type === "password" && isError) {
      return "68px";
    }
    if (type === "password" || isError) {
      return "40px";
    }
    return "";
  };

  return (
    <div className={styles.input_container}>
      <label
        className="caption-text text-primary-paradise-pink-800"
        htmlFor={label}
      >
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div className="relative flex items-center justify-start">
        {icon && (
          <div className="absolute left-4">
            <img src={icon} width={14} alt="" />
          </div>
        )}

        {type === "textarea" ? (
          <div className="relative w-full">
            <textarea
              id={label}
              value={value}
              readOnly={readOnly}
              required={required}
              onChange={(e) => {
                onChange(e);
                setTextareaLength(e.target.value.length);
              }}
              placeholder={placeholder}
              className={`resize-none	h-[145px] ${styles.input} ${
                label === "Search" ? styles.input_search : ""
              } ${isError ? styles.input_error : ""}`}
              onFocus={() => setIsFocused(true)}
              style={{
                flex: 1,
                paddingRight: handleRightPadding(),
                paddingLeft: icon ? "38px" : "",
              }}
              maxLength={maxLength || 200}
            />
            <span className="caption-text absolute bottom-2 right-2 text-primary-gray-400">
              {textareaLength}/{maxLength || 200}
            </span>
          </div>
        ) : (
          <input
            id={label}
            min={min}
            max={max}
            value={value}
            readOnly={readOnly}
            required={required}
            onChange={onChange}
            placeholder={placeholder}
            type={inputType}
            className={`${styles.input} ${
              label === "Search" ? styles.input_search : ""
            } ${isError ? styles.input_error : ""}`}
            onFocus={() => setIsFocused(true)}
            style={{
              flex: 1,
              paddingRight: handleRightPadding(),
              paddingLeft: icon ? "38px" : "",
            }}
          />
        )}
        <div className="absolute flex gap-2 right-4">
          {isError && <img src="/images/icons/error.svg" alt="error" />}
          {type === "password" && (
            <img
              className="cursor-pointer"
              src={
                showPassword
                  ? "/images/icons/eye-closed.svg"
                  : "/images/icons/eye.svg"
              }
              width={20}
              alt="eye"
              onClick={handleTypeChange}
            />
          )}
        </div>
      </div>

      {isError && label !== "Confirm Password" && (
        <span className="caption-text text-tertiary-severe-600">
          Invalid {label}
        </span>
      )}
      {isError && label === "Confirm Password" && (
        <span className="caption-text text-tertiary-severe-600">
          Password and confirm password don't match
        </span>
      )}
    </div>
  );
};

export default Input;
