import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import {
  NewCourseComponents,
  CourseComponents,
  Footer,
  Navbar,
  Loading,
} from "../../../components";
import { useStore } from "../../../store";
import Container from "src/components/shared/Container";
import { toast, ToastContainer } from "react-toastify";

function Cohorte() {
  const params = useParams();
  const { courseStore, userStore } = useStore();

  const [loading, setLoading] = useState<boolean>(true);

  const course: any = courseStore.courseDetails;

  const aboutCreatorRef = useRef<HTMLDivElement>(null);

  const scrollToAboutCreator = () => {
    if (aboutCreatorRef.current) {
      aboutCreatorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendClassiewEvent = async () => {
    if (!params.id) return;

    const payload = {
      event_name: "class_view",
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      action_source: "website",
      user_data: {
        client_user_agent: navigator.userAgent,
        em: [userStore.user?.email || ""],
        ph: [userStore.user?.phone || ""],
        country: userStore.user?.country || "",
        ge: userStore.user?.gender || "",
        fn: userStore.user?.fullName || "",
        ln: userStore.user?.fullName || "",
      },
      custom_data: {
        class_id: params.id,
        class_name: course?.title || "Unknown Class",
      },
    };

    try {
      await userStore.sendMetaEvent("course_view", payload);
    } catch (error) {
      console.error("Error sending event:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, []);

  const loadData = async () => {
    try {
      if (params.id) {
        await courseStore.getCohortById(params.id);
        sendClassiewEvent();
      }
      if (!userStore.user) {
        await userStore.getUser();
      }
    } catch (error) {
      toast.error("Error loading course data");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar fixed={false} />
      <ToastContainer />

      <div className="relative w-full flex flex-col justify-start items-start gap-5 sm:gap-10">
        <Container>
          <div className="flex flex-col gap-1.5 sm:gap-6 sm:py-5 sm:pt-10 sm:px-[2rem] lg:px-[7.5%]">
            <CourseComponents.ClassHeader
              onInstructorClick={scrollToAboutCreator}
              type="class"
            />
            <CourseComponents.ClassUpcomingCohorts />
          </div>
        </Container>

        <div className="w-full m-0 bg-primary-purple-50">
          <Container>
            <div className="sm:px-[2rem] lg:px-[7.5%]">
              <NewCourseComponents.CourseReviews />
            </div>
          </Container>
        </div>

        <Container>
          <div className="sm:px-[2rem] lg:px-[7.5%]">
            <div className="flex flex-col gap-5 sm:gap-10 lg:px-[200px]">
              <div ref={aboutCreatorRef}>
                <CourseComponents.AboutCreator />
              </div>
              <NewCourseComponents.FAQs />
            </div>
          </div>
        </Container>

        <div className="w-full">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default observer(Cohorte);
