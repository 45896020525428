import { useRef, useState } from "react";
import env from "../../../../../config/env";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { Search } from "./Search";
import MapHandler from "./MapHandler";
import { Zoom } from "./Zoom";
import Card from "./Card";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
interface IProps {
  classrooms: any[];
  viewportClassrooms: any[];
  handleRequest?: (id: string) => void;
  setViewportClassrooms?: (value: any) => void;
  handleClassroomClick?: (id: any) => void;
  handleClassroomHover?: (id: any) => void;
}

const MapComponent = ({
  classrooms,
  viewportClassrooms,
  handleRequest,
  setViewportClassrooms,
  handleClassroomClick,
  handleClassroomHover,
}: IProps) => {
  const defaultCenter = { lat: 37.0902, lng: -95.7129 };

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [zoom, setZoom] = useState(5);

  const handleMapWrapperClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest(".marker-container")) {
      handleClick(null);
    }
  };

  const handleClick = (id: string | null) => {
    handleClassroomClick && handleClassroomClick(id);
  };

  const handleHover = (id: string | null) => {
    handleClassroomHover && handleClassroomHover(id);
  };

  return (
    <APIProvider apiKey={env.GOOGLE_MAPS_API_KEY}>
      <div
        className="relative rounded-2xl overflow-hidden w-full h-full"
        onClick={handleMapWrapperClick}
      >
        <Search onPlaceSelect={setSelectedPlace} />
        <Zoom zoom={zoom} onZoomChange={(zoom) => setZoom(zoom)} />

        <Map
          zoom={zoom}
          onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
          defaultCenter={defaultCenter}
          mapId="e92cc09a7d8fb0e9"
          streetViewControl={false}
          disableDefaultUI
        >
          {viewportClassrooms?.map((classroom) => (
            <AdvancedMarker
              key={`${classroom.location.lat}-${classroom.location.lng}`}
              position={classroom.location}
            >
              <div className="marker-container">
                {classroom?.openCard ? (
                  <Card classroom={classroom} handleRequest={handleRequest} />
                ) : (
                  <img
                    src="/images/icons/location-marker.svg"
                    className={`cursor-pointer transition-all duration-300 ${
                      classroom.hover ? "size-12" : "size-10"
                    }`}
                    onClick={() => handleClick(classroom.id)}
                    onMouseEnter={() => handleHover(classroom.id)}
                    onMouseLeave={() => handleHover(null)}
                  />
                )}
              </div>
            </AdvancedMarker>
          ))}
        </Map>

        <MapHandler
          place={selectedPlace}
          classrooms={classrooms}
          setViewportClassrooms={setViewportClassrooms}
        />
      </div>
    </APIProvider>
  );
};

export default MapComponent;
