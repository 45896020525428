import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import clsx from "clsx";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";

// interface SchedulePlannerProps {
//   schedule: any;
//   readOnly: boolean;
//   isOldSchedule: boolean;
// }

interface classPageScheduleProps {
  readOnly: true;
  schedule: any;
  isOldSchedule: boolean;
  setPlanning?: never;
  setSelectedTimeZone?: never;
}

interface createClassProps {
  readOnly: false;
  setPlanning: (newPlanning: any) => void;
  setSelectedTimeZone: (newTimeZone: string) => void;
  schedule?: never;
  isOldSchedule?: never;
}

type SchedulePlannerProps = classPageScheduleProps | createClassProps;

const SchedulePlanner: React.FC<SchedulePlannerProps> = ({
  readOnly,
  schedule,
  isOldSchedule,
  setPlanning,
  setSelectedTimeZone,
}) => {
  const timezones = moment.tz.names().map((tz: any) => {
    const offset = moment.tz(tz).utcOffset();
    const formattedOffset = `UTC${offset >= 0 ? "+" : ""}${(
      offset / 60
    ).toFixed(0)}`;
    return { label: `${formattedOffset} ${tz}`, value: tz };
  });

  const [startDate, setStartDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    moment().add(6, "days").format("YYYY-MM-DD")
  );
  const [timeZone, setTimeZone] = useState<string>("UTC");
  const [startTime, setStartTime] = useState<string>("08:00");
  const [endTime, setEndTime] = useState<string>("19:30");

  const [dates, setDates] = useState<any[]>([]);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);

  const [dragging, setDragging] = useState(false);
  const [currentBlock, setCurrentBlock] = useState<any>(null);
  const [selectedBlocks, setSelectedBlocks] = useState<any[]>([]);

  useEffect(() => {
    if (readOnly) {
      initSchedule(isOldSchedule, schedule);
    } else {
      setDates(generateDateRange());
      setTimeSlots(generateTimeSlots());
      const userTimeZone = moment.tz.guess();
      setTimeZone(userTimeZone);
      setSelectedTimeZone(userTimeZone);
      if (dragging) {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      } else {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [dragging, currentBlock, startDate, endDate]);

  const initSchedule = (isOldSchedule: boolean, schedule: any) => {
    //init old schedule data format
    if (isOldSchedule) {
      initOldSchedule();
    } else {
      initNewSchedule();
    }
  };

  const initOldSchedule = () => {
    if (schedule.schedule?.length) {
      const userTimeZone = moment.tz.guess();
      const userUTCOffset = moment.tz(userTimeZone).utcOffset() / 60;
      const formattedUTCOffset = `UTC ${
        userUTCOffset >= 0 ? `+${userUTCOffset}` : userUTCOffset
      }`;

      const scheduleDates = schedule.schedule.map((date: string) =>
        moment.utc(date).tz(userTimeZone)
      );

      const oldScheduleStartDate = moment
        .min(scheduleDates)
        .format("YYYY-MM-DD");
      const oldScheduleEndDate = moment.max(scheduleDates).format("YYYY-MM-DD");

      const scheduleTimes = scheduleDates.map((date: any) =>
        date.format("HH:mm")
      );

      const oldScheduleStartTime = scheduleTimes.reduce(
        (min: any, time: any) => (time < min ? time : min),
        scheduleTimes[0]
      );
      const oldScheduleEndTime = scheduleTimes.reduce(
        (max: any, time: any) => (time > max ? time : max),
        scheduleTimes[0]
      );

      setStartDate(oldScheduleStartDate);
      setEndDate(oldScheduleEndDate);

      setStartTime(oldScheduleStartTime);
      setEndTime(oldScheduleEndTime);

      setTimeZone(formattedUTCOffset);

      const newTimeSlots = generateTimeSlots();
      const newDates = generateDateRange();

      // group consecutive times in the same day into blocks
      const groupedBlocks: any[] = [];
      let currentGroup: any[] = [];

      let previousDate = scheduleDates[0];

      scheduleDates.forEach((dateObj: moment.Moment, index: number) => {
        const isSameDay =
          dateObj.format("YYYY-MM-DD") === previousDate.format("YYYY-MM-DD");

        if (isSameDay && dateObj.hours() === previousDate.hours() + 1) {
          currentGroup.push(dateObj);
        } else {
          if (currentGroup.length) {
            groupedBlocks.push(currentGroup);
          }

          currentGroup = [dateObj];
        }

        previousDate = dateObj;
      });

      if (currentGroup.length) {
        groupedBlocks.push(currentGroup);
      }

      // generate blocks based on the grouped consecutive times
      const blocks = groupedBlocks.map((group: moment.Moment[]) => {
        const startTime = group[0].format("hh:mm A");
        const endTime = group[group.length - 1]
          .clone()
          .add(1, "hour")
          .format("HH:mm");

        const fullDate = group[0].format("YYYY-MM-DD");
        const date = group[0].format("ddd DD");

        const newText = formatTimeline(group.length * 2 + 1);
        return {
          fullDate,
          date,
          startTime,
          endTime,
          x: 0,
          top: newDates.map((d) => d.display).indexOf(date) * 52 + 56,
          left: (newTimeSlots.indexOf(startTime) + 1) * 78 + 8,
          width: 140 * (group.length + 0.5) + 8 * (group.length * 2),
          text: newText,
        };
      });

      setSelectedBlocks(blocks);
      setTimeSlots(newTimeSlots);
      setDates(newDates);
    }
  };

  const initNewSchedule = () => {
    const blocksList = schedule.blocks;
    const planningTimeZone = schedule.timeZone;
    const userTimeZone = moment.tz.guess();

    // init start date and end date
    const fullDatesList = blocksList.map((block: any) =>
      moment(block.fullDate, "YYYY-MM-DD", planningTimeZone).tz(userTimeZone)
    );

    const fetcheStartDate = moment.min(fullDatesList);
    const fetchedEndDate = moment.max(fullDatesList);

    setStartDate(fetcheStartDate.format("YYYY-MM-DD"));
    setEndDate(fetchedEndDate.format("YYYY-MM-DD"));

    // init start time and end time
    const startTimesList = blocksList.map((block: any) =>
      moment(
        `${block.fullDate} ${block.startTime}`,
        "YYYY-MM-DD hh:mm A",
        planningTimeZone
      ).tz(userTimeZone)
    );
    const endTimesList = blocksList.map((block: any) =>
      moment(
        `${block.fullDate} ${block.endTime}`,
        "YYYY-MM-DD hh:mm A",
        planningTimeZone
      ).tz(userTimeZone)
    );

    const fetchedStartTime = moment.min(startTimesList).format("hh:mm A");
    const fetchedEndTime = moment.max(endTimesList).format("hh:mm A");

    setStartTime(fetchedStartTime);
    setEndTime(fetchedEndTime);

    // init timetable view
    const newTimeSlots = generateTimeSlots();
    const newDates = generateDateRange();

    setTimeSlots(newTimeSlots);
    setDates(newDates);
    setSelectedBlocks(blocksList);
  };

  const generateTimeSlots = () => {
    const times: string[] = [];
    const currentTime = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");

    if (readOnly) {
      while (currentTime <= end || times.length < 49) {
        times.push(currentTime.format("hh:mm A"));
        currentTime.add(30, "minutes");
      }
    } else {
      while (currentTime <= end || times.length < 42) {
        times.push(currentTime.format("hh:mm A"));
        currentTime.add(30, "minutes");
      }
    }
    return times;
  };

  const generateDateRange = () => {
    const dates: { display: string; fullDate: string }[] = [];
    const currentDate = moment(startDate);
    const end = moment(endDate);
    const sevenDatesRange = end.diff(moment(startDate), "days") >=6 ? end :  moment(startDate).add(6, "days");    

    if (!readOnly) {
      while (currentDate <= end) {
        dates.push({
          display: currentDate.format("ddd DD"),
          fullDate: currentDate.format("YYYY-MM-DD"),
        });
        currentDate.add(1, "day");
      }
    } else {
      while (currentDate <= sevenDatesRange) {
        dates.push({
          display: currentDate.format("ddd DD"),
          fullDate: currentDate.format("YYYY-MM-DD"),
        });
        currentDate.add(1, "day");
      }
    }
    return dates;
  };

  const handleClearBoard = () => {
    setSelectedBlocks([]);
    if (setPlanning) {
      setPlanning([]);
    }
  };

  const isBlockDisabled = (selectedTime: any) => {
    const formattedStartTime = moment(startTime, "HH:mm");
    const formattedEndTime = moment(endTime, "HH:mm");
    const timeRange = [];
    while (formattedStartTime <= formattedEndTime) {
      timeRange.push(formattedStartTime.format("hh:mm A"));
      formattedStartTime.add(30, "minutes");
    }
    return !timeRange.includes(selectedTime);
  };

  const handleMouseDown = (
    dateDisplay: string,
    time: string,
    event: React.MouseEvent
  ) => {
    setDragging(true);
    const fullDate =
      dates.find((d) => d.display === dateDisplay)?.fullDate || "";

    const blockTop =
      dates
        .map((date: any) => {
          return date.display;
        })
        .indexOf(dateDisplay) * 52;

    const blockLeft = (timeSlots.indexOf(time) + 1) * 78;

    const newBlock = {
      date: dateDisplay,
      fullDate,
      startTime: time,
      endTime: time,
      x: event.clientX,
      top: blockTop,
      left: blockLeft,
      width: 70,
      text: "",
    };

    setSelectedBlocks((prevBlocks) => [...prevBlocks, newBlock]);
    if (setPlanning) {
      setPlanning((prevPlanning: any) => [...prevPlanning, newBlock]);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!dragging || selectedBlocks.length === 0) return;

    setSelectedBlocks((prevBlocks) => {
      const updatedBlocks = [...prevBlocks];
      const lastBlockIndex = updatedBlocks.length - 1;
      const lastBlock = updatedBlocks[lastBlockIndex];

      if (!lastBlock) return prevBlocks;

      const newWidth = Math.max(event.clientX - lastBlock.x, 70);
      const blocksNumber = Math.floor(newWidth / 70);
      const newTime = formatTimeline(blocksNumber);

      const startTimeIndex = timeSlots.indexOf(lastBlock.startTime);
      const endTimeIndex = Math.min(
        startTimeIndex + blocksNumber - 1,
        timeSlots.length - 1
      );
      const newEndTime = timeSlots[endTimeIndex];

      updatedBlocks[lastBlockIndex] = {
        ...lastBlock,
        width: newWidth,
        text: newTime,
        endTime: newEndTime,
      };

      if (setPlanning) {
        setPlanning(updatedBlocks);
      }

      return updatedBlocks;
    });
  };

  const handleMouseUp = () => {
    if (dragging && currentBlock) {
      setSelectedBlocks([...selectedBlocks, currentBlock]);
      setCurrentBlock(null);
      if (setPlanning) {
        setPlanning([...selectedBlocks, currentBlock]);
      }
    }
    setDragging(false);
  };

  const onResizeBlock = (
    event: any,
    data: any,
    blockIndex: any,
    block: any
  ) => {
    const newWidth = data.size.width;
    const blocksNumber = Math.floor(newWidth / 70);
    const newTime = formatTimeline(blocksNumber);
    let newStartTime = block.startTime;
    let newEndTime = block.endTime;

    if (data.handle === "e") {
      const startTimeIndex = timeSlots.indexOf(block.startTime);
      const newStartTimeIndex = startTimeIndex;
      let newEndTimeIndex = startTimeIndex + blocksNumber - 1;

      newEndTimeIndex = Math.min(newEndTimeIndex, timeSlots.length - 1);
      newStartTime = timeSlots[newStartTimeIndex];
      newEndTime = timeSlots[newEndTimeIndex];
    }

    if (data.handle === "w") {
      const endTimeIndex = timeSlots.indexOf(block.endTime);
      let newStartTimeIndex = endTimeIndex - blocksNumber + 1;
      const newEndTimeIndex = endTimeIndex;

      newStartTimeIndex = Math.max(newStartTimeIndex, 0);
      newStartTime = timeSlots[newStartTimeIndex];
      newEndTime = timeSlots[newEndTimeIndex];
    }

    const updatedBlocks = selectedBlocks.map((b, i) => {
      if (i === blockIndex) {
        return {
          ...b,
          width: newWidth,
          startTime: newStartTime,
          endTime: newEndTime,
          left: data.handle === "w" ? b.left - (newWidth - b.width) : b.left,
          text: newTime,
        };
      }
      return b;
    });

    setSelectedBlocks(updatedBlocks);
    if (setPlanning) {
      setPlanning([updatedBlocks]);
    }
  };

  const formatTimeline = (blocks: number) => {
    if (blocks === 1) return "";
    const hours = Math.floor((blocks - 1) / 2);
    const minutes = (blocks - 1) % 2 === 1 ? "30 MINS" : "";
    if (hours > 0) {
      if (hours === 1) {
        return `${hours} HOUR ${minutes}`;
      } else {
        return `${hours} HOURS ${minutes}`;
      }
    } else {
      return minutes;
    }
  };

  const handleSaveSchedule = () => {
    console.log("selectedBlocks", selectedBlocks);
  };

  const formatDate = (isoString: string) => {
    return moment.tz(isoString, timeZone).format("DD MMM").toUpperCase();
  };

  return (
    <div className="w-full max-w-[1200px] flex flex-col justify-start items-start mt-[24px] md:mt-0 space-y-[12px] md:space-y-[8px] px-[15px] py-[0px] md:px-[40px] md:py-[40px]">
      {/* schedule planner header when readOnly is false */}
      {!readOnly && (
        <div className="w-full">
          <p className="text-3xl font-inter font-bold leading-[38px]">
            Schedule Planner
          </p>
        </div>
      )}
      {/* schedule planner header when readOnly is true */}
      {readOnly && (
        <div className="w-full flex flex-col md:flex-row justify-center md:justify-between items-center space-y-2 md:space-y-0">
          {/* schedule planner left header */}
          <div className="flex justify-between md:justify-start items-center space-x-[6px] md:space-x-[12px]">
            <img
              src="/images/schedule/calendar.svg"
              alt=""
              className="w-[20px] md:w-[24px] h-[20px] md:h-[24px]"
            />
            {/* schedule planner title */}
            <p className="text-[#1B0209] font-inter font-semibold text-[16px] md:text-[22px] leading-[25px] md:leading-[32px] ">
              Class Schedule
            </p>
            {/* schedule planner start & end dates */}
            <div className="flex justify-start items-center space-x-[4px] ">
              {/* start date */}
              <p className="text-[#370513] font-inter font-bold text-[14px] leading-[24px] ">
                {formatDate(startDate)}
              </p>
              {/* right arrow */}
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.50002 7H10.7917M10.7917 7L7.29169 3.5M10.7917 7L7.29169 10.5"
                  stroke="#370513"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              {/* end date */}
              <p className="text-[#370513] font-inter font-bold text-[14px] leading-[24px]">
                {formatDate(endDate)}
              </p>
            </div>
          </div>
          {/* schedule planner timezone text */}
          <p className="text-[#3F3F3E] font-inter font-medium md:font-normal text-[12px] md:text-[14px] leading-[24px] ">
            Shown in your local Time Zone ({timeZone})
          </p>
        </div>
      )}
      {/* schedule planner control board */}
      {!readOnly && (
        <div className="w-full flex justify-between items-center">
          {/* schedule planner date/time control */}
          <div className="flex justify-start items-center space-x-4">
            {/* displayed days section */}
            <div className="flex flex-col justify-start items-start space-y-2">
              <p className="text-primary-gray-800 font-inter font-medium text-xs leading-[24px]">
                Displayed Days
              </p>
              <div className="flex justify-start items-center space-x-[8px]">
                {/* start date input */}
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  className="border border-input rounded-lg w-[184px] h-[48px] p-3"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                {/* right arrow */}
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.75 8H11.5625M11.5625 8L7.8125 4.25M11.5625 8L7.8125 11.75"
                    stroke="#6D6D6D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* end date input */}
                <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  className="border border-input rounded-lg w-[184px] h-[48px] p-3"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* displayed timezone section */}
            <div className="flex flex-col justify-start items-start space-y-2">
              <p className="text-primary-gray-800 font-inter font-medium text-xs leading-[24px]">
                Time Zone (EST)
              </p>
              <select
                name="timeZones"
                id="timeZones"
                value={timeZone}
                className="p-3 text-sm text-black border border-gray-300 rounded-lg w-[141px] h-[48px] bg-white appearance-none"
              >
                {timezones.map((timezone: any) => (
                  <option key={timezone.value} value={timezone.value}>
                    {timezone.label}
                  </option>
                ))}
              </select>
            </div>
            {/* displayed times section */}
            <div className="flex flex-col justify-start items-start space-y-2">
              <p className="text-primary-gray-800 font-inter font-medium text-xs leading-[24px]">
                Displayed Time
              </p>
              <div className="flex justify-start items-center space-x-[8px]">
                {/* start time */}
                <input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="p-3 text-sm text-[var(--black)] border border-gray-300 rounded-lg w-[150px] h-[48px] cursor-inherit"
                />
                {/* right arrow */}
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.75 8H11.5625M11.5625 8L7.8125 4.25M11.5625 8L7.8125 11.75"
                    stroke="#6D6D6D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* end time */}
                <input
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  className="p-3 text-sm text-[var(--black)] border border-gray-300 rounded-lg w-[150px] h-[48px] cursor-inherit"
                />
              </div>
            </div>
          </div>
          {/* schedule planner clear button */}
          <button
            className="flex justify-center items-center py-[12px] px-[34px] bg-[#FFECF2] rounded-[12px] mt-8 "
            onClick={handleClearBoard}
          >
            <span className="text-[#CA325C] text-[14px] font-inter font-semibold text-center leading-[26px] ">
              Clear Board
            </span>
          </button>
        </div>
      )}
      {/* timetable view */}
      <div className="relative w-full max-h-[450px] bg-[#F9F9F9] rounded-[24px] py-[14px] overflow-x-auto overflow-y-auto timetable_scrollbar">
        <table
          // ref={tableRef}
          className="w-full border-none border-separate border-spacing-[8px] overflow-x-auto cursor-cell"
        >
          <thead className="hover:cursor-cell">
            <tr>
              {/* <th className="p-2 min-w-[70px] min-h-[28px] "></th> */}
              <th className="sticky min-w-[70px] left-0 z-10 bg-[#F9F9F9]"></th>
              {timeSlots.map((time, timeIndex) => (
                <th
                  key={timeIndex}
                  className="text-center min-w-[70px] min-h-[28px] text-[#6D6D6D] font-inter font-semibold text-[12px] leading-[24px]"
                >
                  {time}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {dates.map((date, dateIndex) => (
              <tr key={dateIndex}>
                <td className=" sticky text-center left-0 z-10 bg-[#F9F9F9] text-[#6D6D6D] font-inter font-semibold text-[12px] leading-[24px] hover:cursor-default">
                  {date.display}
                </td>
                {timeSlots.map((time, timeIndex) => {
                  const blockKey = `${date.display}_${time}_${timeIndex}_${dateIndex}`;
                  
                  if (readOnly) {
                    return (
                      <td
                        key={blockKey}
                        className={clsx(
                          "w-[70px] h-[44px] bg-[#F2F2F2] rounded-[6px] "
                        )}
                      />
                    );
                  } else {
                    return (
                      <td
                        key={blockKey}
                        className={clsx(
                          `w-[70px] h-[44px] ${
                            isBlockDisabled(time)
                              ? "bg-[#E2E2E2]  hover:cursor-disabled"
                              : "bg-[#F2F2F2] hover:bg-[#E2E2E2] hover:cursor-cell"
                          } rounded-[6px]`
                        )}
                        onMouseDown={(e) =>
                          isBlockDisabled(time)
                            ? null
                            : handleMouseDown(date.display, time, e)
                        }
                      />
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {selectedBlocks.map((block, index) => (
          <ResizableBox
            key={index}
            width={block.width}
            height={44}
            axis="x"
            minConstraints={[70, 44]}
            maxConstraints={[5000, 44]}
            resizeHandles={readOnly ? [] : ["w", "e"]}
            className="absolute bg-[#E83C70] rounded-md"
            style={{
              top: block.top,
              left: block.left,
            }}
            onResize={(event, data) => {
              onResizeBlock(event, data, index, block);
            }}
          >
            <span className="w-full h-full flex justify-center items-center text-[#FFECF2] font-inter font-bold text-[12px] leading-[24px] ">
              {block.text}
            </span>
          </ResizableBox>
        ))}
      </div>
      {/* <button
        className="flex justify-center items-center py-[12px] px-[34px] bg-[#FFECF2] rounded-[12px] mt-4 "
        onClick={handleSaveSchedule}
      >
        <span className="text-[#CA325C] text-[14px] font-inter font-semibold text-center leading-[26px] ">
          Save Schedule
        </span>
      </button> */}
    </div>
  );
};

export default SchedulePlanner;
