import { useState } from "react";
import { observer } from "mobx-react";
import { Button } from "../../../../components";
import { toast } from "react-toastify";
import { useStore } from "../../../../store";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  user: any;
  classroom: any;
  setStep: (step: number) => void;
}

function AdditionalDetails({ user, classroom, setStep }: IProps) {
  const { userStore } = useStore();

  const [agree, setAgree] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const days = classroom.days.filter((day: any) => day.enabled);

  const handleNextStep = async () => {
    setIsButtonClicked(true);
    try {
      const newUser = { ...user };
      const newClassroom = { ...classroom };

      if (user.image) {
        await userStore.uploadPicture(user.image).then((response) => {
          newUser.image = response;
        });
      }

      await userStore.updateProfile(userStore.user.id, newUser);

      classroom?.files.map(async (file: File, index: number) => {
        await userStore.uploadFile(file).then((response) => {
          newClassroom.files[index] = response;
        });
      });

      const response = await userStore.sendUpgradeRequest(
        "host",
        userStore.user.id,
        newClassroom
      );
      if (response) {
        // setStep(5);
      } else {
        toast.error("Failed to send upgrade request");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrong");
    }

    setIsButtonClicked(false);
    HubSpotsignupStore.submitToHubspot();
  };

  const handleBack = () => {
    setStep(3);
  };

  const getTimes = () => {
    if (days) {
      const formatTime = (time: string) => {
        const [hour, minute] = time.split(":").map(Number);
        const ampm = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute.toString().padStart(2, "0")} ${ampm}`;
      };

      const start = formatTime(days[0].start);
      const end = formatTime(days[0].end);

      return `${start} to ${end}`;
    }
    return "";
  };

  const getDays = () => {
    if (days) {
      if (days.length === 7) {
        return "ALL DAYS";
      }
      return days
        .map((day: any) => {
          return day.name.substring(0, 3).toUpperCase();
        })
        .join(", ");
    }
    return "";
  };

  return (
    <div className="flex flex-col gap-5 sm:gap-6 w-full">
      <h3 className="body-large">Review Information</h3>
      <div className="flex flex-col gap-5">
        <div className="flex gap-4 p-5 sm:px-8 sm:py-6 bg-primary-purple-50 border border-primary-purple-200 rounded-3xl sm:rounded-2xl">
          <img src="/images/icons/info-black.svg" />
          <p className="text-body-small sm:text-body-medium">
            You can change these information later as well if needed.
          </p>
        </div>

        <div className="flex flex-col gap-0.5">
          <div className="body-medium-bold">Classroom Name</div>
          <div className="body-medium">{classroom.name}</div>
        </div>

        <div className="flex flex-col gap-0.5">
          <div className="body-medium-bold">Classroom Description</div>
          <div className="body-medium">{classroom.description}</div>
        </div>

        <div className="flex flex-col gap-0.5">
          <div className="body-medium-bold">Images</div>
          <div className="flex flex-wrap gap-2">
            {classroom.files &&
              classroom.files.map((file: File, index: number) => (
                <img
                  key={index}
                  className="w-[170px] h-[98px] sm:w-[141px] sm:h-[81px] rounded-md object-cover"
                  src={URL.createObjectURL(file)}
                />
              ))}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-5">
          <div className="flex flex-col gap-0.5 w-full sm:w-1/2">
            <div className="body-medium-bold">Location</div>
            <div className="body-medium">{classroom.address.name}</div>
          </div>

          <div className="flex flex-col gap-0.5 w-full sm:w-1/2">
            <div className="body-medium-bold">Max Students</div>
            <div className="body-medium">{classroom.capacity}</div>
          </div>
        </div>

        <div className="flex flex-col gap-0.5">
          <div className="body-medium-bold">Available Amenities</div>
          <div className="flex flex-wrap gap-2.5">
            {classroom.equipments &&
              classroom.equipments.map((equipment: string, index: number) => (
                <div
                  key={index}
                  className="body-medium px-[18px] py-2 text-primary-gray-750 border border-primary-gray-300 bg-primary-purple-100 rounded-full"
                >
                  {equipment}
                </div>
              ))}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-5">
          <div className="flex flex-col gap-0.5 w-full sm:w-1/2">
            <div className="body-medium-bold">General Available Timing</div>
            <div className="body-medium">{getTimes()}</div>
          </div>

          <div className="flex flex-col gap-0.5 w-full sm:w-1/2">
            <div className="body-medium-bold">General Available Timing</div>
            <div className="body-medium">{getDays()}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 sm:gap-2 text-primary-gray-500">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="confirm"
            name="confirm"
            checked={confirm}
            onChange={() => setConfirm(!confirm)}
            className="cursor-pointer"
          />
          <label
            htmlFor="confirm"
            className="text-caption-text font-caption-text sm:text-body-small sm:font-body-small cursor-pointer"
          >
            I confirm that the information provided is accurate.
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={agree}
            onChange={() => setAgree(!agree)}
            className="cursor-pointer"
          />
          <label
            htmlFor="agree"
            className="text-caption-text font-caption-text sm:text-body-small sm:font-body-small cursor-pointer"
          >
            I agree to the ClassX{" "}
            <a
              rel="noreferrer"
              href="https://community.classx.tech/host-agreement/"
              target="_blank"
              className="text-primary-paradise-pink-500 underline"
            >
              Host Terms & Conditions
            </a>
          </label>
        </div>
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          type="pale"
          text="Back"
          width="fit-content"
          onClick={handleBack}
        />
        <div className="w-full sm:w-[173px]">
          <Button
            type="primary_dark"
            text="Submit & Verify"
            onClick={handleNextStep}
            isDisabled={!confirm || !agree || isButtonClicked}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(AdditionalDetails);
