import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import styles from "./details.module.css";
import { Button, Input } from "../../../../components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { payForSession, addSessionToUser } from "src/store/session";
import { useTranslation } from "react-i18next";
import moment from "moment";

function PaymentDetails({
  status,
  setStatus,
  agree,
  setAgree,
  submitRef,
  paymentMethod,
}: {
  status: 1 | 2;
  setStatus: React.Dispatch<React.SetStateAction<1 | 2>>;
  agree: boolean;
  setAgree: React.Dispatch<React.SetStateAction<boolean>>;
  submitRef: React.RefObject<HTMLButtonElement>;
  paymentMethod: "PostWire" | "Onsite" | "KonnectPayment" | "StripePayment";
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { courseStore, userStore, paymentStore } = useStore();

  const [code, setCode] = React.useState<string>("");

  const course: any = courseStore.classDetails;

  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const { t } = useTranslation();

  const [NewPrice, setNewPrice] = useState<number>(0);

  useEffect(() => {
    if (course && price !== undefined && discount !== undefined) {
      setNewPrice(price - discount);
    }
    if (course) {
      if (course.newSessionDiscount) {
        const newPriceAfterDiscount =
          course.price - (course.price * course.newSessionDiscount) / 100;
        setPrice(Math.max(0, Math.round(newPriceAfterDiscount)));
      } else {
        setPrice(course.price);
      }
    }
  }, [course, paymentMethod, price, discount, couponApplied]);

  const sendInitiateCheckoutEvent = async (paymentMethod: string = "") => {
    if (!params.id) return;

    const payload = {
      event_name: "initiate_checkout",
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      action_source: "website",
      user_data: {
        client_user_agent: navigator.userAgent,
        em: [userStore.user?.email || ""],
        ph: [userStore.user?.phone || ""],
        country: userStore.user?.country || "",
        ge: userStore.user?.gender || "",
        fn: userStore.user?.fullName || "",
        ln: userStore.user?.fullName || "",
      },
      custom_data: {
        class_id: params.id,
        class_name: course?.title || "Unknown Class",
        payment_method: paymentMethod,
      },
    };

    try {
      console.log("payload", payload);
      //await userStore.sendMetaEvent("course_view", payload);
    } catch (error) {
      console.error("Error sending event:", error);
    }
  };

  const pay = async () => {
    if (submitRef.current) {
      submitRef.current.click();

      if (status === 2) {
        if (paymentMethod === "KonnectPayment") {
          await handleKonnectPayment();
        }
        if (paymentMethod === "Onsite" || paymentMethod === "PostWire") {
          await handleOnSiteAndWirePayment();
        }
      }
    }
  };

  const handleKonnectPayment = async () => {
    const userTimeZone = moment.tz.guess();
    setButtonLoading(true);
    const data = {
      sessionId: course.id,
      amount: NewPrice || 0,
      currency: userStore.currency || "TND",
      userTimeZone,
    };
    sendInitiateCheckoutEvent("konnect");
    const result = await payForSession(data);

    if (result) {
      if (result.payUrl) {
        window.location.href = result.payUrl;
      } else {
        if (result.success === true) {
          navigate("/enrolled");
        } else {
          toast.error("Something went wrong !");
          setButtonLoading(false);
          setCouponApplied(false);
        }
      }
    } else {
      toast.error("Something went wrong !");
      setButtonLoading(false);
      setCouponApplied(false);
    }
  };

  const handleOnSiteAndWirePayment = async () => {
    setButtonLoading(true);
    try {
      if (paymentMethod !== "Onsite" && paymentMethod !== "PostWire") {
        throw new Error("Invalid payment method for onSiteAndWirePayment");
      }
      const response = await paymentStore.createWiringOrOnsitePaymentSession(
        course.id,
        paymentMethod
      );
      if (response?.status === 200) {
        navigate("/successfully-enrolled");
      } else {
        navigate("/paymentFailed");
      }
    } catch (error) {
      console.error("Error during Onsite/Wiring Payment:", error);
      navigate("/paymentFailed");
    } finally {
      setButtonLoading(false);
    }
  };

  const useCode = async () => {
    const response: any = await courseStore.subscribeWithCoupon({
      code: code,
      sessionId: course.id,
      studentId: userStore.user?.id,
    });

    if (response === null) {
      toast.error("Invalid Coupon!");
      return;
    }

    setDiscount((course.price - response.missingAmount).toFixed(1));
    const updatedNewPrice = response.missingAmount;
    setNewPrice(updatedNewPrice);
    setCouponApplied(true);
    toast.success("Coupon Applied Successfully!");

    // If the coupon fully covers the cost
    if (updatedNewPrice <= 0) {
      try {
        await addSessionToUser(course.id, userStore.user?.id);
        navigate("/myclasses");
      } catch (err) {
        console.error("Error subscribing user automatically:", err);
        toast.error(
          "Unable to complete automatic subscription. Please try again."
        );
      }
    }
  };

  if (!course) {
    return <div>Loading course details...</div>;
  }

  return (
    <div className={styles.payment_details}>
      <div className={styles.course_details}>
        <img
          src={`${env.IMAGE_API}${
            course?.sessionCourse?.image?.name || course?.image?.name
          }`}
          alt={course?.title}
        />

        <div className={styles.course_info}>
          <div className={styles.course_info_header}>
            {course?.domains?.map((domain: any, i: number) => (
              <span key={i} className={styles.course_category}>
                {domain?.name || domain}
              </span>
            ))}
            <span className={styles.course_title}>{course?.title}</span>
            <p>{course?.description}</p>
          </div>

          <div className={styles.course_footer}>
            <div className={styles.course_instructor}>
              <img
                src="/images/we-code.jpeg"
                alt={course?.tutors?.fullName}
                className={styles.course_instructor_image}
                width={20}
                height={20}
              />
              <p className={styles.course_instructor_name}>
                {course?.tutors?.fullName}
              </p>
            </div>
            <p className={styles.course_price}>{price}</p>
          </div>
        </div>
      </div>

      <div className={styles.payment_billing}>
        <h4>{t("paymentDetails.billingSummary")}</h4>

        <div className={styles.payment_billing_details}>
          <div className={styles.payment_billing_details_item}>
            <span>{t("paymentDetails.subtotal")}</span>
            <span>{price}</span>
          </div>
          <div className={styles.payment_billing_details_item}>
            <span>{t("paymentDetails.discount")}</span>
            <span>{discount}</span>
          </div>
          {/* <div className={styles.payment_billing_details_item}>
            <span>Tax</span>
            <span>{price * tax}</span>
          </div> */}

          <span className={styles.promo_code_text}>
            {t("paymentDetails.promoQuestion")}
          </span>
        </div>

        <div className={styles.promo_code}>
          <div style={{ flex: 1 }}>
            <Input
              fullWidth
              label={t("paymentDetails.promoLabel")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          {couponApplied ? (
            <Button
              text={t("paymentDetails.couponApplied")}
              width="60%"
              isDisabled
            />
          ) : (
            <Button
              text={t("paymentDetails.apply")}
              width="30%"
              onClick={useCode}
            />
          )}
        </div>

        <hr />

        <div
          className={`${styles.payment_billing_details_item} ${styles.payment_billing_details_total}`}
        >
          <span>{t("paymentDetails.total")}</span>
          <span>{NewPrice}</span>
        </div>

        <div className={styles.checkbox_container}>
          <input
            type="checkbox"
            id="agree"
            name="agree"
            value="Bike"
            checked={agree}
            onChange={() => setAgree(!agree)}
          />
          <label htmlFor="agree">
            {t("paymentDetails.acknowledgePolicy")}
            <a
              rel="noreferrer"
              href="https://blog.classx.tech/privacy-policy/"
              target="_blank"
              className={styles.privacy_link}
            >
              {t("paymentDetails.privacy")}
            </a>
            <span className={styles.and_symbol}> & </span>
            <a
              rel="noreferrer"
              href="https://blog.classx.tech/terms"
              target="_blank"
              className={styles.privacy_link}
            >
              {t("paymentDetails.termsPolicy")}
            </a>
          </label>
        </div>

        <Button
          text={`${t("paymentDetails.pay")} ${NewPrice} ${userStore.currency}`}
          onClick={() => pay()}
          isDisabled={!agree}
          isLoading={buttonLoading}
        />
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default observer(PaymentDetails);
