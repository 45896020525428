import { useEffect, useState } from "react";
import env from "../config/env";

const apiKey = env.GOOGLE_MAPS_API_KEY!;
let isScriptLoaded = false;

export function useGoogleMaps() {
  const [isLoaded, setIsLoaded] = useState(isScriptLoaded);

  useEffect(() => {
    if (isScriptLoaded) {
      setIsLoaded(true);
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.onload = () => {
      isScriptLoaded = true;
      setIsLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      if (!isScriptLoaded) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return isLoaded;
}
