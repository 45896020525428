import { createRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./payment.module.css";
import { Footer, Navbar, PaymentComponents } from "../../../components";
import { OtherCourses } from "../../../components/others/courses";
import { useStore } from "../../../store";
import { observer } from "mobx-react";

function Payment() {
  const params = useParams();
  const { courseStore } = useStore();

  const [agree, setAgree] = useState<boolean>(false);
  const [status, setStatus] = useState<1 | 2>(1);
  const [paymentMethod, setPaymentMethod] = useState<
    "PostWire" | "Onsite" | "KonnectPayment" | "StripePayment"
  >("Onsite");

  const submitRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.id) {
      courseStore.getTopRatedCourses(4);
      courseStore.getCohortById(params.id);
    }
  }, []);

  return (
    <div>
      <Navbar />

      <main>
        <div className={styles.payment_container}>
          <PaymentComponents.PaymentBreadcrumbs />

          <div className={styles.payment_content}>
            <PaymentComponents.PaymentForm
              agree={agree}
              status={status}
              setStatus={setStatus}
              submitRef={submitRef}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              courseTitle={courseStore.courseDetails?.title ?? ""}
            />
            <PaymentComponents.PaymentDetails
              status={status}
              submitRef={submitRef}
              agree={agree}
              setAgree={setAgree}
              setStatus={setStatus}
              paymentMethod={paymentMethod}
            />
          </div>
        </div>

        <OtherCourses />
      </main>

      <Footer />
    </div>
  );
}

export default observer(Payment);
