import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import React from "react";

type ZoomProps = {
  zoom: number;
  onZoomChange: (zoom: number) => void;
};

export const Zoom = ({ zoom, onZoomChange }: ZoomProps) => {
  const handleZoomIn = () => {
    if (zoom < 22) {
      onZoomChange(zoom + 1);
    }
  };
  const handleZoomOut = () => {
    if (zoom > 0) {
      onZoomChange(zoom - 1);
    }
  };

  return (
    <div className="absolute top-2.5 right-2.5 z-[1] hidden sm:flex flex-col gap-2 px-1 py-2 bg-white rounded-full shadow-md">
      <img
        src="/images/icons/plus.svg"
        className="cursor-pointer"
        onClick={handleZoomIn}
      />
      <hr />
      <img
        src="/images/icons/minus.svg"
        className="cursor-pointer"
        onClick={handleZoomOut}
      />
    </div>
  );
};
