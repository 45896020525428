import { useStore } from "../../../../store";
import { observer } from "mobx-react";
import env from "../../../../config/env";
import { useEffect, useState } from "react";

function AboutCreator() {
  const { courseStore } = useStore();
  const instructorData = courseStore?.classDetails?.tutors;
  const instructorImage =
    instructorData &&
    instructorData?.image &&
    instructorData?.image?.name &&
    instructorData?.image?.name !== ""
      ? `${env.IMAGE_API}${instructorData?.image?.name}`
      : "/images/default-user.svg";

  const getInstructorBio = () => {
    if (instructorData) {
      if (instructorData?.description) {
        return instructorData?.description;
      } else if (instructorData?.bio) {
        return instructorData?.bio;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  // StatsDisplay component
  function StatsDisplay({ instructorData }: { instructorData: any }) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const checkScreenSize = () => {
        setIsMobile(window.innerWidth < 640);
      };

      checkScreenSize();

      window.addEventListener("resize", checkScreenSize);

      return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    if (isMobile) {
      return (
        <div className="flex flex-col sm:block gap-2 sm:gap-4">
          <div className="flex gap-2 sm:gap-4">
            <div className="flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
              <img
                width={20}
                height={20}
                src="/images/icons/star-purple.svg"
                alt=""
              />
              {!instructorData?.rating || instructorData?.rating === 0 ? (
                <span className="heading-4 flex flex-col items-center gap-0.5">
                  5
                  <span className="caption-text text-primary-purple-850">
                    Reviews
                  </span>
                </span>
              ) : (
                <span className="heading-4 flex flex-col items-center gap-0.5">
                  {instructorData?.rating?.toLocaleString() || 0}
                  <span className="caption-text text-primary-purple-850">
                    Reviews
                  </span>
                </span>
              )}
            </div>
            <div className="flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
              <img
                width={20}
                height={20}
                src="/images/icons/course-purple.svg"
                alt=""
              />
              {!instructorData?.coursesCount ||
              instructorData?.coursesCount === 0 ? (
                <span className="heading-4 flex flex-col items-center gap-0.5">
                  3
                  <span className="caption-text text-primary-purple-850">
                    Courses
                  </span>
                </span>
              ) : (
                <span className="heading-4 flex flex-col items-center gap-0.5">
                  {instructorData?.coursesCount?.toLocaleString() || 0}
                  <span className="caption-text text-primary-purple-850">
                    Courses
                  </span>
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
            <img
              width={20}
              height={20}
              src="/images/icons/user-purple.svg"
              alt=""
            />
            {!instructorData?.studentsCount ||
            instructorData?.studentsCount === 0 ? (
              <span className="heading-4 flex flex-col items-center gap-0.5">
                23
                <span className="caption-text text-primary-purple-850">
                  Students
                </span>
              </span>
            ) : (
              <span className="heading-4 flex flex-col items-center gap-0.5">
                {instructorData?.studentsCount?.toLocaleString() || 0}
                <span className="caption-text text-primary-purple-850">
                  Students
                </span>
              </span>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col sm:block gap-2 sm:gap-4">
        <div className="flex gap-2 sm:gap-4">
          <div className="flex flex-col items-center   gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
            <img
              width={20}
              height={20}
              src="/images/icons/star-purple.svg"
              alt=""
            />
            {!instructorData?.rating || instructorData?.rating === 0 ? (
              <div className="heading-4 flex flex-col items-center gap-0.5">
                5
                <span className="caption-text text-primary-purple-850">
                  Reviews
                </span>
              </div>
            ) : (
              <div className="heading-4 flex flex-col items-center gap-0.5">
                {instructorData?.rating?.toLocaleString() || 0}
                <span className="caption-text text-primary-purple-850">
                  Reviews
                </span>
              </div>
            )}
          </div>

          <div className="sm:flex flex-col items-center  gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
            <img
              width={20}
              height={20}
              src="/images/icons/user-purple.svg"
              alt=""
            />
            {!instructorData?.studentsCount ||
            instructorData?.studentsCount === 0 ? (
              <div className="heading-4 flex flex-col items-center gap-0.5">
                23
                <span className="caption-text text-primary-purple-850">
                  Students
                </span>
              </div>
            ) : (
              <div className="heading-4 flex flex-col items-center gap-0.5">
                {instructorData?.studentsCount?.toLocaleString() || 0}
                <span className="caption-text text-primary-purple-850">
                  Students
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-col items-center  gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
            <img
              width={20}
              height={20}
              src="/images/icons/course-purple.svg"
              alt=""
            />
            {!instructorData?.coursesCount ||
            instructorData?.coursesCount === 0 ? (
              <div className="heading-4 flex flex-col items-center  gap-0.5">
                3
                <span className="caption-text text-primary-purple-850">
                  Courses
                </span>
              </div>
            ) : (
              <div className="heading-4 flex flex-col items-center  gap-0.5">
                {instructorData?.coursesCount?.toLocaleString() || 0}
                <span className="caption-text text-primary-purple-850">
                  Courses
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex flex-col gap-4 pt-[20px] lg:pt-[80px] md:items-center w-full"
      id="about-creator"
    >
      <h3 className="body-large-bold text-left sm:text-center">
        About the Instructor
      </h3>
      <div className="flex flex-col gap-[22px] sm:gap-[30px] w-full p-4 sm:p-[50px] border border-primary-gray-200 rounded-[36px] sm:rounded[54px] bg-primary-gray-50 max-w-[800px]">
        <div className="flex items-center  gap-4 sm:gap-[26px] lg:gap-[30px]">
          <img
            alt=""
            className="hidden lg:block size-[186px] rounded-full object-cover"
            src={instructorImage}
          />
          <div className="flex flex-col gap-[22px]">
            <div className="flex items-center gap-4 sm:gap[26px] lg:gap-0">
              <img
                alt=""
                className="block lg:hidden size-[76px] sm:size-[116px] rounded-full object-cover"
                src={instructorImage}
              />
              <div className="flex flex-col sm:gap-1.5">
                <h3 className="text-body-large font-body-large sm:text-heading sm:font-heading-medium text-primary-purple-850">
                  {instructorData?.fullName}
                </h3>
                {instructorData?.fullName && (
                  <span className="text-caption-text font-caption-text-bold sm:text-body-small sm:font-body-small-bold text-primary-purple-800">
                    {instructorData.fullName}
                  </span>
                )}
              </div>
            </div>
            <p className="body-small pl-4 border-l-[3px] h-fit border-primary-purple-500 text-primary-paradise-pink-800">
              {getInstructorBio()}
            </p>
          </div>
        </div>
        <StatsDisplay instructorData={instructorData} />
      </div>
    </div>
  );
}

export default observer(AboutCreator);
