import env from "../../../../config/env";
import Stars from "src/components/shared/stars";
import { memo, useState } from "react";
import Popup from "./Popup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

const CustomDot: React.FC<CustomDotProps> = memo(({ onClick, active }) => {
  return (
    <button
      className={`size-1.5 rounded-full mx-[2px] mb-1.5 bg-white transition-all duration-300 ${
        active ? "opacity-90" : "opacity-35"
      }`}
      onClick={onClick}
    />
  );
});
const CustomButtonGroup: React.FC<any> = ({ next, previous }) => {
  return (
    <div className="opacity-0 sm:group-hover:opacity-100 absolute top-1/2 -translate-y-1/2 flex items-center justify-between w-full transition-all duration-300">
      <div
        className={`flex items-center justify-center p-2.5 ml-2 bg-primary-gray-50 opacity-70 hover:opacity-100 rounded-full transition-all duration-300`}
        onClick={previous}
      >
        <img src={`/images/icons/arrow-left-purple1.svg`} />
      </div>
      <div className="w-full z-[1]"></div>
      <div
        className={`flex items-center justify-center p-2.5 mr-2 bg-primary-gray-50 opacity-70 hover:opacity-100 rounded-full transition-all duration-300`}
        onClick={next}
      >
        <img src={`/images/icons/arrow-right-purple1.svg`} />
      </div>
    </div>
  );
};

interface ClassroomProps {
  id: string;
  image: any;
  name: string;
  capacity: number;
  rating: number;
  ratingsCount: number;
  location: any;
  requested: boolean;
  equipments: string[];
  pricePerHour?: number;
  album: any[];
  handleRequest: (id: string) => void;
}

export default function Classroom({
  id,
  image,
  name,
  capacity,
  rating,
  ratingsCount,
  location,
  requested,
  equipments,
  pricePerHour,
  album,
  handleRequest,
}: ClassroomProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClick = () => {
    handleRequest(id);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  const images = [image, ...album];

  return (
    <div
      className={`group flex flex-col sm:flex-row gap-2.5 sm:p-1.5 sm:hover:bg-primary-purple-50 sm:border hover:border-primary-purple-200 rounded-xl cursor-pointer transition-all duration-200 ${
        requested
          ? "bg-primary-purple-50 border-primary-purple-200"
          : "bg-primary-gray-50 border-primary-gray-50"
      }`}
    >
      <div className="group w-full sm:w-[220px] ">
        <Carousel
          responsive={responsive}
          showDots
          arrows={false}
          infinite
          customDot={<CustomDot />}
          customButtonGroup={<CustomButtonGroup />}
        >
          {images.map((image, index) => (
            <img
              key={index}
              className="w-full h-[150px] object-cover rounded-xl"
              src={
                image?.name
                  ? `${env.IMAGE_API}${image.name}`
                  : "/images/course-default.png"
              }
              alt="classroom-image"
            />
          ))}
        </Carousel>
      </div>

      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-col gap-1.5">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1.5">
                <img
                  src="/images/icons/location-purple.svg"
                  width={14}
                  height={14}
                />
                <span className="caption-text text-primary-purple-800">
                  {location?.name}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <img
                  src="/images/icons/user-purple.svg"
                  width={16}
                  height={16}
                />
                <span className="caption-text text-primary-purple-500">
                  {capacity}
                </span>
              </div>
            </div>

            <h3 className="body-medium-bold">{name}</h3>

            <div className="flex items-center gap-2.5">
              <span className="caption-text-bold text-primary-gray-500">
                {rating}
              </span>
              <Stars rating={rating}></Stars>
              <span className="caption-text text-primary-gray-300">
                ({ratingsCount})
              </span>
            </div>
          </div>

          <div className="flex flex-wrap gap-1.5">
            {Array.from(
              { length: Math.min(2, equipments.length) },
              (_, index) => (
                <span
                  key={index}
                  className="caption-text px-4 py-1 text-primary-purple-800 bg-primary-purple-100 border border-primary-purple-200 rounded-full"
                >
                  {equipments[index]}
                </span>
              )
            )}
            {equipments.length > 2 && (
              <span
                className="caption-text px-4 py-1 text-primary-purple-800 bg-primary-purple-100 hover:bg-primary-purple-200 border border-primary-purple-200 rounded-full cursor-pointer"
                onClick={() => setIsPopupOpen(true)}
              >
                ...
              </span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="body-small-bold">
            {pricePerHour ? `$${pricePerHour}` : "20%"}
            <span className="caption-text-bold">
              {pricePerHour ? " / HOUR" : " / STUDENT"}
            </span>
          </div>
          <div className="large-button opacity-100 sm:opacity-0 group-hover:opacity-100 flex items-center gap-1.5 transition-all duration-200">
            <span className="hover:text-primary-paradise-pink-500">
              Details
            </span>
            <span
              className="text-primary-paradise-pink-500 hover:text-primary-paradise-pink-600"
              onClick={handleClick}
            >
              {requested ? "Unselect" : "Select"}
            </span>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          name={name}
          location={location.name}
          equipments={equipments}
          closePopup={() => setIsPopupOpen(false)}
        />
      )}
    </div>
  );
}
