import { observer } from "mobx-react";

import Slider from "react-slick";
import env from "../../../../config/env";
import { useStore } from "../../../../store";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./classrooms.css";
import { useTranslation } from "react-i18next";

function CourseClassrooms() {
  const { courseStore } = useStore();
  const { t } = useTranslation();

  const course: any = courseStore.courseDetails;

  const duplicatedClassrooms = course?.sessionBooking
    ?.map((classroom: any) => {
      if (!classroom?.bookingRoom?.isOnline) {
        return {
          logo: classroom?.bookingRoom?.logo,
          name: classroom?.bookingRoom?.name,
          address: classroom?.bookingRoom?.address,
          album: classroom?.bookingRoom?.album,
        };
      }
      return null;
    })
    .filter((classroom: any) => classroom !== null);

  // remove duplicated classrooms from the array to avoid seeing the same classroom multiple times
  const classrooms = duplicatedClassrooms?.reduce(
    (newClassroomsList: any[], currentRoom: any) => {
      const isDuplicate = newClassroomsList.some(
        (room: any) => room.name === currentRoom.name
      );
      if (!isDuplicate) {
        newClassroomsList.push(currentRoom);
      }
      return newClassroomsList;
    },
    []
  );

  const classroomsPics: any[] = [];
  if (classrooms && classrooms.length > 0) {
    classrooms.forEach((classroom: any) => {
      if (classroom.album && classroom.album.length > 0) {
        classroom.album.forEach((pic: any) => {
          if (pic.name && pic.name !== "") {
            const newClassroomPic = {
              address: classroom.address?.name || "",
              name: classroom.name,
              picture: pic,
            };
            classroomsPics.push(newClassroomPic);
          } else {
            const newClassroomPic = {
              address: classroom.address?.name || "",
              name: classroom.name,
              picture: {
                container: "img",
                name: "aecb67ca-94da-4025-bef3-d13971862117.jpg",
                type: "jpg",
                field: "",
                originalFilename: "default-image.jpg",
                size: 3971055,
              },
            };

            classroomsPics.push(newClassroomPic);
          }
        });
      } else {
        const newClassroomPic = {
          address: classroom.address?.name || "",
          name: classroom.name,
          picture: {
            container: "img",
            name: "aecb67ca-94da-4025-bef3-d13971862117.jpg",
            type: "jpg",
            field: "",
            originalFilename: "default-image.jpg",
            size: 3971055,
          },
        };

        classroomsPics.push(newClassroomPic);
      }
    });
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      {classroomsPics?.length > 0 && (
        <div className="course_classrooms" id="classrooms">
          <div className="course_classrooms_content">
            <h3 className="course_classrooms_title">
              {t("messages.classrooms")}
            </h3>
            <div className="slick_container">
              <Slider {...settings}>
                {classroomsPics?.map((classroom: any, index: number) => (
                  <div key={index}>
                    <img
                      className="classroom_image"
                      src={
                        classroom.picture &&
                        classroom.picture.name &&
                        classroom.picture.name !== ""
                          ? `${env.IMAGE_API}${classroom.picture.name}`
                          : "images/course-default.png"
                      }
                      alt="classroom"
                    />
                    <div className="classroom_details">
                      <h2>{classroom?.name}</h2>
                      <div className="location">
                        <img
                          width={20}
                          height={20}
                          alt="location"
                          src="/images/icons/location-gray.svg"
                        />
                        <span>{classroom?.address}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(CourseClassrooms);
