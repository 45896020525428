import { useEffect, useState } from "react";
import Classroom from "./classroom";
import Map from "./Map/Map";
import { Button } from "src/components/shared";

interface ClassroomsListProps {
  classrooms: any[];
  requestedClassrooms: any[];
  isOnline?: boolean;
  setRequestedClassrooms: (value: any) => void;
  setOnline: () => void;
}

export default function ClassroomsList({
  classrooms,
  requestedClassrooms,
  isOnline,
  setRequestedClassrooms,
  setOnline,
}: ClassroomsListProps) {
  const [showMap, setShowMap] = useState(false);
  const [viewportClassrooms, setViewportClassrooms] =
    useState<any[]>(classrooms);

  useEffect(() => {
    setViewportClassrooms(classrooms);
  }, [classrooms]);

  const handleRequest = (id: string) => {
    console.log(requestedClassrooms);
    if (requestedClassrooms.find((item) => item.id === id)) {
      setRequestedClassrooms(
        requestedClassrooms.filter((item) => item.id !== id)
      );
    } else {
      setRequestedClassrooms([...requestedClassrooms, { id }]);
    }
  };

  const handleClassroomClick = (id: any) => {
    const temp = viewportClassrooms.map((classroom) => {
      if (classroom.id === id) {
        return { ...classroom, openCard: true };
      }
      return { ...classroom, openCard: false };
    });
    setViewportClassrooms(temp);
  };

  const handleClassroomHover = (id: any) => {
    const temp = viewportClassrooms.map((classroom) => {
      if (classroom.id === id) {
        return { ...classroom, hover: true };
      }
      return { ...classroom, hover: false };
    });
    setViewportClassrooms(temp);
  };

  return (
    <div
      className={`flex lg:gap-2 ${
        isOnline ? "h-full" : "h-[calc(100vh-156px)] sm:h-[calc(100vh-174px)]"
      }`}
    >
      <div
        className={`custom_scrollbar w-full lg:h-full overflow-y-scroll overflow-x-hidden ${
          showMap ? "hidden" : "block"
        } lg:block ${viewportClassrooms.length > 0 ? "h-fit" : "h-full"} ${
          isOnline ? "lg:w-full" : "lg:w-2/5"
        }`}
      >
        {viewportClassrooms.length > 0 ? (
          <div className="flex flex-col gap-4 sm:gap-2 mr-1">
            {viewportClassrooms.map((classroom) => (
              <div
                onClick={() => handleClassroomClick(classroom.id)}
                key={classroom.id}
                onMouseEnter={() => handleClassroomHover(classroom.id)}
                onMouseLeave={() => handleClassroomHover(null)}
              >
                <Classroom
                  id={classroom.id}
                  image={classroom.logo}
                  name={classroom.name}
                  capacity={classroom.size}
                  rating={classroom.rating}
                  ratingsCount={classroom.ratingsCount}
                  location={classroom.address}
                  requested={requestedClassrooms.find(
                    (item) => item.id === classroom.id
                  )}
                  equipments={classroom?.equipments || []}
                  handleRequest={handleRequest}
                  pricePerHour={classroom.pricePerHour}
                  album={classroom.album}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-5 h-full">
            <img src="/images/icons/empty.svg" />
            <div className="text-center">
              <h3 className="body-large-bold text-primary-gray-850">
                No Classrooms Found
              </h3>
              <p className="body-medium text-primary-gray-600">
                Oops, no classrooms found in this area. How <br /> about hosting
                your class online instead?
              </p>
            </div>
            <Button
              type="secondary"
              text="Switch to Online"
              width="fit-content"
              customStyle={{ height: "fit-content" }}
              onClick={setOnline}
            />
          </div>
        )}
      </div>

      {!isOnline && (
        <div
          className={`w-full lg:w-3/5 lg:pb-5 ${
            showMap ? "block" : "hidden"
          } lg:block`}
        >
          <Map
            classrooms={classrooms?.map((classroom) => ({
              ...classroom,
              requested: !!requestedClassrooms.find(
                (item) => item.id === classroom.id
              ),
            }))}
            viewportClassrooms={viewportClassrooms?.map((classroom) => ({
              ...classroom,
              requested: !!requestedClassrooms.find(
                (item) => item.id === classroom.id
              ),
            }))}
            handleRequest={handleRequest}
            setViewportClassrooms={setViewportClassrooms}
            handleClassroomClick={handleClassroomClick}
            handleClassroomHover={handleClassroomHover}
          />
        </div>
      )}

      <div
        className="fixed bottom-4 left-1/2 -translate-x-1/2 lg:hidden flex items-center gap-2.5 px-6 py-3 text-primary-paradise-pink-500 bg-primary-paradise-pink-50 hover:bg-primary-paradise-pink-200 border border-primary-paradise-pink-50 hover:border-primary-paradise-pink-500 rounded-full cursor-pointer transition-all duration-300"
        onClick={() => setShowMap(!showMap)}
        style={{
          boxShadow: "0px 6px 20px 0px #0000001A",
          backdropFilter: "blur(14.5px)",
        }}
      >
        {showMap ? "Show Listings" : "Show Map"}
        <img
          src={showMap ? "/images/icons/list.svg" : "/images/icons/map.svg"}
        />
      </div>
    </div>
  );
}
