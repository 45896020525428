import React from "react";

import styles from "./blog.module.css";

interface IProps {
  blog: {
    title: string;
    description: string;
    date: string;
    image: string;
    link: string;
    tags: string[];
  };
}

const Blog: React.FC<IProps> = ({ blog }) => {
  return (
    <a
      href={blog.link}
      target="_blank"
      className="flex flex-col h-full cursor-pointer" rel="noreferrer"
    >
      <img
        className="rounded-xl h-full mb-3"
        src={blog.image}
        alt={blog.title}
        loading="lazy"
      />

      <div className="mb-5 flex-grow">
        <span className="body-small-bold text-primary-paradise-pink-500">
          {new Date(blog.date).toDateString()}
        </span>
        <h3 className={styles.blog_title}>{blog.title}</h3>
      </div>

      <div className="flex justify-between items-center mt-auto">
        <div className="flex flex-wrap gap-1.5">
          {blog.tags.map((tag, index) => (
            <span
              key={index}
              className="caption-text px-3.5 py-1 text-primary-purple-500 bg-primary-purple-100 rounded-xl"
            >
              {tag}
            </span>
          ))}
        </div>
        <img src="/images/icons/explore.svg" loading="lazy"/>
      </div>
    </a>
  );
};

export default Blog;
