import env from "../../../../../config/env";
import Stars from "src/components/shared/stars";
import { memo, useState } from "react";
import Popup from "../Popup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

const CustomDot: React.FC<CustomDotProps> = memo(({ onClick, active }) => {
  return (
    <button
      className={`size-1.5 rounded-full mx-[2px] mb-1.5 bg-white transition-all duration-300 ${
        active ? "opacity-90" : "opacity-35"
      }`}
      onClick={onClick}
    />
  );
});
const CustomButtonGroup: React.FC<any> = ({ next, previous }) => {
  return (
    <div className="opacity-0 sm:group-hover:opacity-100 absolute top-1/2 -translate-y-1/2 flex items-center justify-between w-full transition-all duration-300">
      <div
        className={`flex items-center justify-center p-2.5 ml-2 bg-primary-gray-50 opacity-70 hover:opacity-100 rounded-full transition-all duration-300 cursor-pointer`}
        onClick={previous}
      >
        <img src={`/images/icons/arrow-left-purple1.svg`} />
      </div>
      <div className="w-full z-[1]"></div>
      <div
        className={`flex items-center justify-center p-2.5 mr-2 bg-primary-gray-50 opacity-70 hover:opacity-100 rounded-full transition-all duration-300 cursor-pointer`}
        onClick={next}
      >
        <img src={`/images/icons/arrow-right-purple1.svg`} />
      </div>
    </div>
  );
};

interface IProps {
  classroom: {
    id: string;
    logo: any;
    name: string;
    size: number;
    rating: number;
    ratingsCount: number;
    address: any;
    requested: boolean;
    album: any[];
    equipments: string[];
  };
  handleRequest?: (id: string) => void;
}

export default function Card({
  classroom: {
    id,
    logo,
    name,
    size,
    rating,
    ratingsCount,
    address,
    requested,
    album,
    equipments,
  },
  handleRequest,
}: IProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClick = () => {
    handleRequest && handleRequest(id);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  const images = [logo, ...album];

  return (
    <div
      className="bg-white rounded-xl"
      style={{ boxShadow: "0px 18px 24px -8px #00000012" }}
    >
      <div className="group w-[257px] rounded-tr-xl rounded-tl-xl overflow-hidden">
        <Carousel
          responsive={responsive}
          showDots
          arrows={false}
          infinite
          customDot={<CustomDot />}
          customButtonGroup={<CustomButtonGroup />}
        >
          {images.map((image, index) => (
            <img
              key={index}
              className="w-full h-[150px] object-cover"
              src={
                image?.name
                  ? `${env.IMAGE_API}${image.name}`
                  : "/images/course-default.png"
              }
              alt="classroom-image"
            />
          ))}
        </Carousel>
      </div>

      <div className="flex flex-col gap-3 p-2.5">
        <div>
          <div className="flex items-center gap-1.5">
            <img
              src="/images/icons/location-purple.svg"
              width={14}
              height={14}
            />
            <span className="caption-text text-primary-purple-800">
              {address?.name}
            </span>
          </div>

          <h3 className="body-medium">{name}</h3>

          <div className="flex items-center gap-2.5">
            <span className="caption-text-bold text-primary-gray-500">
              {rating}
            </span>
            <Stars rating={rating}></Stars>
            <span className="caption-text text-primary-gray-300">
              ({ratingsCount})
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <img src="/images/icons/user-purple.svg" width={16} height={16} />
            <span className="caption-text text-primary-purple-500">{size}</span>
          </div>
          <div className="large-button flex items-center gap-1.5 transition-all duration-200">
            <span
              className="hover:text-primary-paradise-pink-500 cursor-pointer"
              onClick={() => setIsPopupOpen(true)}
            >
              Details
            </span>
            <span
              className="text-primary-paradise-pink-500 hover:text-primary-paradise-pink-600 cursor-pointer"
              onClick={handleClick}
            >
              {requested ? "Unselect" : "Select"}
            </span>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          name={name}
          location={address?.name}
          equipments={equipments}
          closePopup={() => setIsPopupOpen(false)}
        />
      )}
    </div>
  );
}
