import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./navbar.module.css";
import { Button } from "..";
import { useStore } from "../../../store";
import { observer } from "mobx-react";
import Beta from "./Beta";
import LanguageDropdown from "./LanguageDropdown";
import env from "../../../config/env";
import { useBetaNotification } from "src/store/useBetaNotification";

const Navbar: React.FC<{ defaultTransparent?: boolean; fixed?: boolean }> = ({
  defaultTransparent,
  fixed = true,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userStore } = useStore();
  const { isBetaVisible } = useBetaNotification();

  let user = userStore.user;
  let role = user?.roles ? user?.roles[user?.roles.length - 1].name : "Student";
  let userRoles =
    user?.roles &&
    user?.roles.map((role: any) => {
      return role.name;
    });
  const [dropdownItems, setDropdownItems] = useState<any[]>([
    {
      name: "My Courses",
      path: "/myclasses",
    },
    {
      name: "My Classes",
      path: "/myclasses",
    },
    {
      name: "Wishlist",
      path: "/favorites",
    },
    {
      name: "My Reviews",
      path: "/",
    },
    {
      name: "Settings",
      path: "/myprofile",
    },
  ]);

  const [language, setLanguage] = useState("English");
  const [currency, setCurrency] = useState("USD");
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [transparent, setTransparent] = useState(defaultTransparent);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const userPicRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user) {
      userStore.getUser().then(() => {
        user = userStore.user;
        if (user?.roles) {
          role = user?.roles[user?.roles.length - 1].name;
          userRoles = user?.roles.map((role: any) => {
            return role.name;
          });
        }
      });
    }
    initDropDownItems();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        userPicRef.current &&
        !userPicRef.current.contains(event.target as Node)
      ) {
        setDropdownMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [user, setDropdownMenu]);

  const handleLogout = () => {
    userStore.logout();
    navigate("/");
  };

  const handleMobileLogout = () => {
    setShowSidebar(false);
    userStore.logout();
    navigate("/");
  };

  const initDropDownItems = () => {
    const items = [];

    if (userRoles && userRoles.length > 0) {
      if (role.toLowerCase() === "student") {
        items.push(
          { name: t("navbar.profile"), path: "/myprofile" },
          { name: t("navbar.browseCourses"), path: "/search" },
          { name: t("navbar.myClasses"), path: "/myclasses" },
          { name: t("navbar.myReviews"), path: "/" },
          { name: t("navbar.wishlist"), path: "/favorites" },
          {
            name: t("navbar.settings"),
            path: "/myprofile",
            state: { activeTab: "privacy" },
          }
        );
      }

      if (role.toLowerCase() === "teacher") {
        items.push(
          { name: t("navbar.profile"), path: "/myprofile" },
          { name: t("navbar.browseCourses"), path: "/search" },
          {
            name: t("navbar.manage"),
            path: "/teaching",
          },
          { name: t("navbar.classHistory"), path: "/myclasses" },
          { name: t("navbar.myReviews"), path: "/" },
          { name: t("navbar.wishlist"), path: "/favorites" },
          {
            name: t("navbar.settings"),
            path: "/myprofile",
            state: { activeTab: "privacy" },
          }
        );
      }

      if (role.toLowerCase() === "host") {
        items.push(
          { name: t("navbar.profile"), path: "/myprofile" },
          { name: t("navbar.browseCourses"), path: "/search" },
          {
            name: t("navbar.manage"),
            path: "/classroomsManagement",
          },
          { name: t("navbar.classHistory"), path: "/myclasses" },
          { name: t("navbar.myReviews"), path: "/" },
          { name: t("navbar.wishlist"), path: "/favorites" },
          {
            name: t("navbar.settings"),
            path: "/myprofile",
            state: { activeTab: "privacy" },
          }
        );
      }

      if (role.toLowerCase() === "admin") {
        items.push(
          { name: t("navbar.profile"), path: "/myprofile" },
          { name: t("navbar.browseCourses"), path: "/search" },
          {
            name: t("navbar.manage") + " " + t("navbar.courses"),
            path: "/teaching",
          },
          {
            name: t("navbar.manage") + " " + t("navbar.listYourSpace"),
            path: "/classroomsManagement",
          },
          { name: t("navbar.myClasses"), path: "/myclasses" },
          { name: t("navbar.wishlist"), path: "/favorites" },
          { name: t("navbar.myReviews"), path: "/" },
          {
            name: t("navbar.settings"),
            path: "/myprofile",
            state: { activeTab: "privacy" },
          }
        );
      }
    }

    setDropdownItems(items);
  };

  window.addEventListener("scroll", () => {
    setTransparent(window.scrollY <= 50 && defaultTransparent);
  });

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Beta />
      {isBetaVisible ? (
        fixed ? (
          <div className="h-[140px]" />
        ) : (
          <div className="h-[50px]" />
        )
      ) : (
        fixed && <div className="h-[90px]"></div>
      )}
      <nav
        className={
          styles.navbar_container +
          (isBetaVisible ? " top-12" : " top-0") +
          (fixed ? " fixed" : "")
        }
        style={
          transparent
            ? { backgroundColor: "transparent" }
            : {
                backgroundColor: "#fff",
                borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
              }
        }
      >
        <div className={styles.navbar_inner}>
          <div className="flex items-center gap-8">
            <Link to="/">
              <img className="h-7 sm:h-10" src="/images/logo.svg" alt="Logo" />
            </Link>
            <div className={`hidden ${user ? "" : "sm:block"}`}>
              <LanguageDropdown
                language={userStore.language}
                currency={userStore.currency}
                setLanguage={setLanguage}
                setCurrency={setCurrency}
              />
            </div>
          </div>

          {!user && (
            <div className={styles.navbar_links}>
              <Link to="/courses">{t("navBarDisconnected.courses")}</Link>
              <a href="https://community.classx.tech/teach-with-us">
                {t("navBarDisconnected.teachWithUs")}
              </a>
              <a href="https://community.classx.tech/list-your-space/">
                {t("navBarDisconnected.listYourSpace")}
              </a>
              <a href="https://community.classx.tech/about/">
                {t("navBarDisconnected.about")}
              </a>
            </div>
          )}
          {user && role.toLowerCase() === "student" && (
            <div className={styles.navbar_links}>
              <Link to="/courses">{t("navbar.browse")}</Link>
              <Link to="/myclasses">{t("navbar.classe")}</Link>
            </div>
          )}

          {((user && role.toLowerCase() === "teacher") ||
            (user && role.toLowerCase() === "host") ||
            (user && role.toLowerCase() === "admin")) && (
            <div className={styles.navbar_links}>
              <Link to="/courses">{t("navbar.browse")}</Link>
              <Link
                to={
                  role.toLowerCase() === "host"
                    ? "/classroomsManagement"
                    : "/teaching"
                }
              >
                {t("navbar.manage")}
              </Link>
              <Link to="/myclasses">{t("navbar.classe")}</Link>
            </div>
          )}

          {!user ? (
            <>
              <div className={styles.navbar_buttons}>
                <Link to="/login" className={styles.navbar_button}>
                  {t("navbar.signIn")}
                </Link>
                <Button
                  text={t("navbar.createAccount")}
                  onClick={() => navigate("/register")}
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-8 items-center relative">
                <div className={`hidden lg:flex items-center gap-8`}>
                  <LanguageDropdown
                    language={userStore.language}
                    currency={userStore.currency}
                    setLanguage={setLanguage}
                    setCurrency={setCurrency}
                  />
                  <a href="/favorites">
                    <img
                      className="cursor-pointer"
                      src="/images/icons/wishlist.svg"
                      alt=""
                    />
                  </a>
                </div>
                <div
                  className={styles.navbar_user}
                  onClick={() => setDropdownMenu(!dropdownMenu)}
                  ref={userPicRef}
                >
                  <img
                    className={styles.navbar_avatar}
                    src={
                      user?.image && Object.keys(user?.image).length > 0
                        ? `${env.IMAGE_API}${user?.image?.name}`
                        : "images/default-user.svg"
                    }
                    alt="profile"
                  />
                </div>

                {dropdownMenu && (
                  <div
                    className={`${styles.navbar_user_links} absolute left-0 top-14`}
                    ref={dropdownRef}
                  >
                    <div
                      className="flex items-center gap-2.5 p-3.5 border-b border-primary-gray-200 cursor-pointer"
                      onClick={() => navigate("/myprofile")}
                    >
                      <img
                        className={styles.navbar_avatar}
                        src={
                          user?.image && Object.keys(user?.image).length > 0
                            ? `${env.IMAGE_API}${user?.image?.name}`
                            : "images/default-user.svg"
                        }
                        alt="profile"
                      />
                      <div>
                        <div className="text-base text-primary-paradise-pink-900 font-normal">
                          {user.fullName}
                        </div>
                        <div className="text-xs leading-6 text-primary-purple-500 font-medium">
                          {capitalizeFirstLetter(role)} Account
                        </div>
                      </div>
                    </div>
                    <div className="py-2 border-b border-primary-gray-200 pt-1.5 min-w-[258px] flex flex-col justify-start items-start">
                      {dropdownItems.map((item, index) => (
                        <Link
                          to={item.path}
                          state={item.state || {}}
                          className={
                            item.name === t("navbar.browseCourses")
                              ? "hidden"
                              : "text-sm text-primary-paradise-pink-800 py-2.5 px-5 w-full cursor-pointer font-normal hover:text-primary-paradise-pink-500 hover:bg-primary-paradise-pink-50"
                          }
                          key={index}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    <div
                      className="flex gap-2 justify-center items-center text-sm font-normal text-tertiary-severe-600 mx-2.5 my-3.5 px-3.5 py-3 rounded-xl border border-tertiary-severe-100 bg-tertiary-severe-50 cursor-pointer"
                      onClick={handleLogout}
                    >
                      <img src="/images/icons/logout.svg" alt="" />
                      <span>{t("navbar.logout")}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className={styles.navbar_mobile}>
            <img
              src={
                showSidebar
                  ? "/images/icons/close.svg"
                  : "/images/icons/menu.svg"
              }
              className="relative z-[150] size-10 sm:size-12"
              alt="menu"
              onClick={() => setShowSidebar(!showSidebar)}
            />

            <div className="relative z-[100] ">
              <div
                className={`fixed inset-0 bg-black transition-opacity duration-200 ${
                  showSidebar ? "opacity-20" : "opacity-0 pointer-events-none"
                }`}
              />
              <div
                className={`fixed top-0 right-0 flex justify-end w-screen transform transition-transform duration-200 ${
                  showSidebar ? "translate-x-0" : "translate-x-full"
                }`}
                style={{
                  height: isBetaVisible ? "calc(100vh - 3rem)" : "100vh",
                }}
              >
                <div className="flex flex-col justify-between w-80 h-screen p-6 bg-primary-gray-50">
                  {!user && (
                    <div className="flex gap-7 flex-col text-xl font-semibold py-[69px]">
                      <Link className="py-0.5 px-1" to="/courses">
                        {t("navbar.courses")}
                      </Link>
                      <a
                        className="py-0.5 px-1"
                        href="https://community.classx.tech/teach-with-us"
                      >
                        {t("navbar.teachWithUs")}
                      </a>
                      <a
                        className="py-0.5 px-1"
                        href="https://community.classx.tech/list-your-space/"
                      >
                        {t("navbar.listYourSpace")}
                      </a>
                      <a
                        className="py-0.5 px-1"
                        href="https://community.classx.tech/about/"
                      >
                        {t("navbar.about")}
                      </a>
                      <div className="py-0.5 px-1">
                        <LanguageDropdown
                          language={language}
                          currency={userStore.currency}
                          setLanguage={setLanguage}
                          setCurrency={setCurrency}
                        />
                      </div>
                    </div>
                  )}
                  {user && (
                    <div className="flex gap-6 flex-col text-xl font-semibold py-[69px]">
                      <div
                        className="flex items-center gap-2.5 cursor-pointer"
                        onClick={() => navigate("/myprofile")}
                      >
                        <img
                          className={styles.navbar_avatar}
                          src={
                            user?.image && Object.keys(user?.image).length > 0
                              ? `${env.IMAGE_API}${user?.image?.name}`
                              : "images/default-user.svg"
                          }
                          alt="profile"
                        />
                        <div>
                          <div className="text-base text-primary-paradise-pink-900 font-normal">
                            {user.fullName}
                          </div>
                          <div className="text-xs leading-6 text-primary-purple-500 font-medium">
                            {capitalizeFirstLetter(role)} Account
                          </div>
                        </div>
                      </div>
                      {dropdownItems.map((item, index) => (
                        <Link
                          key={index}
                          className="py-0.5 px-1 hover:text-primary-paradise-pink-600"
                          to={item.path}
                        >
                          {item.name}
                        </Link>
                      ))}
                      <div className="py-0.5 px-1">
                        <LanguageDropdown
                          language={language}
                          currency={userStore.currency}
                          setLanguage={setLanguage}
                          setCurrency={setCurrency}
                        />
                      </div>
                    </div>
                  )}
                  {!user && (
                    <div className="flex flex-col gap-2.5">
                      <Button
                        type="secondary"
                        text={t("navbar.signIn")}
                        onClick={() => navigate("/login")}
                      />
                      <Button
                        text={t("navbar.createAccount")}
                        onClick={() => navigate("/register")}
                      />
                    </div>
                  )}
                  {user && (
                    <div
                      className="flex gap-2 justify-center items-center text-sm font-normal text-tertiary-severe-600 px-3.5 py-3 rounded-xl border border-tertiary-severe-200 bg-tertiary-severe-50 cursor-pointer"
                      onClick={handleMobileLogout}
                    >
                      <img src="/images/icons/logout.svg" alt="" />
                      <span>{t("navbar.logout")}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default observer(Navbar);
