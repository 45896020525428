import styles from "./stars.module.css";

const Stars: React.FC<{ rating: number }> = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasPartialStar = rating % 1 > 0;
  const partialFill = Math.round((rating % 1) * 100);
  const emptyStars = 5 - fullStars - (hasPartialStar ? 1 : 0);

  return (
    <div className={styles.rating_text}>
      {Array.from({ length: fullStars }, (_, index) => (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.72557 5.49061L7.45725 2.00247C7.67955 1.55468 8.32175 1.55468 8.54406 2.00247L10.2757 5.49061L14.1483 6.0534C14.6452 6.12561 14.8432 6.73288 14.4835 7.08124L11.6818 9.79447L12.343 13.6276C12.4279 14.1198 11.9083 14.4952 11.4637 14.2627L8.00065 12.4519L4.53763 14.2627C4.09305 14.4952 3.57338 14.1198 3.65829 13.6276L4.31949 9.79447L1.51782 7.08124C1.1581 6.73288 1.35613 6.12561 1.853 6.0534L5.72557 5.49061Z"
            fill="#FCD13B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.94244 2.1813C7.92939 2.189 7.91571 2.20125 7.90402 2.2248L6.17234 5.71294C6.09935 5.85997 5.95884 5.9618 5.7964 5.98541L1.92383 6.54821C1.83376 6.5613 1.80604 6.66537 1.86458 6.72206L4.66625 9.43529C4.785 9.55029 4.83923 9.71656 4.81113 9.87946L4.14993 13.7126C4.14553 13.7381 4.14936 13.7553 4.15496 13.7682C4.1614 13.783 4.17333 13.7988 4.19109 13.8116C4.20884 13.8244 4.22824 13.8313 4.24582 13.8329C4.26137 13.8343 4.28054 13.8323 4.30487 13.8196L7.76789 12.0089C7.91303 11.933 8.08612 11.933 8.23126 12.0089L11.6943 13.8196C11.7186 13.8323 11.7378 13.8343 11.7533 13.8329C11.7709 13.8313 11.7903 13.8244 11.8081 13.8116C11.8258 13.7988 11.8378 13.783 11.8442 13.7682C11.8498 13.7553 11.8536 13.7381 11.8492 13.7126L11.188 9.87946C11.1599 9.71656 11.2141 9.55029 11.3329 9.43529L14.1346 6.72206C14.1931 6.66537 14.1654 6.5613 14.0753 6.54821L10.2028 5.98541C10.0403 5.9618 9.8998 5.85997 9.82681 5.71294L8.09513 2.2248C8.08344 2.20125 8.06976 2.189 8.05671 2.1813C8.04186 2.17255 8.02206 2.16663 7.99957 2.16663C7.97709 2.16663 7.95729 2.17255 7.94244 2.1813ZM7.00832 1.78013C7.41442 0.962124 8.58473 0.962124 8.99083 1.78013L10.606 5.0335L14.2191 5.5586C15.1228 5.68993 15.4911 6.80038 14.8302 7.44042L12.2184 9.96984L12.8347 13.5426C12.9911 14.4494 12.0366 15.1271 11.2309 14.7058L7.99957 13.0162L4.76824 14.7058C3.96252 15.1271 3.00806 14.4494 3.16449 13.5426L3.78077 9.96984L1.1689 7.44042C0.50801 6.80039 0.87635 5.68993 1.78001 5.5586L5.39319 5.0335L7.00832 1.78013Z"
            fill="#FCD13B"
          />
        </svg>
      ))}

      {hasPartialStar && (
        <svg width="16" height="16" viewBox="0 0 16 16">
          <defs>
            <linearGradient id="partial-fill" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset={`${partialFill}%`} stopColor="#FCD13B" />
              <stop offset={`${partialFill}%`} stopColor="#DFDFDF" />
            </linearGradient>
          </defs>
          <path
            d="M5.72557 5.49061L7.45725 2.00247C7.67955 1.55468 8.32175 1.55468 8.54406 2.00247L10.2757 5.49061L14.1483 6.0534C14.6452 6.12561 14.8432 6.73288 14.4835 7.08124L11.6818 9.79447L12.343 13.6276C12.4279 14.1198 11.9083 14.4952 11.4637 14.2627L8.00065 12.4519L4.53763 14.2627C4.09305 14.4952 3.57338 14.1198 3.65829 13.6276L4.31949 9.79447L1.51782 7.08124C1.1581 6.73288 1.35613 6.12561 1.853 6.0534L5.72557 5.49061Z"
            fill="url(#partial-fill)"
          />
        </svg>
      )}
      {Array.from({ length: emptyStars }, (_, index) => (
        <svg
          key={`empty-${index}`}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="#DFDFDF"
        >
          <path d="M5.72557 5.49061L7.45725 2.00247C7.67955 1.55468 8.32175 1.55468 8.54406 2.00247L10.2757 5.49061L14.1483 6.0534C14.6452 6.12561 14.8432 6.73288 14.4835 7.08124L11.6818 9.79447L12.343 13.6276C12.4279 14.1198 11.9083 14.4952 11.4637 14.2627L8.00065 12.4519L4.53763 14.2627C4.09305 14.4952 3.57338 14.1198 3.65829 13.6276L4.31949 9.79447L1.51782 7.08124C1.1581 6.73288 1.35613 6.12561 1.853 6.0534L5.72557 5.49061Z" />
        </svg>
      ))}
    </div>
  );
};

export default Stars;
