import { observer } from "mobx-react";
import { toast, ToastContainer } from "react-toastify";
import { useStore } from "../../../store";

import {
  Input,
  Button,
  Navbar,
  DateRange,
  UploadImage,
  Loading,
  LocationInput,
  MultiSelectInput,
} from "../../../components";
import { useEffect, useRef, useState } from "react";
import { Classroom, getClassroomDetails } from "src/store/Classroom";
import { editClassroom } from "src/store/Classroom";
import { useNavigate, useParams } from "react-router-dom";


function EditClassroom() {
  const navigate = useNavigate();
  const { userStore } = useStore();

  const { id } = useParams();
  const [classroomData, setClassroomData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [numberOfPlaces, setNumberOfPlaces] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [equipments, setEquipments] = useState<any[]>([]);
  const [logo, setLogo] = useState<any | null>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [location, setLocation] = useState<any>();
  const [pricePerHour, setPricePerHour] = useState<any>(0);
  const startTimeInputRef = useRef<any>(null);
  const endTimeInputRef = useRef<any>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchClassroomDetails = async () => {
      try {
        setLoading(true);
        const data = await getClassroomDetails(id!);
        if (!data) {
          toast.error("Failed to load classroom details.");
          return;
        }

        // Set data
        setClassroomData(data);
        setName(data.name || "");
        setDescription(data.description || "");
        setNumberOfPlaces(data.size?.toString() || "0");
        setPricePerHour(data.pricePerHour || 0);
        setAddress({
          label: data.address?.city || "",
          value: data.address?.city || "",

        });
        setLocation({
          lat:data?.location.lat,
          lng:data?.location.lng,
        })
        setEquipments(data.equipments || []);
        setStartDate(data.from ? new Date(data.from) : undefined);
        setEndDate(data.to ? new Date(data.to) : undefined);
        setStartTime(
          data.from
            ? new Date(data.from)
                .toLocaleTimeString("en-US", { hour12: false })
                .slice(0, 5)
            : ""
        );
        setEndTime(
          data.to
            ? new Date(data.to)
                .toLocaleTimeString("en-US", { hour12: false })
                .slice(0, 5)
            : ""
        );

        setFiles(data.album || []); // Load existing images
        setLogo(data.logo ? data.logo : null);
      } catch (error) {
        console.error("Error fetching classroom details:", error);
        toast.error("An error occurred while loading classroom details.");
      } finally {
        setLoading(false);
      }
    };

    fetchClassroomDetails();
  }, [id]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      const [decimalPart] = value.split(".");
      if (!decimalPart || decimalPart.length <= 2) {
        setPricePerHour(parseFloat(value) || 0);
      }
    }
  };

  const handleAttachFile = (e: any) => {
    const newFiles: File[] = Array.from(e.target.files);
    const existingFileNames = files.map((file) => file.name);
    const filteredNewFiles = newFiles.filter(
      (file: File) => !existingFileNames.includes(file.name)
    );
    setFiles([...files, ...filteredNewFiles]);
  };
  const triggerAttachFile = (): void => {
    fileUploadRef.current?.click();
  };

  const handleStartTimeFocus = () => {
    if (startTimeInputRef.current) {
      startTimeInputRef.current.showPicker();
      startTimeInputRef.current.focus();
    }
  };

  const handleEndTimeFocus = () => {
    if (endTimeInputRef.current) {
      endTimeInputRef.current.showPicker();
      endTimeInputRef.current.focus();
    }
  };

  const onSave = async () => {
    let from;
    if (startDate) {
      const [startHour, startMinute] = startTime
        ? startTime.split(":")
        : ["07", "00"];
      from = new Date(startDate);
      from.setHours(Number(startHour), Number(startMinute), 0, 0);
    }

    let to;
    if (endDate) {
      const [endHour, endMinute] = endTime ? endTime.split(":") : ["19", "00"];
      to = new Date(endDate);
      to.setHours(Number(endHour), Number(endMinute), 0, 0);
    }

    try {
      setLoading(true);

    // Fallback to existing lat and lng if not provided
    const lat = address.lat || location?.lat || classroomData?.location?.lat || "";
    const lng = address.lng || location?.lng || classroomData?.location?.lng || "";

      const updatedData: Classroom = {
        id: id!,
        name,
        description,
        size: parseInt(numberOfPlaces, 10),
        pricePerHour: pricePerHour,
        equipments,
        address: {
          name: address.label,
          area: address.label,
          city: address.label,
          state: address.label,
        },
        from: from || new Date(),
        to: to || new Date(),
        location: { lat, lng },
        logo:
          logo instanceof File
            ? await userStore.uploadPicture(logo)
            : classroomData.logo,
        album: await Promise.all(
          files.map(async (file) => {
            if (file instanceof File) {
              // Upload new images
              return await userStore.uploadPicture(file);
            }
            return file; // Keep existing images
          })
        ),
        available: true,
        typeEquipment: {},
        deleted: false,
        status: "Published",
        rating: classroomData?.rating || 0,
        ratingsCount: classroomData?.ratingsCount || 0,
        isOnline: classroomData?.isOnline || false,
        ownerName: classroomData?.ownerName || "Unknown Owner",
      };

      // Call the API to update the classroom
      await editClassroom(updatedData);

      toast.success("Classroom updated successfully!");
      navigate(-1); // Navigate back
    } catch (error) {
      console.error("Error saving classroom:", error);
      toast.error("Failed to save classroom.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div>
      <ToastContainer theme="dark" />
      <Navbar />
      <div className="container">
        <h1 className="title">Edit classroom</h1>
        <form>
          <section>
            <h2>Classroom Information</h2>
            <div className="inputs">
              <div className="left-column">
                <div className="field">
                  <label htmlFor="logo">Logo (optional)</label>
                  <div className="input-field">
                    <UploadImage image={logo} setImage={setLogo} />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="loaction">Location</label>
                  <div className="input-field">
                    <div className="input">
                      <LocationInput
                        location={address}
                        setLocation={setAddress}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="places">Number of places (Maximum)</label>
                  <div className="input-field">
                    <div className="input">
                      <Input
                        type="number"
                        label=""
                        fullWidth
                        value={numberOfPlaces}
                        onChange={(e) => setNumberOfPlaces(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="date">Available date</label>
                  <div className="input-field">
                    <div className="input">
                      <DateRange
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="field">
                      <label htmlFor="date">Available time</label>
                      <div className="input-field">
                        <div className="input">
                          <TimeRange
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endTime={endTime}
                            setEndTime={setEndTime}
                          />
                        </div>
                      </div>
                    </div> */}
                <div
                  className="field-group"
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <div className="field" style={{ flex: 1 }}>
                    <label htmlFor="startTime">Start Hour</label>
                    <div className="input-field">
                      <div
                        className="input"
                        onClick={handleStartTimeFocus}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="time"
                          value={startTime}
                          ref={startTimeInputRef}
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{
                            padding: "0.8rem",
                            fontSize: "0.8rem",
                            color: "var(--black)",
                            border: "1px solid #cad4e0",
                            borderRadius: "8px",
                            height: "3rem",
                            width: "100%",
                            cursor: "inherit",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field" style={{ flex: 1 }}>
                    <label htmlFor="endTime">Finish Hour</label>
                    <div className="input-field">
                      <div
                        className="input"
                        onClick={handleEndTimeFocus}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <input
                          type="time"
                          value={endTime}
                          ref={endTimeInputRef}
                          onChange={(e) => setEndTime(e.target.value)}
                          style={{
                            padding: "0.8rem",
                            fontSize: "0.8rem",
                            color: "var(--black)",
                            border: "1px solid #cad4e0",
                            borderRadius: "8px",
                            height: "3rem",
                            width: "100%",
                            cursor: "inherit",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div className="field">
                  <label htmlFor="name">Name</label>
                  <div className="input-field">
                    <div className="input">
                      <Input
                        type="text"
                        label=""
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="description">Description</label>
                  <div className="input-field">
                    <textarea
                      className="bio"
                      id="description"
                      placeholder="Tell us about this classroom"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="name">Price per hour</label>
                  <div className="input-field">
                    <div className="input">
                      <input
                        type="text"
                        id="pricePerHour"
                        name="pricePerHour"
                        value={pricePerHour}
                        onChange={handlePriceChange}
                        className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-lg sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <MultiSelectInput
                    text="Available Equipments"
                    name="equipments"
                    onValueChange={setEquipments}
                    oldValue={equipments}
                  />
                </div>

                <div className="field">
                  <label>Classroom Pictures</label>
                  <div className="input-field" id="files">
                    <div className="files">
                      {files.map((file, index) => (
                        <div className="file" key={index}>
                          <img
                            className="document"
                            src="/images/document.png"
                            alt="doc"
                          />
                          <p>{file.name}</p>
                          <img
                            className="trash"
                            src="/images/trash.png"
                            alt="trash"
                            onClick={() =>
                              setFiles(files.filter((f, i) => i !== index))
                            }
                          />
                        </div>
                      ))}
                    </div>

                    <input
                      className="fileInput"
                      type="file"
                      ref={fileUploadRef}
                      multiple
                      onChange={(e) => handleAttachFile(e)}
                    />
                    <Button
                      text="+ Attach file"
                      type="secondary"
                      width="200px"
                      customColor="rgb(117, 116, 116)"
                      onClick={triggerAttachFile}
                      preventDefault
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="buttons">
            <Button
              text="Cancel"
              type="primary"
              customColor="#373737"
              width="400px"
              preventDefault
              onClick={() => navigate(-1)}
            />
            <Button
              text="Save"
              type="primary"
              width="400px"
              preventDefault
              onClick={onSave}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default observer(EditClassroom);
