import { useTranslation } from "react-i18next";
import Slider from "react-slider";

interface IProps {
  title?: string;
  max: number;
  min: number;
  currency?: string;
  disabled?: boolean;
  range: number[];
  setRange: (range: number[]) => void;
}

const RangeSlider = ({
  title,
  max,
  min,
  currency,
  disabled,
  range,
  setRange,
}: IProps) => {
  const { t } = useTranslation();

  const handlePriceInputChange = (index: number, value: number) => {
    const newRange = [...range];
    newRange[index] = value;

    setRange(newRange);
  };

  return (
    <div
      className={`body-small transition-opacity duration-200 ${
        disabled ? "opacity-50" : "opacity-100"
      }`}
    >
      {title && (
        <div className="text-primary-paradise-pink-800 mb-1">{title}</div>
      )}
      <div className="flex flex-col gap-1.5">
        <div className="caption-text flex justify-between text-primary-gray-500">
          <span>{t("searchBar.minimum")}</span>
          <span>{t("searchBar.maximum")}</span>
        </div>
        <div className="caption-text-bold flex justify-between text-primary-paradise-pink-850">
          <div className="flex items-center gap-4 px-4 py-2 border border-primary-gray-200 rounded-xl">
            {currency && <span>{currency}</span>}
            <input
              className="body-small text-primary-gray-800 w-[40px] text-center outline-none disabled:bg-transparent"
              type="number"
              min={min}
              max={range[1]}
              value={range[0]}
              onChange={(e) =>
                handlePriceInputChange(0, parseInt(e.target.value) || min)
              }
              disabled={disabled}
            />
          </div>
          <div className="flex items-center gap-4 px-4 py-2 border border-primary-gray-200 rounded-xl">
            {currency && <span>{currency}</span>}
            <input
              className="body-small text-primary-gray-800 w-[40px] text-center outline-none disabled:bg-transparent"
              type="number"
              min={range[0]}
              max={max}
              value={range[1]}
              onChange={(e) =>
                handlePriceInputChange(1, parseInt(e.target.value) || max)
              }
              disabled={disabled}
            />
          </div>
        </div>
        <div className="py-[15px]">
          <Slider
            className="slider"
            min={min}
            max={max}
            value={range}
            onChange={(newRange) => {
              setRange(newRange);
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
