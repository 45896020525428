import React, { lazy, Suspense } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import "./header.css";

const Navbar = lazy(() => import("../../../shared/Navbar"));
const SearchBar = lazy(() =>
  import("./searchBar").then((module) => ({
    default: module.SearchBar,
  }))
);

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="home_page_header">
      <Suspense fallback={<nav className="h-12 bg-gray-200 animate-pulse"></nav>}>
        <Navbar defaultTransparent />
      </Suspense>

      <div className="home_page_header_gradient"></div>

      <div className="flex flex-col items-center gap-10 my-10">
        <div className="flex flex-col justify-center">
          <h1 className="text-body-large font-body-large-bold sm:text-heading-medium sm:font-heading-medium lg:text-heading-large lg:font-body-large-bold text-center text-primary-paradise-pink-800 px-3.5">
            {t("header.title1")}{" "}
            <span className="colored_span">{t("header.title2")}</span>
            <br />
            {t("header.title3")}
          </h1>
          <p className="px-3.5 sm:px-0 text-sm font-normal text-primary-gray-400 mt-3.5 text-center">
            {t("header.subtitle1")}{" "}
            <span className="font-bold">{t("header.subtitle2")}</span>{" "}
            <br className="block sm:hidden" />
            {t("header.subtitle3")}
          </p>
        </div>

        <Suspense fallback={<div className="h-10 w-full bg-gray-200 animate-pulse"></div>}>
          <SearchBar />
        </Suspense>
      </div>
    </div>
  );
};

export default observer(Header);
