import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ToastContainer } from "react-toastify";
import styles from "../auth.module.css";
import { Navbar } from "../../../components";
import EmailInput from "./Steps/EmailInput";
import EmailVerification from "./Steps/EmailVerification";
import PasswordInput from "./Steps/PasswordInput";
import NameAndLocation from "./Steps/NameAndLocation";
import ChoosePlan from "./Steps/ChoosePlan";
import ChoosePassions from "./Steps/ChoosePassions";
import { useBetaNotification } from "src/store/useBetaNotification";
import { useLocation, useNavigate } from "react-router-dom";

function Register() {
  const { isBetaVisible } = useBetaNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<any>(
    JSON.parse(localStorage.getItem("registrationUser") || "{}")
  );
  const [step, setStep] = useState<number>(
    parseInt(localStorage.getItem("registrationStep") || "1")
  );

  const [isNavigating, setIsNavigating] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem("registrationStep", step.toString());
    localStorage.setItem("registrationUser", JSON.stringify(user));
  }, [step]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleNavigation = (event: PopStateEvent) => {
      if (
        !isNavigating &&
        !window.confirm(
          "Are you sure you want to leave? Your progress may be lost."
        )
      ) {
        event.preventDefault();
        navigate(location.pathname, { replace: true });
      }
    };

    window.addEventListener("popstate", handleNavigation);
    return () => {
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [navigate, location.pathname, isNavigating]);

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />

      <Navbar />

      <div
        className={`flex flex-col items-center gap-6 sm:gap-8 mx-5 w-[350px] h-fit mb-14 ${
          step === 1 ? "sm:min-w-[530px]" : "sm:min-w-[482px]"
        } ${isBetaVisible ? " mt-[168px]" : " mt-[120px]"}`}
      >
        {step >= 3 && (
          <div className="flex flex-col gap-2 w-full">
            <h3 className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large">
              Account information
            </h3>

            <div className="relative w-full h-[5px] bg-primary-paradise-pink-100 rounded-full">
              <div
                className="absolute left-0 h-full bg-primary-paradise-pink-500 rounded-full"
                style={{ width: `${(step - 2) * 20}%`, maxWidth: "100%" }}
              ></div>
            </div>
          </div>
        )}

        <div
          className={`flex flex-col items-center gap-6 w-full sm:gap-[30px] rounded-xl border border-black border-opacity-5 p-6 sm:py-10 ${
            step === 6 ? "sm:px-[50px]" : "sm:px-20"
          }`}
        >
          {step === 1 && <EmailInput setUser={setUser} setStep={setStep} />}
          {step === 2 && (
            <EmailVerification email={user?.email} setStep={setStep} />
          )}
          {step === 3 && <PasswordInput setUser={setUser} setStep={setStep} />}
          {step === 4 && (
            <NameAndLocation user={user} setUser={setUser} setStep={setStep} />
          )}
          {step === 5 && (
            <ChoosePlan user={user} setUser={setUser} setStep={setStep} />
          )}
          {step === 6 && <ChoosePassions user={user} setUser={setUser} />}
        </div>
      </div>
    </main>
  );
}

export default observer(Register);
