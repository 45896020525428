import { observer } from "mobx-react";
import ScheduleSelector from "react-schedule-selector";
import { useStore } from "../../../../store";
import styles from "./schedule.module.css";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import SchedulePlanner from "src/components/shared/scheduler";

const MS_PER_DAY = 24 * 60 * 60 * 1000;

function CourseSchedule() {
  const { courseStore } = useStore();
  const { t } = useTranslation();

  const course: any = courseStore.courseDetails;
  const scheduleData = course?.planning
    ? course?.planning
    : course?.scheduleData;
  const isOldSchedule = course?.planning ? false : true;

  const getLocalSlots = () => {
    let localSlots: any[] = [];
    if (isOldSchedule) {
      localSlots =
        scheduleData.schedule && scheduleData.schedule.length > 0
          ? scheduleData.schedule.map(
              (utcString: string) => new Date(utcString)
            )
          : [];
    } else {
      localSlots =
        scheduleData.blocks && scheduleData.blocks.length > 0
          ? scheduleData.blocks
          : [];
    }
    return localSlots;
  };

  const localSlots: any[] = getLocalSlots();

  if (!localSlots.length) {
    return (
      <div className={styles.course_schedule} id="schedule">
        <div className={styles.course_schedule_content}>
          <h3 className={styles.course_schedule_title}>
            {t("buttons.timetable")}
          </h3>
          <p>No schedule found</p>
        </div>
      </div>
    );
  }

  // const earliestMs = Math.min(...localSlots.map((d) => d.getTime()));
  // const latestMs = Math.max(...localSlots.map((d) => d.getTime()));

  // const earliestDate = new Date(earliestMs);
  // earliestDate.setHours(0, 0, 0, 0);
  // const latestDate = new Date(latestMs);
  // latestDate.setHours(0, 0, 0, 0);

  // const diffDays =
  //   Math.round((latestDate.getTime() - earliestDate.getTime()) / MS_PER_DAY) +
  //   1;
  // const dynamicStartDate = earliestDate;
  // const dynamicNumDays = diffDays;

  // const localHours = localSlots.map((d) => d.getHours());
  // const earliestHour = Math.min(...localHours);
  // const latestHour = Math.max(...localHours) + 1; // +1 to fully include that hour

  // let dynamicMinTime = Math.max(0, earliestHour);
  // let dynamicMaxTime = Math.min(24, latestHour);

  return (
    <div className={styles.course_schedule} id="schedule">
      {/* <div className={styles.course_schedule_content}>
        <h3 className={styles.course_schedule_title}>
          {t("buttons.timetable")}
        </h3>

        <ScheduleSelector
          selection={localSlots}
          startDate={dynamicStartDate}
          numDays={dynamicNumDays}
          minTime={dynamicMinTime}
          maxTime={dynamicMaxTime}
          hourlyChunks={scheduleData.hourlyChunks || 1}
          dateFormat="ddd M/D"
          timeFormat="h:mma"
          selectedColor="#CA2D5D"
        />
      </div> */}
      <SchedulePlanner
        readOnly={true}
        isOldSchedule={isOldSchedule}
        schedule={scheduleData}
      ></SchedulePlanner>
    </div>
  );
}

export default observer(CourseSchedule);
