export function formatDateTimeRange(start: string, end: string): string {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const optionsDate: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };

    const formattedStartDate: string = startDate.toLocaleDateString(undefined, optionsDate);
    const formattedEndDate: string = endDate.toLocaleDateString(undefined, optionsDate);

    return `${formattedStartDate} to ${formattedEndDate}`;
}