import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./paymentFailed.module.css";
import { Button, Footer, Navbar } from "src/components/shared";

function PaymentFailed() {
  const navigate = useNavigate();
  const { sessionId } = useParams();

  return (
    <div>
      <div className={styles.failed_container}>
        <Navbar />

        <main>
          <div className={styles.text_container}>
            <div className={styles.titles_container}>
              <div className={styles.sub_title}>
                <img
                  src="/images/icons/fail.svg"
                  alt="failure icon"
                  width={38}
                  height={38}
                />
                <h2>Payment Failed</h2>
              </div>
              <div>
                <h1 className={styles.title_1}>We're sorry!</h1>
                <h2 className={styles.title_2}>Please try again</h2>
              </div>
            </div>
          </div>

          <div className={styles.buttons_container}>
            <Button
              type="secondary"
              text="Home Page"
              onClick={() => navigate("/")}
            />
            <Button
              type="primary"
              text="Retry Payment"
              onClick={() => navigate(`/payment/${sessionId}`)}
            />
          </div>
        </main>

        <Footer />
      </div>
    </div>
  );
}

export default observer(PaymentFailed);
