import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Input, Select } from "../../../../components";
import { validateEmail, validateName } from "../../../../utils/validation";
import { useStore } from "../../../../store";
import PaymentMethods from "./PaymentMethods";
import styles from "./form.module.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function ProgressItem({
  count,
  text,
  active,
}: {
  count: number;
  text: string;
  active?: boolean;
}) {
  return (
    <div className={styles.progress_item}>
      <div
        className={
          active
            ? styles.progress_item_count_active
            : styles.progress_item_count
        }
      >
        {active && <span>{count}</span>}
      </div>
      <span className={styles.progress_item_text}>{text}</span>
    </div>
  );
}

export default function PaymentForm({
  status,
  paymentMethod,
  submitRef,
  agree,
  setPaymentMethod,
  setStatus,
  courseTitle,
}: {
  courseTitle: string;
  status: 1 | 2 | 3;
  paymentMethod: "PostWire" | "Onsite" | "KonnectPayment" | "StripePayment";
  submitRef: React.RefObject<HTMLButtonElement>;
  agree: boolean;
  setPaymentMethod: React.Dispatch<
    React.SetStateAction<
      "PostWire" | "Onsite" | "KonnectPayment" | "StripePayment"
    >
  >;
  setStatus: React.Dispatch<React.SetStateAction<1 | 2>>;
}) {
  const { userStore } = useStore();
  const { t } = useTranslation();

  const [save, setSave] = React.useState<boolean>(false);
  const [fullName, setFullName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [streetAddress, setStreetAddress] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [zipCode, setZipCode] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");

  const [countryList, setCountryList] = React.useState([]);

  const [country, setCountry] = React.useState<string>("");

  const [countryState, setCountryState] = React.useState<string>("");

  const [stateList, setStateList] = React.useState([]);

  const user = userStore?.user;

  useEffect(() => {
    const countriesFromLocalStorage = localStorage.getItem("countries");
    if (countriesFromLocalStorage) {
      const countries = JSON.parse(countriesFromLocalStorage).map(
        (country: any) => {
          return { value: country.c_id, label: country.name };
        }
      );

      setCountryList(countries);
      if (user) {
        if (user?.billingInfo) {
          setFullName(user?.billingInfo?.fullName);
          setEmail(user?.billingInfo?.email);
          setPhone(user?.billingInfo?.phone);
          setCountry(user?.billingInfo?.country);
          onCountryChange(user?.billingInfo?.country, countries);
          setCountryState(user?.billingInfo?.state);
          setStreetAddress(user?.billingInfo?.streetAddress);
          setCity(user?.billingInfo?.city);
          setZipCode(user?.billingInfo?.zipCode);
        } else {
          setCountry(user?.country || "");
          onCountryChange(user?.country || "", countries);
          setCountryState(user?.state || "");
          const name = user?.fullName.split(" ");
          setFullName(user?.fullName || "");
          setEmail(user?.email || "");
          setPhone(user?.phone || "");
        }
      }
    }
  }, [user]);

  const onCountryChange = (selectedCountryId: string, countryList: any[]) => {
    setCountryState("");
    setStateList([]);

    const selectedCountry: any = countryList.find(
      (country: any) =>
        country.label.toString() === selectedCountryId.toString()
    );

    if (selectedCountry) {
      setCountry(selectedCountryId);

      const statesListFromLocalStorage = localStorage.getItem("states");
      if (statesListFromLocalStorage) {
        const statesList = JSON.parse(statesListFromLocalStorage);

        const countryStatesList = statesList.filter((state: any) => {
          return state.country_id === +selectedCountry.value;
        });

        const mappedCountryStatesList = countryStatesList.map((state: any) => {
          return { value: state.id, label: state.name };
        });

        setStateList(mappedCountryStatesList);
      }
    }
  };

  const onStateChange = (stateId: string) => {
    const statesListFromLocalStorage = localStorage.getItem("states");
    if (statesListFromLocalStorage) {
      const statesList = JSON.parse(statesListFromLocalStorage);
      const selectedState = statesList.find((state: any) => {
        return state.name === stateId;
      });

      setCountryState(selectedState.name);
    }
  };

  const handleFirstSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const billingInfo = {
      fullName,
      email,
      phone,
      country,
      state: countryState,
      streetAddress,
      city,
      zipCode,
    };

    if (agree && status === 1) {
      try {
        if (user) {
          if (save) {
            await userStore.updateProfile(user?.id, { billingInfo });
          }
          setStatus(2);
        } else {
          const newUser: any = { email, phone, fullName, billingInfo };
          const tempUser = await userStore.createTempUser(newUser);
          if (tempUser) {
            setStatus(2);
          } else {
            toast.error("Something went wrong !");
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong !");
      }
    }
  };

  return (
    <form className={styles.payment_form} onSubmit={handleFirstSumbit}>
      <div className={styles.payment_form_container}>
        <div className={styles.payment_progress}>
          <ProgressItem
            active={status === 1 || status === 2}
            count={1}
            text={t("paymentForm.progress.billingInfo")}
          />
          <hr />
          <ProgressItem
            active={status === 2}
            count={2}
            text={t("paymentForm.progress.payment")}
          />
          <hr />
          <ProgressItem count={3} text={t("paymentForm.progress.review")} />
        </div>

        {status === 1 ? (
          <>
            <Input
              fullWidth
              required
              label={t("paymentForm.fullName")}
              value={fullName}
              validation={validateName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              required
              label={t("paymentForm.email")}
              value={email}
              type="email"
              validation={validateEmail}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              label={t("paymentForm.streetAddress")}
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
            />

            <div className={styles.payment_form_row}>
              <div className="w-full flex justify-start items-center space-x-2">
                <Select
                  fullWidth
                  label={t("paymentForm.country")}
                  value={country}
                  options={countryList}
                  onChange={(e) => onCountryChange(e.target.value, countryList)}
                  required={user ? false : true}
                />
                <Select
                  fullWidth
                  label={t("paymentForm.stateProvince")}
                  value={countryState}
                  options={stateList}
                  onChange={(e) => onStateChange(e.target.value)}
                />
              </div>
              <Input
                fullWidth
                label={t("paymentForm.city")}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className={styles.payment_form_row}>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label={t("paymentForm.zipCode")}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Input
                  fullWidth
                  label={t("paymentForm.phone")}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div hidden>
                <input
                  id="ClassName"
                  defaultValue={courseTitle}
                  placeholder="Class Name"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.checkbox_container}>
              <input
                type="checkbox"
                id="save"
                name="save"
                value="Bike"
                checked={save}
                onChange={() => setSave(!save)}
              />
              <label htmlFor="save">{t("paymentForm.saveMyInfo")}</label>
            </div>
          </>
        ) : (
          <PaymentMethods
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        )}
      </div>
      <button type="submit" ref={submitRef} style={{ display: "none" }}>
        sub
      </button>
    </form>
  );
}
