import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import Carousel from "react-multi-carousel";
import "./index.css";
import env from "../../../../config/env";
import moment from "moment";
import SchedulePlanner from "src/components/shared/scheduler";
import { Button } from "src/components/shared";
import { Popup } from "src/components/others";

// Zoom patterns
const zoomPatterns = [
  /^https?:\/\/on\.zoom\.us/,
  /^on\.zoom\.us/,
  /^https?:\/\/us04web\.zoom\.us/,
  /^us04web\.zoom\.us/,
  /^https?:\/\/app\.zoom\.us/,
  /^app\.zoom\.us/,
];

// Google Meet patterns
const googleMeetPatterns = [
  /^https?:\/\/meet\.google\.com/,
  /^meet\.google\.com/,
];

// Teams patterns
const teamsPatterns = [
  /^https?:\/\/teams\.microsoft\.com/,
  /^teams\.microsoft\.com/,
];

const isValidOnlineLink = (link: any) => {
  if (!link) return false;

  return (
    zoomPatterns.some((pattern) => pattern.test(link)) ||
    googleMeetPatterns.some((pattern) => pattern.test(link)) ||
    teamsPatterns.some((pattern) => pattern.test(link))
  );
};

const isGoogleMeetLink = (link: string) => {
  return googleMeetPatterns.some((pattern) => pattern.test(link));
};

const isTeamsLink = (link: string) => {
  return teamsPatterns.some((pattern) => pattern.test(link));
};

const isZoomLink = (link: string) => {
  return zoomPatterns.some((pattern) => pattern.test(link));
};

function parseLocation(address: string): string {
  if (!address) return "";
  const isTunisianAddress = address.includes("Tunisia");
  const parts = address
    .split(",")
    .map((part) => part.trim())
    .filter(Boolean);

  return isTunisianAddress
    ? parts.slice(-2).join(", ")
    : parts.slice(-3).join(", ");
}

function ClassUpcomingCohorts() {
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();
  const params = useParams();
  const { userStore, courseStore, paymentStore } = useStore();

  const [selectedSession, setSelectedSession] = useState<any>(null);

  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState<any[]>([]);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [isStripeProcessing, setIsStripeProcessing] = useState<boolean>(false);

  const isTunisian: boolean = localStorage.getItem("country") === "Tunisia";

  const carouselRef = useRef<any>(null);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 800 },
      items: 3, // Show 3 sessions on desktop
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2, // Show 2 sessions on tablet
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1, // Show 1 session on mobile
      partialVisibilityGutter: 20,
    },
  };

  const course: any = courseStore.classDetails;
  const courseId = course?.courseId;

  useEffect(() => {
    if (courseId) {
      const fetchCourseSessions = () => {
        try {
          const fetchedSessions: any = courseStore.searchedCourses;

          if (fetchedSessions.length > 0) {
            const sessionPageIndex = fetchedSessions.findIndex(
              (session: any) => session.id.toString() === params.id
            );

            if (sessionPageIndex !== -1) {
              setActiveIndex(sessionPageIndex);
              setSelectedSession(fetchedSessions[sessionPageIndex]);
              courseStore.setClassDetails(fetchedSessions[sessionPageIndex]);
            } else {
              setSelectedSession(courseStore.classDetails);
              courseStore.setClassDetails(courseStore.classDetails);
            }

            setSessions([...fetchedSessions]);
          } else {
            setSessions([courseStore.classDetails]);
            setSelectedSession(courseStore.classDetails);
          }

          setLoading(false);
        } catch (error) {
          console.log("errorr", error);
          setLoading(false);
        }
      };
      fetchCourseSessions();
    }
  }, [courseId, courseStore]);

  useEffect(() => {
    if (activeIndex !== null) {
      const timer = setTimeout(() => {
        if (carouselRef.current) {
          carouselRef.current.goToSlide(activeIndex, true);
        }
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [activeIndex]);

  const participate = async () => {
    courseStore.selectCourse(selectedSession?.id);
    if (!isTunisian) {
      await handleStripePayment();
    } else {
      navigate(`/payment/${selectedSession?.id}`);
    }
  };

  const sendInitiateCheckoutEvent = async (paymentMethod: string = "") => {
    if (!params.id) return;

    const payload = {
      event_name: "initiate_checkout",
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      action_source: "website",
      user_data: {
        client_user_agent: navigator.userAgent,
        em: [userStore.user?.email || ""],
        ph: [userStore.user?.phone || ""],
        country: userStore.user?.country || "",
        ge: userStore.user?.gender || "",
        fn: userStore.user?.fullName || "",
        ln: userStore.user?.fullName || "",
      },
      custom_data: {
        class_id: params.id,
        class_name: course?.title || "Unknown Class",
        payment_method: paymentMethod,
      },
    };

    try {
      console.log("payload", payload);

      //await userStore.sendMetaEvent("course_view", payload);
    } catch (error) {
      console.error("Error sending event:", error);
    }
  };

  const handleStripePayment = async () => {
    setIsStripeProcessing(true);
    try {
      const hasDiscount = selectedSession?.newSessionDiscount > 0;
      const discountPrice = hasDiscount
        ? selectedSession?.price *
          (1 - selectedSession.newSessionDiscount / 100)
        : selectedSession?.price;

      if (userStore?.user) {
        sendInitiateCheckoutEvent("stripe");
        await paymentStore.createCheckoutSession(
          discountPrice || 0,
          "usd",
          selectedSession?.id
        );
      } else {
        await paymentStore.createCheckoutSessionForUnregisteredUsers(
          discountPrice || 0,
          "usd",
          selectedSession?.id
        );
      }
    } catch (err) {
      console.error("Error during Stripe Checkout:", err);
      setIsStripeProcessing(false);
    }
  };

  const selectedClassRatingStars = (rating: number) => {
    const totalStars = 5;
    const filledStars = rating !== 0 ? Math.floor(rating) : 5;
    const emptyStars = totalStars - filledStars;

    const stars = [
      ...new Array(filledStars).fill("filled"),
      ...new Array(emptyStars).fill("empty"),
    ];

    return stars;
  };

  const getNumberOfDaysLeft = (startDate: string, startTime: string) => {
    const datePart = moment(startDate).format("YYYY-MM-DD");
    const timePart = moment(startTime).format("HH:mm:ssZ");

    const startDateTime = moment(`${datePart}T${timePart}`);

    const now = moment();

    const daysLeft = startDateTime.diff(now, "days");
    const hoursLeft = startDateTime.diff(now, "hours");

    if (daysLeft > 0) {
      return `${daysLeft} ${daysLeft !== 1 ? "Days" : "Day"} Left to Enroll`;
    } else if (hoursLeft > 1) {
      return `${hoursLeft} Hours Left to Enroll`;
    } else if (hoursLeft === 1) {
      return "1 Hour Left to Enroll";
    } else {
      return "Enrollment Closed";
    }
  };

  const itemsOnDesktop = responsive.desktop.items;
  const placeholdersCount = Math.max(0, itemsOnDesktop - sessions.length);
  const placeholders = new Array(placeholdersCount).fill(null).map((_, i) => ({
    id: `placeholder-${i}`,
    isPlaceholder: true,
  }));

  const handleNewSessionSelection = (newSessionIndex: number) => {
    const newSelectedSession = finalCarouselItems[newSessionIndex];

    if (newSelectedSession && newSelectedSession.id) {
      setActiveIndex(newSessionIndex);
      setSelectedSession(newSelectedSession);
      courseStore.setClassDetails(newSelectedSession);

      navigate(`/class/${newSelectedSession.id}`, { replace: true });
    }
  };

  const finalCarouselItems = [...sessions, ...placeholders];

  const ButtonGroup = ({ next, previous }: any) => {
    return (
      <div className="w-full h-full absolute top-1/2 -translate-y-1/2 z-20">
        {/* Previous Button */}
        <button
          className="group transition-all duration-100  absolute left-3 top-1/2 -translate-y-1/2 h-[36px] w-[36px] rounded-full flex justify-center items-center bg-white bg-opacity-70 shadow-md hover:bg-opacity-100"
          onClick={previous}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-all duration-100  stroke-[#1B0209] group-hover:stroke-[#E83C70]"
          >
            <path
              d="M10 4L6 8L10 12"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        {/* Next Button */}
        <button
          className="group transition-all duration-200 absolute right-4 top-1/2 -translate-y-1/2 h-[36px] w-[36px] rounded-full flex justify-center items-center bg-white bg-opacity-70 shadow-md hover:bg-opacity-100"
          onClick={next}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-all duration-200 stroke-[#1B0209] group-hover:stroke-[#E83C70]"
          >
            <path
              d="M6 12L10 8L6 4"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  const CustomDot = ({ onClick, active }: any) => {
    return (
      <button
        className={`w-[6px] h-[6px] mx-[4px] -mt-4 rounded-full transition-all duration-300 ${
          active ? "bg-white" : "bg-white opacity-35 "
        }`}
        onClick={onClick}
      />
    );
  };

  const CustomButtonGroup: React.FC<any> = ({ next, previous }) => {
    return (
      <div className="absolute top-1/2 -translate-y-1/2 flex items-center justify-between w-full">
        <div
          className={`z-[3] flex items-center justify-center w-[54px] h-[54px] p-[15px] border bg-primary-purple-50 border-primary-purple-100 rounded-full  ${
            finalCarouselItems.length > 3
              ? "cursor-pointer hover:bg-[#FAFAFA] hover:border-primary-gray-200"
              : "cursor-not-allowed "
          } `}
          onClick={previous}
        >
          <img
            src={`/images/icons/${
              finalCarouselItems.length > 3
                ? "arrow-left-purple1"
                : "arrow-left"
            }.svg`}
          />
        </div>
        <div className="w-full z-[1]"></div>
        <div
          className={`z-[3] flex items-center justify-center w-[54px] h-[54px] p-[15px] border bg-primary-purple-50 border-primary-purple-100 rounded-full  ${
            finalCarouselItems.length > 3
              ? "cursor-pointer hover:bg-[#FAFAFA] hover:border-primary-gray-200"
              : "cursor-not-allowed "
          } `}
          onClick={next}
        >
          <img
            src={`/images/icons/${
              finalCarouselItems.length > 3
                ? "arrow-right-purple1"
                : "arrow-right"
            }.svg`}
            className="size-6"
          />
        </div>
      </div>
    );
  };

  if (loading)
    return (
      <>
        {" "}
        <div></div>{" "}
      </>
    );

  const getSelectedClassStartDateEndDate = (session: any) => {
    const isOldSchedule = session.planning ? false : true;
    if (isOldSchedule) {
      const userTimeZone = moment.tz.guess();

      const scheduleDates = session.scheduleData.schedule.map((date: string) =>
        moment.utc(date).tz(userTimeZone)
      );

      const selectedClassStartDate = moment.min(scheduleDates).format("DD MMM");
      const selectedClassEndDate = moment.max(scheduleDates).format("DD MMM");

      if (selectedClassStartDate === selectedClassEndDate) {
        return <span>{selectedClassEndDate}</span>;
      }
      return (
        <>
          <span>{selectedClassStartDate}</span>
          <img src="/images/icons/arrow-right-black.svg" />
          <span>{selectedClassEndDate}</span>
        </>
      );
    }
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center space-y-[26px] ">
        {/* upcoming classes section */}
        <div className="w-full flex flex-col justify-center items-center space-y-[26px]">
          {/* upcoming classes header */}
          <div className="w-full flex justify-center items-center space-x-[20px]">
            <span className=" w-[529px] h-[1.4px] bg-[#DFDFDF] "></span>
            <span className="text-[#1E1E1E] font-inter font-semibold text-[12px] leading-[24px] min-w-[102px] ">
              Available Classes
            </span>
            <span className=" w-[529px] h-[1.4px] bg-[#DFDFDF] "></span>
          </div>
          {/* upcoming classes carousel*/}
          <div className="relative w-full">
            <Carousel
              ref={carouselRef}
              responsive={responsive}
              arrows={false}
              containerClass="z-[2]"
              className="mx-14"
              customButtonGroup={<CustomButtonGroup />}
              renderButtonGroupOutside
            >
              {finalCarouselItems.map((session, index) => {
                if (session.isPlaceholder) {
                  return (
                    <div
                      key={session.id || index}
                      className={`flex flex-col items-center justify-center space-y-[2px] p-5 mx-0 sm:mx-3 lg:mx-5 h-full bg-primary-gray-50 border border-primary-gray-200 rounded-2xl cursor-pointer group transition-all duration-200`}
                    ></div>
                  );
                } else {
                  return (
                    <div
                      key={session?.id}
                      className={`flex flex-col items-center justify-center space-y-[2px] p-5 mx-0 sm:mx-3 lg:mx-5 h-full bg-primary-gray-50 border border-primary-gray-200 rounded-2xl cursor-pointer group transition-all duration-200 ${
                        index === activeIndex
                          ? "border-primary-paradise-pink-500 bg-primary-paradise-pink-50"
                          : "hover:bg-[#FAFAFA]"
                      }`}
                      onClick={() => {
                        handleNewSessionSelection(index);
                      }}
                    >
                      <div className="flex items-center gap-2.5">
                        <div className="flex items-center gap-1.5">
                          <img src="/images/icons/location-purple.svg" />
                          <span className="text-[#370513] font-bold font-inter text-[14px] leading-[24px] ">
                            {session?.isOnline
                              ? "Online"
                              : parseLocation(
                                  session?.sessionBooking?.[0]?.bookingRoom
                                    ?.address?.name || ""
                                )}
                          </span>
                        </div>
                        {session?.newSessionDiscount !== undefined &&
                          session?.newSessionDiscount > 0 && (
                            <span className="px-[10px] min-w-[73px] h-[24px] rounded-full bg-[#E83C70] text-[#FFFAFB] font-inter font-semibold text-[12px] leading-[24px] ">
                              {session?.newSessionDiscount}% OFF
                            </span>
                          )}
                      </div>
                      <div
                        className={`flex items-center gap-2 sm:text-body-medium sm:font-body-medium-bold lg:text-body-large lg:font-body-large ${
                          index === activeIndex
                            ? "text-primary-paradise-pink-500"
                            : ""
                        }`}
                      >
                        {getSelectedClassStartDateEndDate(session)}
                      </div>
                    </div>
                  );
                }
              })}
            </Carousel>
          </div>
          {/* selected upcoming class details */}
          {finalCarouselItems[activeIndex] &&
          finalCarouselItems[activeIndex].isOnline ? (
            isValidOnlineLink(
              finalCarouselItems[activeIndex].onlineSessionLink
            ) ? (
              // online class info
              <div className="w-full max-w-[1200px] flex justify-start items-center space-x-[12px]">
                {/* online class host link */}
                <div className="w-full max-w-[868px] h-[126px] rounded-[24px] border-[1px] px-[26px] py-[20px] border-[#DFDFDF] bg-[#FAFAFA] flex justify-center items-center ">
                  {/* online class meeting icon */}
                  {isGoogleMeetLink(
                    finalCarouselItems[activeIndex].onlineSessionLink
                  ) && (
                    <div className="flex justify-center items-center space-x-[18px]">
                      {/* google meet logo */}
                      <svg
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.2056 40.4553L50.4448 47.6208L58.8353 53.0075L60.2948 40.5005L58.8353 28.2756L50.2841 33.0076L44.2056 40.4553Z"
                          fill="#00832D"
                        />
                        <path
                          d="M8 51.8435V62.5013C8 64.9348 9.966 66.9106 12.3885 66.9106H22.996L25.1925 58.8578L22.996 51.8435L15.7185 49.6366L8 51.8435Z"
                          fill="#0066DA"
                        />
                        <path
                          d="M22.996 14L8 29.0671L15.719 31.2687L22.996 29.0671L25.1525 22.1482L22.996 14Z"
                          fill="#E94235"
                        />
                        <path
                          d="M8.00049 51.8479H22.9962V29.0664H8.00049V51.8479Z"
                          fill="#2684FC"
                        />
                        <path
                          d="M68.4147 20.3787L58.835 28.2749V53.0066L68.4542 60.9335C69.8942 62.0668 72.0007 61.0339 72.0007 59.1948V22.0822C72.0007 20.2227 69.844 19.1948 68.4145 20.3789"
                          fill="#00AC47"
                        />
                        <path
                          d="M44.2056 40.4553V51.8435H22.9961V66.9106H54.4471C56.8696 66.9106 58.8353 64.9348 58.8353 62.5013V53.0075L44.2056 40.4553Z"
                          fill="#00AC47"
                        />
                        <path
                          d="M54.4471 14H22.9961V29.0671H44.2056V40.4553L58.8356 28.2753V18.4095C58.8356 15.9756 56.8696 14.0003 54.4471 14.0003"
                          fill="#FFBA00"
                        />
                      </svg>
                      <div className="flex flex-col justify-start items-start">
                        <span className="text-[#0D0D0D] font-inter font-semibold text-[22px] leading-[32px]">
                          Online Class
                        </span>
                        <span className="text-[#3F3F3E] font-inter font-normal text-[14px] leading-[24px] ">
                          Hosted by Google Meet
                        </span>
                      </div>
                    </div>
                  )}
                  {isTeamsLink(
                    finalCarouselItems[activeIndex].onlineSessionLink
                  ) && (
                    <div className="flex justify-center items-center space-x-[18px]">
                      {/* teams icon */}
                      <svg
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M53.1406 32.6729H69.6717C71.2337 32.6729 72.4997 33.9391 72.4997 35.5008V50.5584C72.4997 56.2984 67.8465 60.9518 62.1065 60.9518H62.0573C56.3173 60.9526 51.6633 56.3001 51.6626 50.5599V34.1511C51.6625 33.957 51.7007 33.7647 51.7749 33.5853C51.8491 33.406 51.958 33.243 52.0953 33.1057C52.2325 32.9684 52.3955 32.8595 52.5748 32.7852C52.7542 32.711 52.9465 32.6728 53.1406 32.6729Z"
                          fill="#5059C9"
                        />
                        <path
                          d="M64.3129 29.6976C68.012 29.6976 71.0106 26.699 71.0106 22.9999C71.0106 19.3009 68.012 16.3022 64.3129 16.3022C60.6139 16.3022 57.6152 19.3009 57.6152 22.9999C57.6152 26.699 60.6139 29.6976 64.3129 29.6976Z"
                          fill="#5059C9"
                        />
                        <path
                          d="M43.4776 29.6975C48.8207 29.6975 53.152 25.3661 53.152 20.023C53.152 14.68 48.8207 10.3486 43.4776 10.3486C38.1346 10.3486 33.8032 14.68 33.8032 20.023C33.8032 25.3661 38.1346 29.6975 43.4776 29.6975Z"
                          fill="#7B83EB"
                        />
                        <path
                          d="M56.3783 32.6729H29.0908C27.5475 32.7111 26.3268 33.9918 26.3625 35.5351V52.7092C26.147 61.9701 33.4737 69.6553 42.7344 69.882C51.9951 69.6553 59.3218 61.9701 59.1065 52.7092V35.5351C59.1423 33.9918 57.9215 32.7111 56.3783 32.6729Z"
                          fill="#7B83EB"
                        />
                        <path
                          opacity="0.1"
                          d="M44.2193 32.6729V56.7396C44.2157 57.2794 44.0527 57.8062 43.7509 58.2537C43.449 58.7012 43.0217 59.0496 42.5225 59.2551C42.1975 59.3926 41.8483 59.4634 41.4955 59.4634H27.6684C27.4749 58.9721 27.2964 58.4809 27.1474 57.9751C26.6264 56.2672 26.3606 54.4918 26.3587 52.7062V35.5306C26.3229 33.9898 27.5417 32.7111 29.0824 32.6731L44.2193 32.6729Z"
                          fill="black"
                        />
                        <path
                          opacity="0.2"
                          d="M42.7317 32.6729V58.2281C42.7317 58.5809 42.661 58.9301 42.5235 59.2551C42.318 59.7543 41.9696 60.1816 41.5221 60.4835C41.0745 60.7853 40.5478 60.9483 40.008 60.9518H28.3694C28.1164 60.4606 27.8781 59.9694 27.6699 59.4634C27.4698 58.9769 27.2959 58.4801 27.1489 57.9751C26.6279 56.2672 26.3621 54.4918 26.3601 52.7062V35.5306C26.3244 33.9898 27.5431 32.7111 29.0839 32.6731L42.7317 32.6729Z"
                          fill="black"
                        />
                        <path
                          opacity="0.2"
                          d="M42.732 32.6729V55.2514C42.7207 56.7509 41.508 57.9639 40.0083 57.9751H27.1489C26.6279 56.2672 26.3621 54.4918 26.3601 52.7062V35.5306C26.3244 33.9898 27.5431 32.7111 29.0839 32.6731L42.732 32.6729Z"
                          fill="black"
                        />
                        <path
                          opacity="0.2"
                          d="M41.2438 32.6729V55.2514C41.2323 56.7509 40.0195 57.9639 38.52 57.9751H27.1489C26.6279 56.2672 26.3621 54.4918 26.3601 52.7062V35.5306C26.3244 33.9898 27.5431 32.7111 29.0839 32.6731L41.2438 32.6729Z"
                          fill="black"
                        />
                        <path
                          opacity="0.1"
                          d="M44.2215 24.9798V29.668C43.9685 29.683 43.7302 29.698 43.4772 29.698C43.2242 29.698 42.986 29.683 42.733 29.668C42.2306 29.6347 41.7324 29.555 41.2447 29.43C39.7589 29.0781 38.3774 28.3795 37.2133 27.3914C36.0493 26.4032 35.1356 25.1536 34.547 23.7446C34.3416 23.2646 34.1821 22.7662 34.0708 22.2561H41.4977C42.9995 22.2619 44.2157 23.4778 44.2215 24.9798Z"
                          fill="black"
                        />
                        <path
                          opacity="0.2"
                          d="M42.7306 26.4681V29.6681C42.2282 29.6347 41.7301 29.5551 41.2424 29.4301C39.7566 29.0781 38.3752 28.3795 37.2111 27.3914C36.0471 26.4032 35.1334 25.1536 34.5449 23.7446H40.0069C41.5089 23.7501 42.7248 24.9664 42.7306 26.4681Z"
                          fill="black"
                        />
                        <path
                          opacity="0.2"
                          d="M42.7306 26.4681V29.6681C42.2282 29.6347 41.7301 29.5551 41.2424 29.4301C39.7566 29.0781 38.3752 28.3795 37.2111 27.3914C36.0471 26.4032 35.1334 25.1536 34.5449 23.7446H40.0069C41.5089 23.7501 42.7248 24.9664 42.7306 26.4681Z"
                          fill="black"
                        />
                        <path
                          opacity="0.2"
                          d="M41.2426 26.4684V29.4303C39.7567 29.0784 38.3753 28.3798 37.2112 27.3916C36.0471 26.4034 35.1334 25.1536 34.5449 23.7446H38.5189C40.0209 23.7504 41.2369 24.9666 41.2426 26.4684Z"
                          fill="black"
                        />
                        <path
                          d="M11.2282 23.7449H38.5155C40.0225 23.7449 41.244 24.9664 41.244 26.4731V53.7606C41.244 55.2673 40.0225 56.4888 38.5157 56.4888H11.2282C9.72149 56.4888 8.5 55.2673 8.5 53.7606V26.4734C8.5 24.9661 9.72149 23.7449 11.2282 23.7449Z"
                          fill="url(#paint0_linear_5546_34054)"
                        />
                        <path
                          d="M32.051 34.1307H26.5961V48.9846H23.1209V34.1307H17.6914V31.2493H32.051V34.1307Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_5546_34054"
                            x1="577.328"
                            y1="-189.42"
                            x2="2714.09"
                            y2="3511.32"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#5A62C3" />
                            <stop offset="0.5" stopColor="#4D55BD" />
                            <stop offset="1" stopColor="#3940AB" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <div className="flex flex-col justify-start items-start">
                        <span className="text-[#0D0D0D] font-inter font-semibold text-[22px] leading-[32px]">
                          Online Class
                        </span>
                        <span className="text-[#3F3F3E] font-inter font-normal text-[14px] leading-[24px] ">
                          Hosted by Microsoft Teams
                        </span>
                      </div>
                    </div>
                  )}
                  {isZoomLink(
                    finalCarouselItems[activeIndex].onlineSessionLink
                  ) && (
                    <div className="flex justify-center items-center space-x-[18px]">
                      {/* zoom link */}
                      <svg
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3538 47.7601H9.60731C8.83799 47.7601 8.08737 47.32 7.73081 46.58C7.31819 45.7201 7.4681 44.7 8.10606 44.04L16.2875 35.32H10.4141C8.80035 35.32 7.50561 33.92 7.50561 32.2198H18.3327C19.102 32.2198 19.8525 32.6599 20.2091 33.3999C20.622 34.2599 20.4718 35.2799 19.8338 35.9398L11.6525 44.66H18.4266C20.0402 44.66 21.3538 46.0601 21.3538 47.7601ZM67.8517 32C66.1631 32 64.6618 32.78 63.611 34.02C62.5603 32.78 61.059 32 59.3703 32C56.2554 32 53.7035 34.84 53.7035 38.16V47.7601C55.3171 47.7601 56.6119 46.36 56.6119 44.66V38.1401C56.6119 36.54 57.7752 35.18 59.2764 35.1201C60.8526 35.0601 62.1474 36.4 62.1474 38.06V44.66C62.1474 46.3801 63.4609 47.7601 65.0559 47.7601V38.12C65.0559 36.52 66.2193 35.16 67.7204 35.1001C69.2966 35.04 70.5914 36.38 70.5914 38.0401V44.64C70.5914 46.3602 71.9049 47.74 73.4999 47.74V38.1402C73.5186 34.84 70.9666 32 67.8517 32ZM36.065 40C36.065 44.42 32.7062 48 28.5593 48C24.4123 48 21.0535 44.42 21.0535 40C21.0535 35.58 24.4123 32 28.5593 32C32.7062 32 36.065 35.58 36.065 40ZM33.1564 40C33.1564 37.3 31.0924 35.1001 28.5593 35.1001C26.0261 35.1001 23.9619 37.3 23.9619 40C23.9619 42.7001 26.0261 44.9001 28.5593 44.9001C31.0924 44.9001 33.1564 42.7001 33.1564 40ZM52.2397 40C52.2397 44.42 48.881 48 44.734 48C40.5871 48 37.2283 44.42 37.2283 40C37.2283 35.58 40.5871 32 44.734 32C48.881 32 52.2399 35.58 52.2399 40M49.3315 40C49.3315 37.3 47.2674 35.1001 44.7342 35.1001C42.2012 35.1001 40.137 37.3 40.137 40C40.137 42.7001 42.201 44.9001 44.7342 44.9001C47.2674 44.9001 49.3315 42.7001 49.3315 40Z"
                          fill="#0B5CFF"
                        />
                      </svg>
                      <div className="flex flex-col justify-start items-start">
                        <span className="text-[#0D0D0D] font-inter font-semibold text-[22px] leading-[32px]">
                          Online Class
                        </span>
                        <span className="text-[#3F3F3E] font-inter font-normal text-[14px] leading-[24px] ">
                          Hosted by Zoom
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* book a seat button & students number */}
                <div className=" w-full max-w-[320px] rounded-[24px] border-[1px] border-[#DFDFDF] p-[20px] bg-[#FAFAFA] flex flex-col justify-center items-center space-y-[12px]">
                  {/* book a seat button */}
                  <Button
                    type="primary_dark"
                    text="Book a Seat"
                    onClick={participate}
                    isLoading={isStripeProcessing}
                  />
                  {/* enrolled students number */}
                  <div className="flex justify-start items-center space-x-[4px]">
                    {/* icon */}
                    {/* <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16663 16.6667V15.8333C4.16663 12.6117 6.7783 10 9.99996 10V10C13.2216 10 15.8333 12.6117 15.8333 15.8333V16.6667"
                      stroke="#E83C70"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99996 9.99998C11.8409 9.99998 13.3333 8.5076 13.3333 6.66665C13.3333 4.8257 11.8409 3.33331 9.99996 3.33331C8.15901 3.33331 6.66663 4.8257 6.66663 6.66665C6.66663 8.5076 8.15901 9.99998 9.99996 9.99998Z"
                      stroke="#E83C70"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg> */}
                    {/* text */}
                    <span className="text-[#E83C70] font-inter font-semibold text-[12px] leading-[24px] ">
                      {getNumberOfDaysLeft(
                        selectedSession?.start,
                        selectedSession?.startTime
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full max-w-[1200px] flex flex-col sm:flex-row justify-start items-center gap-3">
                {/* online class host link */}
                <div className="w-full max-w-[868px] h-[126px] rounded-[24px] border-[1px] px-[26px] py-[20px] border-[#DFDFDF] bg-[#FAFAFA] flex justify-center items-center ">
                  {/* online class meeting icon */}
                  <div className="flex justify-center items-center space-x-[18px]">
                    {/* zoom link */}
                    <svg
                      width="81"
                      height="80"
                      viewBox="0 0 81 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.3538 47.7601H9.60731C8.83799 47.7601 8.08737 47.32 7.73081 46.58C7.31819 45.7201 7.4681 44.7 8.10606 44.04L16.2875 35.32H10.4141C8.80035 35.32 7.50561 33.92 7.50561 32.2198H18.3327C19.102 32.2198 19.8525 32.6599 20.2091 33.3999C20.622 34.2599 20.4718 35.2799 19.8338 35.9398L11.6525 44.66H18.4266C20.0402 44.66 21.3538 46.0601 21.3538 47.7601ZM67.8517 32C66.1631 32 64.6618 32.78 63.611 34.02C62.5603 32.78 61.059 32 59.3703 32C56.2554 32 53.7035 34.84 53.7035 38.16V47.7601C55.3171 47.7601 56.6119 46.36 56.6119 44.66V38.1401C56.6119 36.54 57.7752 35.18 59.2764 35.1201C60.8526 35.0601 62.1474 36.4 62.1474 38.06V44.66C62.1474 46.3801 63.4609 47.7601 65.0559 47.7601V38.12C65.0559 36.52 66.2193 35.16 67.7204 35.1001C69.2966 35.04 70.5914 36.38 70.5914 38.0401V44.64C70.5914 46.3602 71.9049 47.74 73.4999 47.74V38.1402C73.5186 34.84 70.9666 32 67.8517 32ZM36.065 40C36.065 44.42 32.7062 48 28.5593 48C24.4123 48 21.0535 44.42 21.0535 40C21.0535 35.58 24.4123 32 28.5593 32C32.7062 32 36.065 35.58 36.065 40ZM33.1564 40C33.1564 37.3 31.0924 35.1001 28.5593 35.1001C26.0261 35.1001 23.9619 37.3 23.9619 40C23.9619 42.7001 26.0261 44.9001 28.5593 44.9001C31.0924 44.9001 33.1564 42.7001 33.1564 40ZM52.2397 40C52.2397 44.42 48.881 48 44.734 48C40.5871 48 37.2283 44.42 37.2283 40C37.2283 35.58 40.5871 32 44.734 32C48.881 32 52.2399 35.58 52.2399 40M49.3315 40C49.3315 37.3 47.2674 35.1001 44.7342 35.1001C42.2012 35.1001 40.137 37.3 40.137 40C40.137 42.7001 42.201 44.9001 44.7342 44.9001C47.2674 44.9001 49.3315 42.7001 49.3315 40Z"
                        fill="#0B5CFF"
                      />
                    </svg>
                    <div className="flex flex-col justify-start items-start">
                      <span className="text-[#0D0D0D] font-inter font-semibold text-[22px] leading-[32px]">
                        Online Class
                      </span>
                      <span className="text-[#3F3F3E] font-inter font-normal text-[14px] leading-[24px] ">
                        Hosted by Zoom
                      </span>
                    </div>
                  </div>
                </div>
                {/* book a seat button & students number */}
                <div className="w-full max-w-[320px] rounded-[24px] border-[1px] border-[#DFDFDF] p-[20px] bg-[#FAFAFA] flex flex-col justify-center items-center space-y-[12px] ">
                  {/* book a seat button */}
                  <Button
                    type="primary_dark"
                    text="Book a Seat"
                    onClick={participate}
                    isLoading={isStripeProcessing}
                  />
                  {/* enrolled students number */}
                  <div className="flex justify-start items-center space-x-[4px]">
                    {/* icon */}
                    {/* <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16663 16.6667V15.8333C4.16663 12.6117 6.7783 10 9.99996 10V10C13.2216 10 15.8333 12.6117 15.8333 15.8333V16.6667"
                      stroke="#E83C70"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.99996 9.99998C11.8409 9.99998 13.3333 8.5076 13.3333 6.66665C13.3333 4.8257 11.8409 3.33331 9.99996 3.33331C8.15901 3.33331 6.66663 4.8257 6.66663 6.66665C6.66663 8.5076 8.15901 9.99998 9.99996 9.99998Z"
                      stroke="#E83C70"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg> */}
                    {/* text */}
                    <span className="text-[#E83C70] font-inter font-semibold text-[12px] leading-[24px] ">
                      {getNumberOfDaysLeft(
                        selectedSession.start,
                        selectedSession?.startTime
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="w-full max-w-[1200px] h-auto md:h-[290px] md:min-h-[290px] flex flex-col md:flex-row justify-start items-start space-y-[10px] md:space-y-0 md:space-x-[30px] rounded-[24px] border-[1px] p-[8px] md:p-[26px] border-[#DFDFDF] bg-white">
              {/* selected class classrooms pictures */}
              <div className="w-full lg:w-2/4 gap-1/8 flex items-center">
                <div className="relative w-full lg:w-2/3 lg:pr-2 md:max-w-[376px] sm:max-w-[334px]">
                  <Carousel
                    additionalTransfrom={0}
                    autoPlay
                    autoPlaySpeed={3000}
                    infinite
                    draggable
                    keyBoardControl
                    showDots
                    responsive={{
                      desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 1,
                      },
                      tablet: { breakpoint: { max: 1024, min: 640 }, items: 1 },
                      mobile: { breakpoint: { max: 640, min: 0 }, items: 1 },
                    }}
                    slidesToSlide={1}
                    swipeable
                    customButtonGroup={<ButtonGroup />}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customDot={<CustomDot />}
                  >
                    {[
                      selectedSession?.sessionBooking?.[0]?.bookingRoom?.logo,
                      ...(selectedSession?.sessionBooking?.[0]?.bookingRoom
                        ?.album || []),
                    ].map((image, index) =>
                      image ? (
                        <img
                          key={index}
                          src={`${env.IMAGE_API}${image?.name}`}
                          alt={`Room ${index + 1}`}
                          className="big-image object-cover rounded-lg mx-auto w-[334px] h-[178px] md:w-[376px] md:h-[238px] sm:w-[334px] sm:h-[178px]"
                        />
                      ) : null
                    )}
                  </Carousel>
                </div>
                <div className="w-full flex flex-col gap-3 small-images">
                  {[
                    selectedSession?.sessionBooking?.[0]?.bookingRoom?.logo,
                    ...(selectedSession?.sessionBooking?.[0]?.bookingRoom
                      ?.album || []),
                  ]
                    .slice(1, 3)
                    .map((image, index) =>
                      image ? (
                        <img
                          key={index}
                          src={`${env.IMAGE_API}${image?.name}`}
                          alt={`Classroom ${index + 1}`}
                          className="max-w-[175px] max-h-[114px] object-cover rounded-lg"
                        />
                      ) : null
                    )}
                </div>
              </div>
              {/* selected class details */}
              <div className="h-full flex flex-col justify-between items-start">
                <div className="flex flex-col justify-start items-start space-y-[4px]">
                  <div className="w-full flex justify-between items-center">
                    {/* selected class full location */}
                    <div className="flex justify-start items-center space-x-[5px] ">
                      {/* location icon */}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.6667 5.83332C11.6667 8.41065 7.00004 12.8333 7.00004 12.8333C7.00004 12.8333 2.33337 8.41065 2.33337 5.83332C2.33337 3.25599 4.42271 1.16666 7.00004 1.16666C9.57737 1.16666 11.6667 3.25599 11.6667 5.83332Z"
                          stroke="#370513"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M6.99996 6.41667C7.32213 6.41667 7.58329 6.1555 7.58329 5.83333C7.58329 5.51117 7.32213 5.25 6.99996 5.25C6.67779 5.25 6.41663 5.51117 6.41663 5.83333C6.41663 6.1555 6.67779 6.41667 6.99996 6.41667Z"
                          fill="#370513"
                          stroke="#370513"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {/* location text */}
                      <span className="text-[#370513] font-inter font-bold text-[14px] leading-[24px] ">
                        {selectedSession?.sessionBooking?.[0]?.bookingRoom
                          ?.address?.name || ""}
                      </span>
                    </div>
                    {/* enrolled students number */}
                    <div className="hidden md:flex justify-start items-center space-x-[4px]">
                      <div className="flex  justify-start items-center space-x-[4px]">
                        <span className="text-[#E83C70] font-inter font-semibold text-[12px] leading-[24px] ">
                          {getNumberOfDaysLeft(
                            selectedSession?.start,
                            selectedSession?.startTime
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* classroom name */}
                  <span className="text-[#1B0209] font-inter font-semibold text-[22px] leading-[32px] ">
                    {selectedSession?.sessionBooking?.[0]?.bookingRoom?.name ||
                      "No Room Assigned"}
                  </span>
                  {/* classroom rating */}
                  <div className="flex justify-start items-center space-x-[10px]">
                    {/* rating number */}
                    <span className="text-[#3F3F3E] font-inter font-semibold text-[12px] leading-[24px] ">
                      {selectedSession?.sessionBooking?.[0]?.bookingRoom
                        .rating || "5"}
                    </span>
                    {/* rating stars */}
                    <div className="flex justify-start items-center space-x-[1.17px]">
                      {selectedClassRatingStars(
                        selectedSession?.sessionBooking?.[0]?.bookingRoom
                          .rating || 5
                      ).map((starType, idx) => (
                        <img
                          key={idx}
                          width={16}
                          height={16}
                          src={`/images/icons/${
                            starType === "filled"
                              ? "star.svg"
                              : "empty-star.svg"
                          }`}
                          alt={starType}
                        />
                      ))}
                    </div>
                    {/* ratings count number */}
                    <span className="text-[#BCBCBC] font-inter font-medium text-[12px] leading-[24px] ">
                      (
                      {selectedSession?.sessionBooking?.[0]?.bookingRoom
                        .ratingsCount || "5"}
                      )
                    </span>
                  </div>
                  {/* classroom description */}
                  <span className="text-[#1B0209] font-inter font-normal text-[14px] leading-[24px] ">
                    {selectedSession?.sessionBooking?.[0]?.bookingRoom
                      ?.description || "No description available."}
                  </span>
                </div>
                {/* book a seat button & enrolled students number */}
                <div className="w-full flex flex-col-reverse md:flex-row justify-start items-center space-y-[10px] space-y-reverse md:space-y-0 md:space-x-[20px]">
                  {/* book a seat button */}
                  <Button
                    type="primary_dark"
                    text="Book a Seat"
                    onClick={participate}
                    isLoading={isStripeProcessing}
                  />
                  {/* enrolled students number */}
                  {/* <div className="flex w-[165px] justify-start items-center space-x-[4px]">
                    <span className="text-[#E83C70] font-inter font-semibold text-[12px] leading-[24px] ">
                      {getNumberOfDaysLeft(
                        selectedSession?.start,
                        selectedSession?.startTime
                      )}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* selected session schedule */}
        {selectedSession && (
          <div className="w-full flex justify-center items-center">
            <SchedulePlanner
              key={selectedSession?.id}
              readOnly={true}
              isOldSchedule={
                selectedSession?.planning === undefined ? true : false
              }
              schedule={
                selectedSession?.planning
                  ? selectedSession?.planning
                  : selectedSession?.scheduleData
              }
            ></SchedulePlanner>
          </div>
        )}
      </div>
      {isOpen && (
        <Popup
          type="class"
          isOpen={isOpen}
          course={selectedSession?.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </>
  );
}

export default observer(ClassUpcomingCohorts);
