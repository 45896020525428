import { observer } from "mobx-react";

import { useStore } from "../../../../store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function AboutCourse() {
  const { courseStore } = useStore();
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const course: any = courseStore.courseDetails;

  const numberOfShownWords = 100;
  const content = course?.forWho;
  const truncatedContent = content
    ?.split(" ")
    .slice(0, numberOfShownWords)
    .join(" ");

  const getCourseDifficulty = () => {
    if (course?.difficulty) {
      switch (course.difficulty) {
        case "Beginner": {
          return t("messages.beginnerLevel");
        }
        case "Intermediate": {
          return t("messages.intermediateLevel");
        }
        case "Advanced": {
          return t("messages.AdvancedLevel");
        }
        default: {
          return t("messages.beginnerLevel");
        }
      }
    } else {
      return t("messages.beginnerLevel");
    }
  };

  const toggleExpanded = () => {
    console.log(isExpanded, content.length, truncatedContent.length);
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col gap-4 w-full" id="about-course">
      <h3 className="body-large-bold">About this Course</h3>

      <div className="flex flex-wrap flex-col sm:flex-row gap-2.5 items-center">
        <div className="flex items-center gap-3.5 w-full sm:w-fit px-[26px] py-[18px] bg-primary-paradise-pink-100 rounded-2xl">
          <img src="/images/icons/certificate-pink.svg" />
          <span className="body-small-bold text-primary-paradise-pink-500">
            Certified Course
          </span>
        </div>
        <div className="flex items-center gap-3.5 w-full sm:w-fit px-[26px] py-[18px] bg-primary-paradise-pink-100 rounded-2xl">
          <img src="/images/icons/level.svg" />
          <span className="body-small-bold text-primary-paradise-pink-500">
            {getCourseDifficulty()}
          </span>
        </div>
      </div>

      <div
        className={"body-medium"}
        style={{ transition: "all 1s cubic-bezier(0.34, 0.32, 0.43, 0.9)" }}
      >
        <p className="whitespace-pre-wrap">
          {isExpanded ? content : truncatedContent}
          {!isExpanded && content !== truncatedContent ? "..." : ""}
        </p>
        <input
          className="hidden"
          type="checkbox"
          id="readMore"
          checked={isExpanded}
          onChange={toggleExpanded}
        />
        {content !== truncatedContent && (
          <label
            className="text-primary-paradise-pink-500 hover:text-primary-paradise-pink-600 underline cursor-pointer mt-1.5"
            htmlFor="readMore"
          >
            {isExpanded ? "Read less" : "Read more"}
          </label>
        )}
      </div>
    </div>
  );
}

export default observer(AboutCourse);
