import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../../store";
import { Button, Footer, Navbar } from "../../../components";
import styles from "./postWirePaymentSuccess.module.css";

function PostWirePayment() {
  const navigate = useNavigate();
  const { userStore } = useStore();

  return (
    <div>
      <div className={styles.enrolled_container}>
        <Navbar />

        <main>
          <div className={styles.text_container}>
            <div className={styles.titles_container}>
              <div className={styles.sub_title}>
                <img
                  src="/images/icons/success.svg"
                  alt="design icon"
                  width={38}
                  height={38}
                />
                <h2>You have successfully enrolled</h2>
              </div>
              <div>
                <h1 className={styles.title_1}>
                  Thank you {userStore?.user?.fullName} !
                </h1>
                <h2 className={styles.title_2}>
                  Our team will get in touch soon.
                </h2>
              </div>
            </div>
          </div>

          <div className={styles.buttons_container}>
            <Button
              type="secondary"
              text="Browse other courses"
              onClick={() => navigate("/search")}
              customColor="#23232F"
            />
            <Button
              type="primary"
              text="View my upcoming classes"
              onClick={() => navigate("/myclasses")}
              customColor="#23232F"
              rightIcon="/images/icons/arrow-right-white.svg"
            />
          </div>
        </main>
      </div>

      <Footer />
    </div>
  );
}

export default observer(PostWirePayment);
