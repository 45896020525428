import React from "react";
import Modal from "react-modal";
import Button from "../Button";
import Input from "../Input";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

interface InputProps {
  isOpen: boolean;
  closePopup: () => void;
}

const SharePopup: React.FC<InputProps> = ({ isOpen, closePopup }) => {
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  const socials = [
    {
      name: "linkedin",
      icon: "/images/icons/socialMedia/linkedin.svg",
      url: `https://www.linkedin.com/shareArticle?url=${url}`,
    },
    {
      name: "whatsapp",
      icon: "/images/icons/socialMedia/whatsapp.svg",
      url: `https://api.whatsapp.com/send/?text=${url}`,
    },
    {
      name: "twitter",
      icon: "/images/icons/socialMedia/twitter.svg",
      url: `https://twitter.com/intent/tweet?url=${url}`,
    },
    {
      name: "instagram",
      icon: "/images/icons/socialMedia/instagram.svg",
      url: `https://www.instagram.com/?url=${url}`,
    },
    {
      name: "facebook",
      icon: "/images/icons/socialMedia/facebook.svg",
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
  ];

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    toast.success("Link copied to clipboard");
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closePopup} style={modalStyles}>
      <div className="w-[350px] sm:w-[478px]">
        <div className="flex justify-between items-center px-5 py-3.5 border-b border-primary-gray-100">
          <h3 className="body-medium-bold text-primary-purple-800">
            Share this Course
          </h3>
          <img
            className="cursor-pointer"
            src="/images/icons/close.svg"
            onClick={closePopup}
          />
        </div>

        <div className="body-small flex flex-col gap-3.5 px-5 py-3.5 text-primary-gray-800">
          <div className="flex flex-col gap-1.5">
            <span>Share this link via</span>
            <div className="flex justify-between gap-4">
              {socials.map((social) => (
                <a
                  key={social.name}
                  className="p-4 sm:p-5 bg-primary-gray-100 rounded-full hover:bg-primary-gray-200 cursor-pointer transition-all duration-300"
                  target="_blank"
                  href={social.url}
                  rel="noreferrer"
                >
                  <img src={social.icon} />
                </a>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <span>Or copy link</span>
            <div className="flex justify-between gap-2">
              <Input
                icon="/images/icons/link.svg"
                value={url}
                readOnly
                onChange={() => {}}
              />
              <Button
                type="primary"
                text="Copy"
                width="fit-content"
                onClick={handleCopy}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "24px",
    border: "1px solid var(--Primary-Gray-100, rgba(245, 245, 245, 1))",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "var(--Primary-Gray-50)",
    // margin: "20px",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: "30",
  },
};

export default SharePopup;
