import React, { useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import ListItem from "./ListItem";
import { useStore } from "../../../../../store";
import { useNavigate, useLocation } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import env from "../../../../../config/env";
import { useTranslation } from "react-i18next";

type SearchModalProps = {
  closePopup: () => void;
  difficulty: string;
  price: any;
  setSelectedDomains: any;
  setActualPlaceId: any;
  setLocationLabel: any;
  setDifficulty: any;
  setPrice: any;
  setFocus: any;
};

type Option = {
  value: string;
  label: string;
};

const icons = [
  "/images/icons/topics/coding.svg",
  "/images/icons/topics/design.svg",
  "/images/icons/topics/digital-marketing.svg",
  "/images/icons/topics/product.svg",
  "/images/icons/topics/life-skills.svg",
  "/images/icons/topics/data-science.svg",
  "/images/icons/topics/ai.svg",
];

const SearchModal: React.FC<SearchModalProps> = ({
  closePopup,
  difficulty,
  price,
  setSelectedDomains,
  setActualPlaceId,
  setLocationLabel,
  setDifficulty,
  setPrice,
  setFocus,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { courseStore } = useStore();
  const appLocation = useLocation();

  const courses = courseStore?.domains;

  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [location, setLocation] = useState("");
  const [locationObject, setLocationObject] = useState<Option | null>(null);
  const [course, setCourse] = useState("");
  const [activeTab, setActiveTab] = useState<"courses" | "locations">(
    "courses"
  );
  const [placeId, setPlaceId] = useState<string>("");

  useEffect(() => {
    const searchStorage = sessionStorage.getItem("search");
    if (searchStorage) {
      const searchStorageObject = JSON.parse(searchStorage);
      if (searchStorageObject.domains) {
        setSelectedCourses(searchStorageObject.domains);
        setSelectedDomains(searchStorageObject.domains);
        setFocus(false);
      }
      if (searchStorageObject.location) {
        setLocation(
          searchStorageObject.location.locationName.label
            ? searchStorageObject.location.locationName.label
            : searchStorageObject.location.locationName
        );
        setSelectedLocations([
          searchStorageObject.location.locationName.label
            ? searchStorageObject.location.locationName.label
            : searchStorageObject.location.locationName,
        ]);
        setPlaceId(searchStorageObject.location.placeId);
        setActualPlaceId(searchStorageObject.location.placeId);
        if (searchStorageObject.location.locationName.label) {
          setLocationLabel(searchStorageObject.location.locationName);
          setLocationObject(searchStorageObject.location.locationName);
        }
      }
      if (searchStorageObject.difficulty) {
        setDifficulty(searchStorageObject.difficulty);
      }
      if (searchStorageObject.priceMin && searchStorageObject.priceMax) {
        setPrice([searchStorageObject.priceMin, searchStorageObject.priceMax]);
      }
    }
  }, []);

  const getSelectedCourses = () => {
    return selectedCourses.length > 1
      ? `${selectedCourses.length} ${t("searchModal.selected")}`
      : selectedCourses[0];
  };

  const getSelectedLocations = () => {
    return selectedLocations[0].split(",")[0];
  };

  const reset = () => {
    setSelectedCourses([]);
    setSelectedLocations([]);
    setCourse("");
    setLocation("");
  };

  const handleSearch = (e: any) => {
    e.preventDefault();

    const selectedDomainsIds: any[] = [];
    if (courseStore?.domains && courseStore?.domains.length > 0) {
      courseStore?.domains.forEach((domain: any) => {
        if (selectedCourses.includes(domain.name)) {
          selectedDomainsIds.push(domain.id.toString());
        }
      });
    }

    courseStore
      ?.searchCourses(8, {
        location: placeId,
        domain: selectedDomainsIds,
        difficulty: difficulty,
        priceMin: price[0],
        priceMax: price[1],
      })
      .then(() => {
        // let searchStorage = {
        //   domains: selectedCourses,
        //   location: { placeId: placeId, locationName: locationObject },
        //   difficulty: difficulty,
        //   priceMin: price[0],
        //   priceMax: price[1],
        // };

        const searchStorage: any = {};
        if (selectedCourses && selectedCourses.length > 0) {
          searchStorage.domains = selectedCourses;
        }
        if (
          placeId &&
          placeId !== "" &&
          locationObject &&
          locationObject !== null
        ) {
          searchStorage.location = {
            placeId: placeId,
            locationName: locationObject,
          };
        }
        if (difficulty && difficulty !== "") {
          searchStorage.difficulty = difficulty;
        }
        if (price && price.length === 2) {
          searchStorage.priceMin = price[0];
          searchStorage.priceMax = price[1];
        }

        sessionStorage.setItem("search", JSON.stringify(searchStorage));
        setActualPlaceId(placeId);
        setSelectedDomains(selectedCourses);
        setLocationLabel(location);
        setDifficulty(difficulty);
        setPrice(price);
        closePopup();
        if (appLocation.pathname !== "/search") {
          navigate("/search");
        }
      });
  };

  const onPlaceSelected = async (value: any | null) => {
    setLocation(value.label);
    setSelectedLocations([value.label]);
    setLocationLabel(value);
    setLocationObject(value);
    if (value && value.value.place_id) {
      setPlaceId(value.value.place_id);
      setActualPlaceId(value.value.place_id);
    }
  };

  return (
    <div className="fixed inset-0 z-50 w-full p-6 bg-primary-gray-50">
      <div className="relative body-medium-bold flex justify-center items-center mb-4">
        <h2>{t("searchModal.title")}</h2>
        <img
          src="/images/icons/close.svg"
          className="absolute right-0 size-10"
          alt="menu"
          onClick={closePopup}
        />
      </div>

      <div
        className="mt-4 border border-primary-gray-200 rounded-xl p-5"
        onClick={() => setActiveTab("courses")}
        style={{ maxHeight: "calc(100% - 190px)", overflowY: "scroll" }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {activeTab !== "courses" && <img src="/images/icons/course.svg" />}
            <button
              className={
                activeTab === "courses" ? "body-large" : "body-medium-bold"
              }
            >
              {t("searchModal.courseTab")}
            </button>
          </div>
          {selectedCourses.length > 0 && (
            <div className="caption-text px-4 py-1 rounded-full bg-primary-paradise-pink-100 text-primary-paradise-pink-500">
              {getSelectedCourses()}
            </div>
          )}
        </div>
        <div className={`${activeTab === "courses" ? "block" : "hidden"}`}>
          <SearchInput
            value={course}
            setValue={setCourse}
            placeholder={t("searchModal.placeholderCourse") || ""}
          />
          <ul className="m-0 mt-4 space-y-2 list-none">
            {courses
              .filter((courseItem: any) =>
                courseItem.name.toLowerCase().includes(course.toLowerCase())
              )
              .map((course: any, index) => (
                <ListItem
                  key={index}
                  name={course.name}
                  icon={icons[index % icons.length]}
                  selectedItems={selectedCourses}
                  setSelectedItems={setSelectedCourses}
                  setSeletecDomains={setSelectedDomains}
                />
              ))}
            {course &&
              !courses.some(
                (courseItem: any) =>
                  courseItem.name.toLowerCase() === course.toLowerCase()
              ) && (
                <ListItem
                  key="new-course"
                  name={course}
                  icon={icons[icons.length - 1]}
                  selectedItems={selectedCourses}
                  setSelectedItems={setSelectedCourses}
                  setSeletecDomains={setSelectedDomains}
                />
              )}
          </ul>
        </div>
      </div>

      <div
        className="mt-4 border border-primary-gray-200 rounded-xl p-5"
        onClick={() => setActiveTab("locations")}
        style={{ maxHeight: "calc(100% - 190px)", overflowY: "hidden" }}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {activeTab !== "locations" && (
              <img src="/images/icons/location-black.svg" />
            )}
            <button
              className={
                activeTab === "locations" ? "body-large" : "body-medium-bold"
              }
            >
              {t("searchModal.locationTab")}
            </button>
          </div>
          {selectedLocations.length > 0 && (
            <div className="caption-text px-4 py-1 rounded-full bg-primary-paradise-pink-100 text-primary-paradise-pink-500">
              {getSelectedLocations()}
            </div>
          )}
        </div>
        <div
          className={`${
            activeTab === "locations" ? "h-screen block mt-4" : "hidden"
          }`}
        >
          <GooglePlacesAutocomplete
            apiKey={env.GOOGLE_MAPS_API_KEY}
            selectProps={{
              onChange: onPlaceSelected,
              noOptionsMessage: () => t("searchModal.locationsNearYou"),
              placeholder: t("searchModal.placeholderLocation"),
              value: locationObject || undefined,
              styles: {
                input: (provided) => ({
                  ...provided,
                  width: "100%",
                  padding: "0",
                  margin: "0",
                  border: "none",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "24px",
                }),
                indicatorsContainer: (provided) => ({
                  display: "none",
                }),
                control: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                  padding: "1rem",
                  backgroundColor: "#FDFCFF",
                  border: "1px solid #D1D5DB",
                  borderRadius: "0.75rem",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  width: "100%",
                  padding: "0",
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                }),
                menuList: (provided) => ({
                  ...provided,
                  display: "block",
                  padding: "0",
                  margin: "0",
                }),
                option: (provided, state) => ({
                  ...provided,
                  padding: "10px 15px",
                  cursor: "pointer",
                  backgroundColor: state.isFocused ? "#f1f1f1" : "transparent",
                  color: "#333",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#B4b9c2",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "24px",
                }),
              },
              className: "w-full",
            }}
          />
        </div>
      </div>

      <div className="mt-5 flex justify-between items-center">
        <button className="body-medium-linked" onClick={reset}>
          {t("searchBar.resetChanges")}
        </button>
        <button
          className="body-medium px-5 py-4 bg-primary-paradise-pink-500 text-white rounded-full flex items-center gap-2"
          onClick={handleSearch}
        >
          <img src="/images/icons/search.svg" />
          {t("searchModal.searchButton")}
        </button>
      </div>
    </div>
  );
};

export default SearchModal;
