import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../store";
import {
  Navbar,
  Loading,
  UploadImage,
  Input,
  Select as RSelect,
  MultiSelectInput,
  Button,
} from "src/components/shared";
import Select from "react-select";
import "./styles.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import env from "../../../config/env";
import RichTextEditor from "src/components/shared/Input/RichTextEditor";

export default function UpdateCourse() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();
  const params = useParams();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [syllabus, setSyllabus] = useState("");
  const [syllabusEditorState, setSyllabusEditorState] = useState(
    EditorState.createEmpty()
  );
  const [forWho, setForWho] = useState("");
  const [objectifs, setObjectifs] = useState("");
  const [objectifsEditorState, setObjectifsEditorState] = useState(
    EditorState.createEmpty()
  );
  const [objectivesImage, setObjectivesImage] = useState<File | null>(null);
  const [perspectives, setPerspectives] = useState("");
  const [perspectivesEditorState, setPerspectivesEditorState] = useState(
    EditorState.createEmpty()
  );
  const [perspectivesImage, setPerspectivesImage] = useState<File | null>(null);
  const [price, setPrice] = useState("");
  const [level, setLevel] = useState("Beginner");
  const [createdBy, setCreatedBy] = useState("");
  const [skills, setSkills] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState<
    { value: string; label: string }[]
  >([]);

  const [currency, setCurrency] = useState("USD");
  const [language, setLanguage] = useState("English");
  const [courseAttachment, setCourseAttachment] = useState<any | null>(null);

  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const summaryRef = useRef<HTMLTextAreaElement>(null);

  const [courseTestimonials, setCourseTestimonials] = useState([
    {
      author: "",
      content: "",
      photo: {
        preview: "images/default-user.jfif",
        data: {},
        container: {
          container: "img",
          name: "",
          type: "image",
          field: "file",
          originalFilename: "",
          size: 0,
        },
      },
    },
  ]);

  const [isOldSyllabus, setIsOldSyllabus] = useState(false);
  const [courseContents, setCourseContents] = useState([
    { step: 1, title: "", content: "", expanded: true },
  ]);

  const courseLevels = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Advanced" },
  ];

  const currencyOptions = [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "CAD", label: "CAD" },
    { value: "TND", label: "TND" },
  ];

  const languageOptions = [
    { value: "English", label: "English" },
    { value: "French", label: "French" },
  ];

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        setLoading(true);
        const courseId = params.id;

        if (!courseId) {
          navigate("/teaching");
          return;
        }

        await courseStore.getCourseById(courseId);

        const [domainsResponse, categoriesResponse] = await Promise.all([
          courseStore.getAllDomains(),
          courseStore.getAllCategories(),
        ]);

        let passedDomains: any[] = [];
        let passedCategories: any[] = [];
        if (domainsResponse) {
          const domains = domainsResponse.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }));
          passedDomains = domains;
          setDomains(domains);
        }

        if (categoriesResponse) {
          const categories = categoriesResponse.map((category: any) => ({
            value: category.id,
            label: category.name,
          }));
          passedCategories = categories;
          setCategories(categories);
          setCategory(categories[0]?.value);

          initCourseDetails(
            courseStore.courseDetails,
            passedCategories,
            passedDomains
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [params.id, navigate, courseStore]);

  const initCourseDetails = (
    courseDetails: any,
    categoriesResponse: any,
    domainsResponse: any
  ) => {
    setTitle(courseDetails.title || "");
    setDescription(courseDetails.description || "");
    setForWho(courseDetails.forWho || "");
    setIsOldSyllabus(courseDetails.isOldSyllabus || false);
    if (courseDetails?.isOldSyllabus) {
      setSyllabus(JSON.stringify(courseDetails?.syllabus));
      setSyllabusEditorState(
        courseDetails?.syllabus
          ? EditorState.createWithContent(
              convertFromRaw(courseDetails?.syllabus)
            )
          : EditorState.createEmpty()
      );
    } else {
      setSyllabus("");
      setSyllabusEditorState(EditorState.createEmpty());
      if (courseDetails?.syllabus && courseDetails?.syllabus.length > 0) {
        const newCourseContents = courseDetails?.syllabus.map(
          (content: any, index: number) => {
            return {
              step: index + 1,
              title: content.title,
              content: content.content,
              expanded: true,
            };
          }
        );

        setCourseContents(newCourseContents);
      } else {
        setCourseContents([
          { step: 1, title: "", content: "", expanded: true },
        ]);
      }
    }

    if (
      courseDetails.objectifs &&
      courseDetails.objectifs.content &&
      courseDetails.objectifs.content.blocks
    ) {
      setObjectifs(JSON.stringify(courseDetails.objectifs.content));
      setObjectifsEditorState(
        EditorState.createWithContent(
          convertFromRaw(courseDetails.objectifs.content)
        )
      );
    }

    if (
      courseDetails.perspectives &&
      courseDetails.perspectives.content &&
      courseDetails.perspectives.content.blocks
    ) {
      setPerspectives(JSON.stringify(courseDetails.perspectives.content));
      setPerspectivesEditorState(
        EditorState.createWithContent(
          convertFromRaw(courseDetails.perspectives.content)
        )
      );
    }

    setCourseTestimonials(courseDetails.testimonials || []);
    setPrice(courseDetails.price || 0);
    setCurrency(courseDetails.currency || "USD");
    setLanguage(courseDetails.language || "English");
    setCreatedBy(courseDetails.courseCreator || "");
    setLevel(courseDetails.difficulty || "Beginner");
    setSkills(courseDetails.prerequisite || []);
    if (courseDetails.domains && courseDetails.domains.length > 0) {
      const selectedDomainsNames = courseDetails.domains.map(
        (domain: any) => domain.name
      );

      const finalSelectedDomains = domainsResponse.filter((domain: any) =>
        selectedDomainsNames.includes(domain.label.toString())
      );
      setSelectedDomains(finalSelectedDomains);
    }
    if (courseDetails.categoryId) {
      const selectedCategory = categoriesResponse.find(
        (category: any) =>
          category.value.toString() === courseDetails.categoryId.toString()
      );

      if (selectedCategory) {
        setCategory(selectedCategory.label);
      }
    }

    if (
      courseDetails.image &&
      courseDetails.image.name &&
      courseDetails.image.name !== ""
    ) {
      setImage(courseDetails.image);
    }
    if (
      courseDetails.objectifs.image &&
      courseDetails.objectifs.image.name &&
      courseDetails.objectifs.image.name !== ""
    ) {
      setObjectivesImage(courseDetails.objectifs.image);
    }
    if (
      courseDetails.perspectives.image &&
      courseDetails.perspectives.image.name &&
      courseDetails.perspectives.image.name !== ""
    ) {
      setPerspectivesImage(courseDetails.perspectives.image);
    }
    if (
      courseDetails.file &&
      Object.keys(courseDetails.file).length > 0 &&
      courseDetails.file.name &&
      courseDetails.file.name.length > 0
    ) {
      setCourseAttachment(courseDetails.file);
    }
  };

  const links = [{ path: "/mycourses", label: "My Courses" }];

  const handleSave = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const descriptionLength = description.trim().length;
    const summaryLength = forWho.trim().length;

    if (descriptionLength < 300) {
      descriptionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoading(false);
      return;
    }

    if (summaryLength < 400) {
      summaryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoading(false);
      return;
    }

    const course: any = {
      id: params.id,
      title: title,
      description: description,
      image: image,
      difficulty: level,
      courseCreator: createdBy,
      forWho: forWho,
      price: price,
      prerequisite: skills,
      objectifs: {
        content:
          objectifs.length > 0
            ? JSON.parse(objectifs)
            : { blocks: [], entityMap: {} },
        image: objectivesImage,
      },
      perspectives: {
        content:
          perspectives.length > 0
            ? JSON.parse(perspectives)
            : { blocks: [], entityMap: {} },
        image: perspectivesImage,
      },
      domains: selectedDomains.map((domain: any) => {
        return { id: domain.value, name: domain.label };
      }),
      categoryId: categories.find((cat: any) => cat.label === category)?.value,
      currency: currency,
      language: language,
      testimonials: courseTestimonials,
      file:
        courseAttachment !== null
          ? courseAttachment
          : { name: "", originalFileName: "" },
    };

    if (isOldSyllabus) {
      course.syllabus =
        syllabus.length > 0
          ? JSON.parse(syllabus)
          : { blocks: [], entityMap: {} };

      course.isOldSyllabus = true;
    } else {
      course.syllabus = courseContents.map((content: any) => {
        return {
          title: content.title,
          content: content.content,
        };
      });
      course.isOldSyllabus = false;
    }

    await courseStore
      .editCourse(course)
      .then((response) => {
        setLoading(false);
        navigate("/teaching");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleAddContent = () => {
    setCourseContents((prev) => [
      ...prev,
      { step: prev.length + 1, title: "", content: "", expanded: true },
    ]);
  };

  const handleRemoveContent = (index: number) => {
    if (courseContents.length > 1) {
      setCourseContents((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleContentChange = (
    index: number,
    field: "title" | "content",
    value: string
  ) => {
    setCourseContents((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  };

  const toggleExpandCollapse = (index: number) => {
    setCourseContents((prev) => {
      const updated = [...prev];
      updated[index].expanded = !updated[index].expanded;
      return updated;
    });
  };

  const handleSyllabusEditorChange = (newEditorState: EditorState) => {
    setSyllabusEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setSyllabus(JSON.stringify(rawContentState));
  };

  const handleObjectifsEditorChange = (newEditorState: EditorState) => {
    setObjectifsEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setObjectifs(JSON.stringify(rawContentState));
  };

  const handlePerspectivesEditorChange = (newEditorState: EditorState) => {
    setPerspectivesEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setPerspectives(JSON.stringify(rawContentState));
  };

  const updateTestimonialField = (index: any, field: any, value: any) => {
    const updatedTestimonials = [...courseTestimonials];
    updatedTestimonials[index] = {
      ...updatedTestimonials[index],
      [field]: value,
    };
    setCourseTestimonials(updatedTestimonials);
  };

  const photoChangeHandlerTestimonial = async (index: any, e: any) => {
    const updatedTestimonials = [...courseTestimonials];
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      userStore.uploadPicture(file).then((response) => {
        updatedTestimonials[index].photo.container = response;
        console.log("updatedTestimonials", updatedTestimonials);

        setCourseTestimonials(updatedTestimonials);
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Add new testimonial
  const addNewTestimonial = () => {
    setCourseTestimonials([
      ...courseTestimonials,
      {
        author: "",
        content: "",
        photo: {
          preview: "images/default-user.jfif",
          data: {},
          container: {
            container: "img",
            name: "",
            type: "image",
            field: "file",
            originalFilename: "",
            size: 0,
          },
        },
      },
    ]);
  };

  // Remove testimonial
  const removeTestimonial = (index: any) => {
    if (courseTestimonials.length > 1) {
      const updatedTestimonials = [...courseTestimonials];
      updatedTestimonials.splice(index, 1);
      setCourseTestimonials(updatedTestimonials);
    }
  };

  const updateCourseAttachment = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      userStore.uploadFile(file).then((response) => {
        setCourseAttachment(response);
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const setHtml = (index: number, value: any) => {
    handleContentChange(index, "content", value);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />

      <form className="new-course" onSubmit={handleSave}>
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <Link to={link.path} className="label">
                {link.label}
              </Link>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">Update course</div>
        </div>

        <h1>Update course</h1>

        <div className="inputs">
          <div className="left-column">
            <div className="field">
              <label htmlFor="picture">Course banner</label>
              <div className="input-field">
                <UploadImage
                  image={image}
                  setImage={setImage}
                  width="80%"
                  isEdit={true}
                />
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <label htmlFor="title">Title</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    label="Title"
                    required
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="description">
                <div className="w-full flex justify-between items-center">
                  <span>Description </span>
                  {description.length < 300 && (
                    <small className="text-red-500">
                      {`Write at least ${
                        300 - description.length
                      } more characters.`}
                    </small>
                  )}
                </div>
              </label>
              <div className="input-field">
                <textarea
                  className="bio resize-none"
                  required
                  id="description"
                  placeholder="Description"
                  value={description}
                  ref={descriptionRef}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label htmlFor="description">
            <div className=" w-full flex justify-between items-center">
              <span>Course Summary </span>
              {forWho.length < 400 && (
                <small className="text-red-500 font-inter font-normal">
                  {`Write at least ${400 - forWho.length} more characters.`}
                </small>
              )}
            </div>
          </label>
          <div className="input-field">
            <textarea
              className="bio resize-none"
              required
              id="summary"
              placeholder="Course Summary"
              value={forWho}
              onChange={(e) => setForWho(e.target.value)}
            />
          </div>
        </div>

        {isOldSyllabus && (
          <div className="field">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="syllabus">
                Syllabus{" "}
                <span className="text-red-500 text-[14px]">
                  ( We recommend using the new syllabus )
                </span>{" "}
              </label>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  name="syllabus"
                  id="syllabus"
                  checked={!isOldSyllabus}
                  onChange={(e) => setIsOldSyllabus(!e.target.checked)}
                  className="peer sr-only"
                />
                <label htmlFor="switch" className="hidden"></label>
                <div className="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-slate-800 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-300 ml-2">
                  Switch to new syllabus
                </span>
              </label>
            </div>
            <div className="input-field">
              <div className="input_container">
                <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                  <Editor
                    editorState={syllabusEditorState}
                    onEditorStateChange={handleSyllabusEditorChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {!isOldSyllabus && (
          <div className="field">
            <div className="w-full flex justify-between items-center">
              <label htmlFor="syllabus">Syllabus</label>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  name="syllabus"
                  id="syllabus"
                  checked={!isOldSyllabus}
                  onChange={(e) => setIsOldSyllabus(!e.target.checked)}
                  className="peer sr-only"
                />
                <label htmlFor="switch" className="hidden"></label>
                <div className="peer h-6 w-11 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-slate-800 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-300 ml-2">
                  Switch to old syllabus
                </span>
              </label>
            </div>
            {courseContents.map((content, index) => (
              <div key={index} className="content-item mt-2">
                <div className="w-full flex justify-start items-center space-x-2">
                  <button
                    type="button"
                    onClick={() => toggleExpandCollapse(index)}
                    style={{
                      color: "var(--Primary-ParadisePink-500)",
                      border: "1px solid",
                      borderColor: "var(--Primary-Gray-200)",
                      borderRadius: "12px",
                      width: "50px",
                      height: "50px",
                      fontSize: "40px",
                    }}
                    className="flex justify-center items-center"
                  >
                    {content.expanded ? "-" : "+"}
                  </button>
                  <input
                    type="text"
                    name="content title"
                    placeholder="Content Title"
                    value={content.title}
                    onChange={(e) =>
                      handleContentChange(index, "title", e.target.value)
                    }
                    style={{
                      padding: "12px 16px",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "var(--black)",
                      outline: "1px solid var(--Primary-Gray-200)",
                      borderRadius: "12px",
                      width: "100%",
                    }}
                  />
                  {courseContents.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveContent(index)}
                      style={{
                        color: "var(--Primary-ParadisePink-500)",
                        border: "1px solid",
                        borderColor: "var(--Primary-Gray-200)",
                        borderRadius: "12px",
                        width: "50px",
                        height: "50px",
                      }}
                    >
                      X
                    </button>
                  )}
                </div>

                {content.expanded && (
                  <div className="input-field">
                    {/* <textarea
                      className="bio resize-none"
                      required
                      id="summary"
                      placeholder="Content Description"
                      ref={summaryRef}
                      value={content.content}
                      onChange={(e) =>
                        handleContentChange(index, "content", e.target.value)
                      }
                    /> */}
                    <RichTextEditor
                      html={content.content}
                      setHtml={(value) => setHtml(index, value)}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="w-full flex justify-center items-center mt-2">
              <Button
                text="add"
                onClick={handleAddContent}
                type="primary"
                width="150px"
                preventDefault={true}
              />
            </div>
          </div>
        )}

        <div className="w-full flex flex-col justify-start items-start">
          <div className="w-full">
            <label htmlFor="objectifs">Objectifs</label>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-start items-center space-y-12 md:space-y-0 md:space-x-8">
            <div className="w-full">
              <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                <Editor
                  editorState={objectifsEditorState}
                  onEditorStateChange={handleObjectifsEditorChange}
                />
              </div>
            </div>
            <div className="w-full md:w-[450px] input-field">
              <UploadImage
                image={objectivesImage}
                setImage={setObjectivesImage}
                isEdit={true}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-start items-start">
          <div className="w-full">
            <label htmlFor="perspectives">Perspectives</label>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-start items-center space-y-12 md:space-y-0 md:space-x-8">
            <div className="w-full">
              <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                <Editor
                  editorState={perspectivesEditorState}
                  onEditorStateChange={handlePerspectivesEditorChange}
                />
              </div>
            </div>
            <div className="w-full md:w-[450px] input-field">
              <UploadImage
                image={perspectivesImage}
                setImage={setPerspectivesImage}
                isEdit={true}
              />
            </div>
          </div>
        </div>

        <div className="grid gap-4 w-full">
          {courseTestimonials.map((testimonial, index) => (
            <div key={index} className="border p-4 rounded-lg">
              <div className="w-full flex flex-col md:flex-row justify-start items-center gap-8">
                <div className="w-full flex flex-col md:flex-row justify-start items-start gap-4">
                  {/* Testimonial Content */}
                  <div className="w-full md:w-1/2 flex flex-col space-y-2">
                    <label className="font-medium text-gray-700">
                      Testimonial {index + 1}
                    </label>
                    <textarea
                      className="border rounded-lg p-2 text-gray-900 focus:outline-none h-[150px] resize-none"
                      value={testimonial.content}
                      onChange={(e) =>
                        updateTestimonialField(index, "content", e.target.value)
                      }
                      placeholder="Testimonial Content"
                      required
                    />
                  </div>

                  {/* Author Input */}
                  <div className="w-full md:w-1/2 flex flex-col space-y-2">
                    <label className="font-medium text-gray-700">
                      Author {index + 1}
                    </label>
                    <textarea
                      className="border rounded-lg p-2 text-gray-900 focus:outline-none h-[150px] resize-none"
                      value={testimonial.author}
                      onChange={(e) =>
                        updateTestimonialField(index, "author", e.target.value)
                      }
                      placeholder="Testimonial Author"
                      required
                    />
                  </div>
                </div>

                {/* Image Upload */}
                <div className="flex flex-col justify-start md:justify-center items-start md:items-center space-y-4 w-[400px]">
                  <img
                    alt="Author"
                    src={
                      env.IMAGE_API + testimonial?.photo?.container?.name ||
                      "assets/images/default-user.jfif"
                    }
                    className="w-[200px] h-[200px] object-cover border"
                  />
                  <input
                    type="file"
                    className="w-[230px]"
                    onChange={(e) => photoChangeHandlerTestimonial(index, e)}
                  />
                </div>
              </div>

              {/* Remove Button */}
              {index !== 0 && (
                <div className="mt-4">
                  <Button
                    text="Remove Testimonial"
                    type="primary"
                    customColor="#373737"
                    width="250px"
                    preventDefault={true}
                    onClick={() => removeTestimonial(index)}
                  />
                </div>
              )}
            </div>
          ))}
          {/* Add Testimonial Button */}
          <div>
            <Button
              text="Add Testimonial"
              type="primary"
              width="200px"
              preventDefault={true}
              onClick={() => addNewTestimonial()}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 my-6">
          {/* Row 1 */}
          <div className="col-span-4 md:col-span-1">
            <label className="block">Price per person</label>
            <Input
              type="number"
              fullWidth
              value={price}
              required
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">Currency</label>
            <RSelect
              options={currencyOptions}
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              label="Select Currency"
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">Language</label>
            <RSelect
              options={languageOptions}
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              label="Select Language"
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">Created by (optional)</label>
            <Input
              type="text"
              fullWidth
              value={createdBy}
              onChange={(e) => setCreatedBy(e.target.value)}
            />
          </div>

          {/* Row 2 */}
          <div className="col-span-4 md:col-span-2">
            <label className="block">Course level</label>
            <RSelect
              options={courseLevels}
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              label="Select Course Level"
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <label className="block">Attachment (optional)</label>
            <input
              className="fileInput"
              type="file"
              id="courseAttachment"
              onChange={(e) => {
                updateCourseAttachment(e);
              }}
            />
          </div>

          {/* Row 3 */}
          <div className="col-span-4 md:col-span-2">
            <MultiSelectInput
              text="Required skills"
              name="skills"
              onValueChange={setSkills}
              oldValue={skills}
              required
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <label className="block">Domain</label>
            <Select
              isMulti
              options={domains}
              value={selectedDomains}
              closeMenuOnSelect={false}
              onChange={(e: any) => setSelectedDomains(e)}
              placeholder="Select domain(s)"
              required
            />
          </div>

          {/* Row 4 */}
          <div className="col-span-4 md:col-span-2">
            <label className="block">Category</label>
            <RSelect
              label="Select category"
              fullWidth
              options={categories}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons">
          <Button
            text="Cancel"
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault={true}
            onClick={() => navigate(-1)}
          />
          <Button text="Save" type="primary" width="400px" submit />
        </div>
      </form>
    </>
  );
}
