// src/index.tsx
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./App";
import { StoreProvider } from "./store";
import { LocationStore } from "./store/location";
import Modal from "react-modal";
import { GoogleOAuthProvider } from "@react-oauth/google";
import env from "./config/env";
import "./locales/i18n";
import { BetaNotificationProvider } from "./store/useBetaNotification";
import { CourseStore } from "./store/course";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useGoogleMaps } from "./store/useGoogleMaps";
import { Loading } from "./components/shared";

// Initialize stores
const locationStore = new LocationStore();
const courseStore = new CourseStore();

const AppInitializer = ({ children }: { children: React.ReactNode }) => {
  const isMapsLoaded = useGoogleMaps();

  const [loading, setLoading] = useState(false);

  const getLanguagesListAndSave = async () => {
    if (localStorage.getItem("languagesList")) {
      return;
    }

    try {
      const languages = await courseStore.getAllLanguages();
      if (languages?.length > 0) {
        localStorage.setItem("languagesList", JSON.stringify(languages));
      }
    } catch (error) {
      console.error("Error fetching languages list:", error);
    }
  };

  const loadThirdPartyScripts = () => {
    const gtmScript = document.createElement("script");
    gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=G-7N9Z104RLP";
    gtmScript.async = true;
    document.body.appendChild(gtmScript);

    gtmScript.onload = () => {
      (window as any).dataLayer = (window as any).dataLayer || [];

      (window as any).gtag = function (...args: any[]) {
        (window as any).dataLayer.push(args);
      };

      (window as any).gtag("js", new Date());
      (window as any).gtag("config", "G-7N9Z104RLP");
    };

    const hubspotScript = document.createElement("script");
    hubspotScript.src = "//js-eu1.hs-scripts.com/24925124.js";
    hubspotScript.async = true;
    hubspotScript.defer = true;
    document.body.appendChild(hubspotScript);
  };

  useEffect(() => {
    locationStore.getUserLocation();
    locationStore.initializeLocationData();
    getLanguagesListAndSave();
    loadThirdPartyScripts();
    // const handleUserInteraction = () => {
    //   loadThirdPartyScripts();
    //   window.removeEventListener("scroll", handleUserInteraction);
    //   window.removeEventListener("click", handleUserInteraction);
    // };

    // window.addEventListener("scroll", handleUserInteraction, { once: true });
    // window.addEventListener("click", handleUserInteraction, { once: true });
  }, []);

  if (loading || !isMapsLoaded) {
    return <Loading />;
  }

  return <>{children}</>;
};

Modal.setAppElement("#root");

const googleClientId = env.GOOGLE_CLIENT_ID!;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <HelmetProvider>
        <Helmet>
          <link
            rel="preconnect"
            href="https://accounts.google.com"
            crossOrigin="anonymous"
          />
        </Helmet>
        <StoreProvider>
          <BetaNotificationProvider>
            <HelmetProvider>
              <AppInitializer>
                <RouterProvider router={router} />
              </AppInitializer>
            </HelmetProvider>
          </BetaNotificationProvider>
        </StoreProvider>
      </HelmetProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
