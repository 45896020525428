import React, { useEffect } from "react";

import { format } from "date-fns";
import { Input, Button } from "src/components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import Carousel from "react-multi-carousel";
import "./index.css";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

function UpcomingClassesMobile() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();
  const { t } = useTranslation();

  const responsive = {
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 100,
    },
  };

  const user: any = userStore.user;
  const course: any = courseStore.courseDetails;
  const sessions: any[] = courseStore.searchedCourses;
  const courseId = course?.id;

  const [isButtonDisabled, setIsButtonDisabled] =
    React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [price, setPrice] = React.useState<number>(0);
  const [discount, setDiscount] = React.useState<number>(0);
  const [activeSessionId, setActiveSessionId] = React.useState<string>("");
  const [newSessionDiscount, setNewSessionDiscount] = React.useState<number>(0);
  const [activeSession, setActiveSession] = React.useState<any>(null);

  useEffect(() => {
    if (user?.email) {
      setEmail(user?.email);
    }
    if (sessions.length) {
      setPrice(sessions[0]?.price || 0);
      setDiscount(sessions[0]?.discount || 0);
      setNewSessionDiscount(sessions[0]?.newSessionDiscount || 0);
      setActiveSessionId(sessions[0]?.id);
      setActiveSession(sessions[0]);
    }
  }, [courseId]);


  function parseLocation(address: string): string {
    if (!address) return "";
    const parts = address
      .split(",")
      .map((part) => part.trim())
      .filter(Boolean);
    
    return parts.slice(-3).join(", ");
  }

  const formatDate = (date: string | undefined) => {
    if (!date) return "";
    return format(new Date(date), "dd MMM").toUpperCase();
  };

  function getLocalDateRangeFromSlots(schedule?: string[]) {
    if (!schedule || !schedule.length) return "";

    const localSlots = schedule.map((utcStr) => new Date(utcStr));

    const earliestMs = Math.min(...localSlots.map((d) => d.getTime()));
    const latestMs = Math.max(...localSlots.map((d) => d.getTime()));

    const earliestLocal = new Date(earliestMs);
    const latestLocal = new Date(latestMs);

    const startDay = format(earliestLocal, "dd MMM").toUpperCase();
    const endDay = format(latestLocal, "dd MMM").toUpperCase();

    return startDay === endDay ? startDay : `${startDay} - ${endDay}`;
  }

  const getDates = (start: string | undefined, end: string | undefined) => {
    const startDate = formatDate(start);
    const endDate = formatDate(end);
    if (startDate === endDate) {
      return <span>{startDate}</span>;
    }
    return (
      <>
        <span>{startDate}</span>
        <img src="/images/icons/arrow-right-black.svg" />
        <span>{endDate}</span>
      </>
    );
  };

  const redirectToSession = (sessionId: any) => {
    if (sessionId) {
      navigate(`/class/${sessionId}`);
    }
  };

  const notifyMe = async () => {
    setIsButtonDisabled(true);
    await HubSpotsignupStore.notifyMeHubspot(email, course.title);
    setIsButtonDisabled(false);
  };

  return (
    <div
      className="px-5 pt-3 rounded-tl-[36px] rounded-tr-[36px] text-primary-purple-850 bg-primary-gray-50"
      style={{ boxShadow: "0px 0px 20px -2px #00000024" }}
    >
      <div className="relative flex flex-col gap-4">
        <div className="flex justify-center items-center gap-2">
          <span className="body-small">Upcoming Classes</span>
          <img src="/images/icons/point.svg" />
          <div className="flex items-center gap-1">
            {newSessionDiscount === 0 && (
              <span
                className={`body-medium-bold ${
                  discount ? "line-through opacity-60" : ""
                }`}
              >
                {formatCurrency(price, userStore.currency)}
              </span>
            )}
            {newSessionDiscount !== 0 && (
              <>
                <span className="body-medium-bold">
                  {formatCurrency(
                    Math.max(
                      0,
                      Math.round((price * (100 - newSessionDiscount)) / 100)
                    ),
                    userStore.currency
                  )}
                </span>
                <span className="body-small-bold text-primary-paradise-pink-50 bg-primary-paradise-pink-500 rounded-full px-2 py-0.5">
                  {newSessionDiscount}% OFF
                </span>
              </>
            )}
          </div>
        </div>

        {sessions.length > 0 ? (
          <div className="flex flex-col gap-4 pb-5">
            <div className="blur-sides">
              <Carousel
                responsive={responsive}
                arrows={false}
                partialVisible={true}
                itemClass="px-2"
                containerClass="px-12"
                afterChange={(previousSlide, { currentSlide }) => {
                  const activeSession = sessions[currentSlide];
                  if (activeSession) {
                    setPrice(activeSession?.price || 0);
                    setDiscount(activeSession?.discount || 0);
                    setActiveSessionId(activeSession.id);
                    setActiveSession(activeSession);
                    setNewSessionDiscount(
                      activeSession.newSessionDiscount || 0
                    );
                  }
                }}
              >
                {sessions.map((session) => (
                  <div
                    key={session?.id}
                    className={`flex items-center justify-between p-5 h-full bg-primary-purple-50 border border-primary-purple-200 rounded-2xl cursor-pointer group ${
                      session?.id === activeSessionId
                        ? "border-primary-purple-300"
                        : ""
                    }`}
                    onClick={() => redirectToSession(session?.id)}
                  >
                    <div>
                      <div className="flex items-center gap-2.5">
                        <div className="flex items-center gap-1.5">
                          <img src="/images/icons/location-purple.svg" />
                          <span className="caption-text">
                            {session?.isOnline
                              ? "Online"
                              : parseLocation(
                                session?.sessionBooking?.[0]?.bookingRoom?.address?.name || ""
                              )}
                          </span>
                        </div>
                      </div>
                      <div className="body-medium-bold flex items-center gap-2">
                        {getLocalDateRangeFromSlots(
                          session?.scheduleData?.schedule
                        )}
                      </div>
                    </div>
                    <ArrowRight className="size-[18px] " />
                  </div>
                ))}
                <div></div>
              </Carousel>
            </div>
            {/* <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-primary-purple-50 to-transparent" /> */}
          </div>
        ) : (
          <div className="flex flex-col gap-4 pb-10">
            <div className="flex flex-col gap-2 w-full">
              <div className="caption-text">
                Get notified about Upcoming Classes
              </div>
              <div className="w-full">
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Your Email Address"
                />
              </div>
            </div>
            <Button
              type="primary_dark"
              text="Notify me"
              onClick={notifyMe}
              isDisabled={isButtonDisabled}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(UpcomingClassesMobile);
