import { observer } from "mobx-react";

import styles from "./form.module.css";

const PaymentMethods = observer(
  ({
    paymentMethod,
    setPaymentMethod,
  }: {
    paymentMethod: "PostWire" | "Onsite" | "KonnectPayment" | "StripePayment";
    setPaymentMethod: React.Dispatch<
      React.SetStateAction<
        "PostWire" | "Onsite" | "KonnectPayment" | "StripePayment"
      >
    >;
  }) => {
    return (
      <div className={styles.payment_methods}>
        <h4>Payment Methods</h4>

        <div className={styles.payment_methods_container}>
          <div
            className={styles.payment_method}
            onClick={() => setPaymentMethod("Onsite")}
          >
            <div className={styles.payment_method_name}>
              <input
                id="Onsite"
                type="radio"
                name="payment_method"
                value={paymentMethod}
                checked={paymentMethod === "Onsite"}
              />
              <label htmlFor="Onsite">Onsite</label>
            </div>
            <img src="/images/icons/payment-onsite.svg" alt="Onsite Payment" />
          </div>

          <div
            className={styles.payment_method}
            onClick={() => setPaymentMethod("PostWire")}
          >
            <div className={styles.payment_method_name}>
              <input
                id="PostWire"
                type="radio"
                name="payment_method"
                checked={paymentMethod === "PostWire"}
              />
              <label htmlFor="PostWire">Wiring</label>
            </div>
            <img src="/images/icons/payment-post.svg" alt="Wiring Payment" />
          </div>

          <div
            className={styles.payment_method}
            onClick={() => setPaymentMethod("KonnectPayment")}
          >
            <div className={styles.payment_method_name}>
              <input
                id="KonnectPayment"
                type="radio"
                name="payment_method"
                checked={paymentMethod === "KonnectPayment"}
              />
              <label htmlFor="KonnectPayment">Konnect</label>
            </div>
            <img
              src="/images/konnect-logo.png"
              alt="Konnect Payment"
              height="90rem"
              width="90rem"
            />
          </div>
        </div>

        <div className={styles.payment_methods_security}>
          <img src="/images/icons/lock.svg" width={28} height={28} alt="lock" />
          <span>
            We protect your payment information using encryption to provide
            bank-level security.
          </span>
        </div>
      </div>
    );
  }
);

export default PaymentMethods;
