import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../store";
import { formatCurrency } from "../../../../utils/formatCurrency";
import env from "../../../../config/env";
import { toast } from "react-toastify";
import { ReactComponent as ShareIcon } from "./share.svg";
import { SharePopup } from "src/components/shared";

interface IProps {
  type: "course" | "class";
  onInstructorClick?: () => void;
}

function ClassHeader({ type, onInstructorClick }: IProps) {
  const { courseStore, userStore } = useStore();

  const navigate = useNavigate();
  const [isAddedToFavorites, setIsAddedToFavorites] = useState<boolean>(false);
  const [isWishListClicked, setIsWishListClicked] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);

  const course: any = courseStore.classDetails;
  console.log("course", course);

  const image =
    type === "class" ? course?.SessionImage?.name : course?.image?.name;

  useEffect(() => {
    if (userStore.user) {
      const user = userStore.user;
      const userFavoritesList: any = [];
      if (user.favoriteCourse && user.favoriteCourse.length > 0) {
        userFavoritesList.push(...user.favoriteCourse);
      }
      if (user.favoriteSession && user.favoriteSession.length > 0) {
        userFavoritesList.push(...user.favoriteSession);
      }
      const userFavoritesSessionIds = userFavoritesList.map(
        (userFavorite: any) => {
          return userFavorite.sessionId
            ? userFavorite.sessionId.toString()
            : userFavorite.courseId.toString();
        }
      );

      if (userFavoritesSessionIds && userFavoritesSessionIds.length > 0) {
        if (userFavoritesSessionIds.includes(course?.id)) {
          setIsAddedToFavorites(true);
        }
      }
    }
  }, [userStore.user, course]);
  const [showBubble, setShowBubble] = useState<boolean>(false);
  const [bubbleClicked, setBubbleClicked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasDiscount = course?.newSessionDiscount > 0;
  const discountPrice = hasDiscount
    ? course?.price * (1 - course?.newSessionDiscount / 100)
    : course?.price;

  const addToFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      let response: any;
      type === "class"
        ? (response = await courseStore.addSessionToFavorites(
            course?.id,
            userStore.user.id
          ))
        : (response = await courseStore.addCourseToFavorites(
            course?.id,
            userStore.user.id
          ));
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(true);
        toast.success(
          `You’ve successfully added ${course.title} to your Wishlist.`
        );
        setShowBubble(false);
        setIsWishListClicked(false);
      });
    } else {
      setIsWishListClicked(false);
      setIsOpen(true);
    }
  };

  const deleteFromFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      const favoriteObject =
        type === "class"
          ? userStore.user.favoriteSession.find(
              (favoriteSession: any) =>
                favoriteSession.sessionId.toString() === course.id.toString()
            )
          : userStore.user.favoriteCourse.find(
              (favoriteCourse: any) =>
                favoriteCourse.courseId.toString() === course.id.toString()
            );
      type === "class"
        ? await courseStore.deleteSessionFromFavorites(favoriteObject.id)
        : await courseStore.deleteCourseFromFavorites(favoriteObject.id);
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(false);
        setIsWishListClicked(false);
        if (course.length === 0) {
          setShowBubble(true);
          setBubbleClicked(false);
        }
      });
    } else {
      setIsWishListClicked(false);
      setIsOpen(true);
    }
  };

  return (
    // class hearder for desktop screens
    <>
      <div className="w-full hidden md:flex justify-between items-center bg-[#FDFCFF] border-[1px] border-[#E2D3FF] rounded-[26px] pl-[12px] pr-[34px] py-[12px]">
        {/* class details section */}
        <div className="flex justify-start items-start space-x-[26px]">
          {/* class image */}
          <div
            className="w-[170px] h-[100px] min-w-[170px] min-h-[100px] hover:cursor-pointer"
            onClick={() => {
              navigate(`/course/${course?.courseId}`);
            }}
          >
            <img
              alt={course?.title}
              src={
                image
                  ? `${env.IMAGE_API}${image}`
                  : "/images/course-default.png"
              }
              className="w-full h-full rounded-[12px] object-cover"
            />
          </div>
          {/* class title & creator name & pricing */}
          <div className="flex flex-col justify-start items-start space-y-[4px] ">
            {/* class title */}
            <span
              className="text-[#370513] text-[22px] font-inter font-semibold leading-[32px] hover:cursor-pointer"
              onClick={() => {
                navigate(`/course/${course?.courseId}`);
              }}
            >
              {course?.title}
            </span>
            {/* class tutor name */}
            <span
              className="text-[#1B0209] text-[14px] font-inter font-normal leading-[24px] hover:cursor-pointer "
              onClick={onInstructorClick}
            >
              {course?.tutors?.fullName}
            </span>
            {/* class pricing */}
            <div className="flex justify-start items-center space-x-[4px] ">
              {hasDiscount && course?.price ? (
                <>
                  <span className="text-[#0F0220] font-inter font-normal text-[16px] leading-[24px] line-through">
                    {formatCurrency(course?.price, userStore.currency)}
                  </span>
                  <span className="text-[#7947D1] font-inter font-bold text-[16px] leading-[24px] ">
                    {formatCurrency(discountPrice, userStore.currency)}
                  </span>
                </>
              ) : (
                <span className="text-[#7947D1] font-inter font-bold text-[16px] leading-[24px] ">
                  {course?.price
                    ? formatCurrency(course?.price, userStore.currency)
                    : "FREE"}
                </span>
              )}

              {hasDiscount && (
                <span className="w-auto h-[24px] rounded-[99px] px-[10px] bg-[#E83C70] text-[#FFFAFB] font-inter font-semibold text-[12px] leading-[24px] ">
                  {course?.newSessionDiscount}% OFF
                </span>
              )}
            </div>
          </div>
        </div>
        {/* class copy link & add to wishlist button */}
        <div className="flex gap-1.5">
          <div
            className="group flex items-center gap-2 p-4 cursor-pointer hover:text-primary-paradise-pink-600 hover:bg-primary-paradise-pink-100 active:bg-primary-paradise-pink-200 rounded-xl transition-all duration-200"
            onClick={() => setIsShareOpen(true)}
          >
            <ShareIcon />
          </div>

          {localStorage.getItem("token") && (
            <div className="flex justify-start items-center space-x-[14px]">
              {/* copy link button */}
              {/* <button
              type="button"
              className="group h-[52px] w-[52px] flex justify-center items-center border-none rounded-[12px] transition-all duration-100
              hover:bg-[#FFECF2]
               active:bg-[#FFC9D9]"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="transition-all duration-100 fill-none stroke-[#2A2A2A] stroke-[1.75]
                  group-hover:stroke-[#E83C70]
                  group-active:stroke-[#E83C70]"
              >
                <path
                  d="M20 13V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V13"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 15V3M12 3L8.5 6.5M12 3L15.5 6.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button> */}
              {/* add to wishlist button */}
              {isAddedToFavorites ? (
                <button
                  type="button"
                  className="h-[52px] w-[52px] flex justify-center items-center border-2 border-transparent rounded-[12px] bg-[#E83C70] transition-all duration-100 hover:border-[#CA325C] hover:bg-[#CA325C] active:border-[3px] active:border-[#811D39] active:bg-[#811D39]"
                  onClick={() => deleteFromFavorites()}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    className="transition-all duration-100 fill-white stroke-white stroke-[1.5]"
                  >
                    <path d="M18.3333 7.38518C18.3333 8.67395 17.8385 9.91177 16.9548 10.8274C14.9207 12.9359 12.9478 15.1344 10.8377 17.1664C10.354 17.6254 9.58675 17.6086 9.12396 17.1289L3.04476 10.8274C1.20725 8.92267 1.20725 5.84769 3.04476 3.94298C4.90033 2.01954 7.92324 2.01954 9.77881 3.94298L9.99977 4.17201L10.2206 3.9431C11.1103 3.02042 12.322 2.5 13.5877 2.5C14.8535 2.5 16.0651 3.02037 16.9548 3.94298C17.8385 4.8587 18.3333 6.09647 18.3333 7.38518Z" />
                  </svg>
                </button>
              ) : (
                <button
                  type="button"
                  className="group h-[52px] w-[52px] flex justify-center items-center border-2 border-transparent rounded-[12px] transition-all duration-100 hover:border-[#E83C70] hover:bg-[#FFECF2] active:border-[3px] active:border-[#E83C70] active:bg-[#FFC9D9]"
                  onClick={() => addToFavorites()}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    className="transition-all duration-100 fill-none stroke-[#0A0003] stroke-[1.5] group-hover:fill-[#E83C70] group-hover:stroke-[1.5] group-hover:stroke-[#E83C70] group-active:fill-[#E83C70] group-active:stroke-[1.5] group-active:stroke-[#E83C70]"
                  >
                    <path d="M18.3333 7.38518C18.3333 8.67395 17.8385 9.91177 16.9548 10.8274C14.9207 12.9359 12.9478 15.1344 10.8377 17.1664C10.354 17.6254 9.58675 17.6086 9.12396 17.1289L3.04476 10.8274C1.20725 8.92267 1.20725 5.84769 3.04476 3.94298C4.90033 2.01954 7.92324 2.01954 9.77881 3.94298L9.99977 4.17201L10.2206 3.9431C11.1103 3.02042 12.322 2.5 13.5877 2.5C14.8535 2.5 16.0651 3.02037 16.9548 3.94298C17.8385 4.8587 18.3333 6.09647 18.3333 7.38518Z" />
                  </svg>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {/* class header for mobile screens */}
      <div className="md:hidden w-full h-[137px] flex flex-col justify-start items-start space-y-[6px] bg-[#FDFCFF] border-[1px] border-[#E2D3FF] rounded-[12px] p-[8px]">
        {/* class image & title */}
        <div className="w-full flex justify-start items-center space-x-[10px]">
          {/* class image */}
          <div
            className="w-[110px] h-[65px] min-w-[110px] min-h-[65px] hover:cursor-pointer"
            onClick={() => {
              navigate(`/course/${course?.courseId}`);
            }}
          >
            <img
              alt={course?.title}
              src={
                image
                  ? `${env.IMAGE_API}${image}`
                  : "/images/course-default.png"
              }
              className="min-w-full min-h-full rounded-[6px] object-cover"
            />
          </div>
          {/* class title */}
          <span
            className="text-[#370513] font-inter font-bold text-[16px] leading-[25px] hover:cursor-pointer "
            onClick={() => {
              navigate(`/course/${course?.courseId}`);
            }}
          >
            {course?.title}
          </span>
        </div>
        {/* class tutor & pricing & copy link & wishlist buttons */}
        <div className="w-full flex justify-between items-center">
          {/* class tutor & pricing */}
          <div className="flex flex-col justify-start items-start space-y-[2px]">
            {/* class tutor */}
            <span
              className="text-[#1B0209] font-inter font-medium text-[12px] leading-[24px] hover:cursor-pointer"
              onClick={onInstructorClick}
            >
              {course?.tutors?.fullName}
            </span>
            {/* class pricing */}
            <div className="flex justify-start items-center space-x-[4px] ">
              {hasDiscount && course?.price ? (
                <>
                  <span className="text-[#0F0220] font-inter font-normal text-[14px] leading-[24px] line-through">
                    {formatCurrency(course?.price, userStore.currency)}
                  </span>
                  <span className="text-[#7947D1] font-inter font-bold text-[14px] leading-[24px] ">
                    {formatCurrency(discountPrice, userStore.currency)}
                  </span>
                </>
              ) : (
                <span className="text-[#7947D1] font-inter font-bold text-[14px] leading-[24px] ">
                  {course?.price
                    ? formatCurrency(course?.price, userStore.currency)
                    : "FREE"}
                </span>
              )}

              {hasDiscount && (
                <span className="w-auto h-[24px] rounded-[99px] px-[10px] bg-[#E83C70] text-[#FFFAFB] font-inter font-semibold text-[12px] leading-[24px] ">
                  {course?.newSessionDiscount}% OFF
                </span>
              )}
            </div>
          </div>
          {/* class copy link & add to wishlist button */}
          <div className="flex gap-1.5">
            <div
              className="group flex items-center gap-2 p-4 cursor-pointer hover:text-primary-paradise-pink-600 hover:bg-primary-paradise-pink-100 active:bg-primary-paradise-pink-200 rounded-xl transition-all duration-200"
              onClick={() => setIsShareOpen(true)}
            >
              <ShareIcon />
            </div>

            {localStorage.getItem("token") && (
              <div className="flex justify-start items-center">
                {/* copy link button */}
                {/* <button
                type="button"
                className=" h-[52px] w-[52px] flex justify-center items-center"
              >
                <img src="/images/class/share.svg" alt="Copy link" />
              </button> */}
                {/* add to wishlist button */}
                <button
                  type="button"
                  className=" h-[52px] w-[52px] flex justify-center items-center"
                  onClick={() =>
                    isAddedToFavorites
                      ? deleteFromFavorites()
                      : addToFavorites()
                  }
                >
                  <img src="/images/class/heart.svg" alt="Add to wishlist" />
                </button>
              </div>
            )}
          </div>
        </div>

        {isShareOpen && (
          <SharePopup
            isOpen={isShareOpen}
            closePopup={() => setIsShareOpen(false)}
          />
        )}
      </div>
    </>
  );
}

export default observer(ClassHeader);
