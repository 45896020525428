import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../store";
import {
  Navbar,
  Loading,
  UploadImage,
  Input,
  Select as RSelect,
  Button,
} from "src/components/shared";
import Select from "react-select";
import { Switch } from "antd";
import { getAllSessionsAndCourses, getSessionDetails } from "src/store/session";
import Popup from "./Popup";
import ScheduleSelector from "react-schedule-selector";
import "../NewCourse/styles.css";
import ClassroomSelection from "./ClassroomSelection-new";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";

export default function NewSession() {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const { userStore, courseStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });

    setLoading(true);

    Promise.all([
      courseStore.getAllDomains(),
      courseStore.getAllLanguages(),
      getAllSessionsAndCourses(),
      getSessionDetails(id),
    ])
      .then(
        ([
          domainsResponse,
          languagesResponse,
          sessionsAndCoursesResponse,
          sessionDetailsResponse,
        ]) => {
          if (domainsResponse !== null) {
            const domains = domainsResponse.map((domain: any) => ({
              value: domain.id,
              label: domain.name,
            }));
            setDomains(domains);
            setAllDomains(domainsResponse);
          }

          if (languagesResponse?.status !== null) {
            setLanguagesFromDB(languagesResponse);
            console.log("Languages fetched from API:", languagesResponse);
          }

          if (sessionsAndCoursesResponse !== null) {
            setSessions(sessionsAndCoursesResponse.sessions);
            setCourses(sessionsAndCoursesResponse.courses);
          }

          if (sessionDetailsResponse !== null) {
            setSessionDetails(sessionDetailsResponse);
          }

          setLoading(false);
          setFirstUseEffect(false);
        }
      )
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const user = userStore.user;

  const [firstUseEffect, setFirstUseEffect] = useState<boolean>(true);

  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [onlineSessionLink, setOnlineSessionLink] = useState("");
  const [syllabus, setSyllabus] = useState<any>(null);
  const [start, setStartDate] = useState<any>(undefined);
  const [end, setEndDate] = useState<any>(undefined);
  const [numDays, setNumdays] = useState<any>(undefined);

  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [schedule, setSchedule] = useState("");
  const [minPlace, setMinPlace] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const [maxPlace, setMaxPlace] = useState("");
  const [newSessionDiscount, setNewSessionDiscount] = useState(0);
  const [TimeWindowForCancel, setTimeWindowForCancel] = useState("");
  const [price, setPrice] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [domains, setDomains] = useState([]);
  const [isOnline, setIsOnline] = useState<boolean>();
  const [language, setLanguage] = useState("English");
  const [languagesFromDB, setLanguagesFromDB] = useState<any[]>([]);
  const [sessions, setSessions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState<any>(null);
  const [sessionId, setSessionId] = useState<any>(null);
  const [scheduleData, setScheduleData] = useState<any>({
    schedule: [],
    hourlyChunks: 1,
    numDays: numDays || 7,
    minTime: 8,
    maxTime: 18,
    startDate: start || new Date().toISOString(),
    endDate: end || new Date().toISOString(),
  });
  const localScheduleDates =
    scheduleData.schedule?.map((slot: any) => new Date(slot)) ?? [];
  if (localScheduleDates.length) {
    const earliestMs = Math.min(
      ...localScheduleDates.map((d: any) => d.getTime())
    );
    const latestMs = Math.max(
      ...localScheduleDates.map((d: any) => d.getTime())
    );

    const earliestDate: any = new Date(earliestMs);
    earliestDate.setHours(0, 0, 0, 0);
    const latestDate: any = new Date(latestMs);
    latestDate.setHours(0, 0, 0, 0);
  }

  if (localScheduleDates.length > 0) {
    //
  }

  let earliestHour = 8;
  let latestHour = 18;
  if (localScheduleDates.length) {
    const localHours = localScheduleDates.map((d: any) => d.getHours());
    earliestHour = Math.min(...localHours);
    latestHour = Math.max(...localHours);
  }

  let finalMinTime = scheduleData.minTime;
  let finalMaxTime = scheduleData.maxTime;

  finalMinTime = Math.min(finalMinTime, earliestHour);
  finalMaxTime = Math.max(finalMaxTime, latestHour + 1);

  finalMinTime = Math.max(0, finalMinTime);
  finalMaxTime = Math.min(24, finalMaxTime);

  const [classroomSelection, setClassroomSelection] = useState(false);
  const [newSessionData, setNewSessionData] = useState<any>();
  const [inCourse, setInCourse] = useState(false);
  const [allDomains, setAllDomains] = useState<any>([]);
  const [sessionDetails, setSessionDetails] = useState<any>();
  const [currency, setCurrency] = useState<string>("USD");
  const [syllabusEditorState, setSyllabusEditorState] = useState(
    EditorState.createEmpty()
  );
  const [courseContents, setCourseContents] = useState([
    { step: 1, title: "", content: "", expanded: true },
  ]);
  const [isOldSyllabus, setIsOldSyllabus] = useState(false);

  const courseLevels = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Advanced" },
  ];

  const currencyOptions = [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "CAD", label: "CAD" },
    { value: "TND", label: "TND" },
  ];

  const languageOptions = [
    { value: "English", label: "English" },
    { value: "French", label: "French" },
  ];

  const calculateNumDays = useCallback(() => {
    if (!start || !end) return;
    const startDate = moment(start);
    const endDate = moment(end);

    if (!startDate.isValid() || !endDate.isValid()) {
      console.error("Invalid start or end date");
      return;
    }

    const numDays = endDate.diff(startDate, "days") + 1;

    setNumdays(numDays);

    if (numDays !== scheduleData.numDays) {
      setScheduleData((prevData: any) => ({
        ...prevData,
        numDays: numDays,
      }));
    }
  }, [start, end]);

  useEffect(() => {
    calculateNumDays();
    console.log(numDays);
  }, [calculateNumDays]);

  useEffect(() => {
    const session: any =
      type === "edit"
        ? sessionDetails
        : sessions.find((session: any) => session.id === id);
    const course: any = courses.find((course: any) => course.id === id);

    if (type === "new" && (id === undefined || (!session && !course))) {
      setIsPopupOpen(true);
    } else if ((type === "edit" || type === "new") && id) {
      setIsPopupOpen(false);

      if (session) {
        setCourseId(session?.courseId);
        setIsOnline(session?.isOnline);
        setSessionId(session?.id);
        setImage(session?.SessionImage);
        setTitle(session?.title);
        setDescription(session?.description);
        setStartDate(
          session?.start
            ? new Date(session?.start).toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0]
        );
        setEndDate(
          session?.end
            ? new Date(session?.end).toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0]
        );

        setStartTime(moment(session?.startTime).format("HH:mm"));
        setEndTime(moment(session?.endDate).format("HH:mm"));
        setMinPlace(session?.minPlace);
        setTotalDuration(session?.duration);
        setMaxPlace(session?.maxPlace);
        if (type === "edit") {
          setNewSessionDiscount(session?.newSessionDiscount || 0);
        }
        setPrice(session?.price);
        setDifficulty(session?.difficulty);
        setScheduleData(session?.scheduleData);
        setIsOldSyllabus(session?.isOldSyllabus || false);
        if (session?.isOldSyllabus) {
          setSyllabus(session?.syllabus ? session?.syllabus : "");
          setSyllabusEditorState(
            session?.syllabus
              ? EditorState.createWithContent(convertFromRaw(session?.syllabus))
              : EditorState.createEmpty()
          );
        } else {
          setSyllabus("");
          setSyllabusEditorState(EditorState.createEmpty());
          if (session?.syllabus && session?.syllabus.length > 0) {
            const newCourseContents = session?.syllabus.map(
              (content: any, index: number) => {
                return {
                  step: index + 1,
                  title: content.title,
                  content: content.content,
                  expanded: true,
                };
              }
            );

            setCourseContents(newCourseContents);
          } else {
            setCourseContents([
              { step: 1, title: "", content: "", expanded: true },
            ]);
          }
        }
        setTimeWindowForCancel(session?.TimeWindowForCancel);
        setSchedule(session?.schedule);
        setLanguage(session?.language || "English");
        setCurrency(session?.currency || "USD");
        setSelectedDomains(
          session?.domains?.map((domain: any) => ({
            value: domain,
            label: domain,
          }))
        );
        if (session?.isOnline) {
          setOnlineSessionLink(session?.onlineSessionLink);
        }
      } else if (course) {
        setIsOnline(false);
        setInCourse(true);
        setCourseId(course.id);
        setImage(course.image);
        setTitle(course.title);
        setDescription(course.description);
        setIsOldSyllabus(course.isOldSyllabus || false);
        if (course?.isOldSyllabus) {
          setSyllabus(course?.syllabus ? course?.syllabus : "");
          setSyllabusEditorState(
            course?.syllabus
              ? EditorState.createWithContent(convertFromRaw(course?.syllabus))
              : EditorState.createEmpty()
          );
        } else {
          setSyllabus("");
          setSyllabusEditorState(EditorState.createEmpty());
          if (course?.syllabus && course?.syllabus.length > 0) {
            const newCourseContents = course?.syllabus.map(
              (content: any, index: number) => {
                return {
                  step: index + 1,
                  title: content.title,
                  content: content.content,
                  expanded: true,
                };
              }
            );

            setCourseContents(newCourseContents);
          } else {
            setCourseContents([
              { step: 1, title: "", content: "", expanded: true },
            ]);
          }
        }

        setPrice(course.price);
        setDifficulty(course.difficulty);
        setSelectedDomains(
          course.domains.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }))
        );
        setSelectedSkills(
          course?.prerequisite.map((skill: any) => ({
            value: skill,
            label: skill,
          }))
        );
        setLanguage(course.language || "English");

        // init start date and end date
        const currentDate = new Date();
        const startDate = currentDate.toISOString().split("T")[0];

        const endDateObj = new Date(currentDate);
        endDateObj.setDate(currentDate.getDate() + 7);
        const endDate = endDateObj.toISOString().split("T")[0];
        setStartDate(startDate);
        setEndDate(endDate);
      }
    } else {
      navigate("/teaching");
    }
  }, [id, firstUseEffect]);

  const links = [{ path: "/myclasses", label: "My Classes" }];

  const handleNext = async () => {
    console.log("Language state at start of handleNext:", language);
    console.log("Languages from DB:", languagesFromDB);

    const domains = inCourse
      ? selectedDomains?.map((domain: any) =>
          allDomains.find((dom: any) => dom.id === domain.value)
        )
      : selectedDomains?.map((domain: any) => domain.value);

    const sortedSchedule = scheduleData.schedule.sort(
      (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()
    );

    const session: any = {
      courseId,
      SessionImage: image,
      title,
      description,
      start: start ? start : new Date().toISOString(),
      startTime:
        sortedSchedule.length > 0
          ? sortedSchedule[0]
          : new Date().toISOString(),
      end: end ? end : new Date().toISOString(),

      endTime:
        sortedSchedule.length > 0
          ? sortedSchedule[sortedSchedule.length - 1]
          : new Date().toISOString(),
      minPlace: parseInt(minPlace) || 0,
      maxPlace: parseInt(maxPlace) || 0,
      duration: parseInt(totalDuration) || 0,
      newSessionDiscount: newSessionDiscount || 0,
      price,
      difficulty,
      TimeWindowForCancel: parseInt(TimeWindowForCancel) || 0,
      isOnline,
      teacherId: user.id,
      scheduleData,
      currency,
      // planning: { blocks: planning, timeZone: timeZone },
    };
    if (schedule) session.schedule = schedule;

    if (isOnline) session.onlineSessionLink = onlineSessionLink;

    if (courseId) {
      session.courseId = courseId;
    }
    if (sessionId) {
      session.sessionId = sessionId;
    }

    if (isOldSyllabus) {
      session.syllabus =
        syllabus.length > 0
          ? JSON.parse(syllabus)
          : { blocks: [], entityMap: {} };

      session.isOldSyllabus = true;
    } else {
      session.syllabus = courseContents.map((content: any) => {
        return {
          title: content.title,
          content: content.content,
        };
      });
      session.isOldSyllabus = false;
    }

    if (type === "new") {
      setNewSessionData({
        session,
        domains,
      });
    } else {
      setNewSessionData(session);
    }

    if (image?.lastModified) {
      await userStore.uploadPicture(image).then((response) => {
        session.SessionImage = response;
      });
    }

    // Language mapping logic
    if (language && language !== "") {
      if (language === "French") {
        session.languageId = languagesFromDB.find(
          (lang: any) => lang.name === "Français"
        ).id;
      } else {
        session.languageId = languagesFromDB.find(
          (lang: any) => lang.name === "Anglais"
        ).id;
      }
    } else {
      session.languageId = languagesFromDB.find(
        (lang: any) => lang.name === "Anglais"
      ).id;
    }

    setClassroomSelection(true);
  };

  const handleSyllabusEditorChange = (newEditorState: EditorState) => {
    setSyllabusEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setSyllabus(JSON.stringify(rawContentState));
  };

  const onStartDateChange = (newStartDate: any) => {
    // let formattedNewStartDate = moment(newStartDate);

    // let formattedEndDate = end;

    // let newNumberOfDates = formattedEndDate.diff(formattedNewStartDate, "days");
    const startDate = moment(newStartDate);
    const endDate = moment(end);

    if (!startDate.isValid()) {
      console.error("Invalid start date");
      return;
    }

    if (endDate && startDate.isAfter(endDate)) {
      toast("Start date cannot be after end date");
      return;
    }

    setScheduleData({
      schedule: scheduleData.schedule,
      hourlyChunks: scheduleData.hourlyChunks,
      numDays: numDays,
      minTime: scheduleData.minTime,
      maxTime: scheduleData.maxTime,
      startDate: new Date(newStartDate),
      endDate: end,
    });

    console.log("scheduleData", {
      schedule: scheduleData.schedule,
      hourlyChunks: scheduleData.hourlyChunks,
      numDays: numDays,
      minTime: scheduleData.minTime,
      maxTime: scheduleData.maxTime,
      startDate: new Date(newStartDate),
      endDate: end,
    });

    calculateNumDays();

    setStartDate(newStartDate);
    setNumdays(numDays + 1);
  };

  const onEndDateChange = (newEndDate: any) => {
    const formattedNewEndDate = moment(newEndDate);
    const endDate = moment(newEndDate);
    const startDate = moment(start);

    if (!formattedNewEndDate.isValid()) {
      console.error("Invalid date selected");
      return;
    }

    if (startDate && endDate.isBefore(startDate.add(1))) {
      toast("End date cannot be before start date");
      return;
    }

    const endDatePlusOneDay = formattedNewEndDate;
    // const endDatePlusOneDay = formattedNewEndDate;

    const formattedStartDate = moment(start);
    const newNumberOfDates = endDatePlusOneDay.diff(formattedStartDate, "days");

    setScheduleData({
      schedule: scheduleData.schedule,
      hourlyChunks: scheduleData.hourlyChunks,
      numDays: newNumberOfDates <= 0 ? 1 : newNumberOfDates,
      minTime: scheduleData.minTime,
      maxTime: scheduleData.maxTime,
      startDate: start,
      endDate: endDatePlusOneDay,
    });

    setEndDate(newEndDate);

    console.log("scheduleData", {
      schedule: scheduleData.schedule,
      hourlyChunks: scheduleData.hourlyChunks,
      numDays: newNumberOfDates <= 0 ? 1 : newNumberOfDates,
      minTime: scheduleData.minTime,
      maxTime: scheduleData.maxTime,
      startDate: start,
      endDate: end,
    });
    calculateNumDays();
  };

  const handleScheduleChange = (newSchedule: any) => {
    const oldSchedule = scheduleData?.schedule || [];

    if (JSON.stringify(newSchedule) === JSON.stringify(oldSchedule)) {
      return;
    }

    const sortedSchedule = newSchedule
      .map((date: any) => moment(date).toISOString())
      .sort((a: any, b: any) => moment(a).diff(moment(b)));

    const startTime = sortedSchedule[0];
    const endTime = sortedSchedule[sortedSchedule.length - 1];

    setScheduleData({
      schedule: sortedSchedule,
      hourlyChunks: scheduleData.hourlyChunks,
      numDays: scheduleData.numDays,
      minTime: scheduleData.minTime,
      maxTime: scheduleData.maxTime,
      startDate: start,
      endDate: end,
    });
    setStartTime(startTime ? moment.utc(startTime).format("HH:mm") : startTime);
    setEndTime(endTime ? moment.utc(endTime).format("HH:mm") : endTime);
    console.log("Start", startTime);
    console.log("endTime", endTime);
  };

  const toggleExpandCollapse = (index: number) => {
    setCourseContents((prev) => {
      const updated = [...prev];
      updated[index].expanded = !updated[index].expanded;
      return updated;
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastContainer theme="dark" />

      <div className="session-popup">
        <Popup isOpen={isPopupOpen} sessions={sessions} courses={courses} />
      </div>
      <Navbar />
      {classroomSelection ? (
        <ClassroomSelection
          data={newSessionData}
          isOnline={isOnline}
          setIsOnline={() => setIsOnline(true)}
          setClassroomSelection={setClassroomSelection}
        />
      ) : (
        <div className="new-session">
          <div className="links">
            {links.map((link, index) => (
              <div className="link" key={index}>
                <Link to={link.path} className="label">
                  {link.label}
                </Link>
                <div className="arrow">&gt;</div>
              </div>
            ))}
            <div className="active label">
              {type === "new" ? "Add new class" : "Edit class"}
            </div>
          </div>

          <h1>{type === "new" ? "Add new class" : "Edit class"}</h1>

          <div className="field">
            <div className="input-field">
              <div className="switch-container">
                <Switch
                  defaultChecked={isOnline}
                  onChange={() => setIsOnline(!isOnline)}
                  style={{ backgroundColor: isOnline ? "#CA2D5D" : "" }}
                />
                <label htmlFor="link">{isOnline ? "Online" : "Offline"}</label>
              </div>
            </div>
          </div>

          {isOnline && (
            <div className="field">
              <label htmlFor="link">Link to class</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    label="Copy link to class"
                    fullWidth
                    value={onlineSessionLink}
                    onChange={(e) => setOnlineSessionLink(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="w-full grid grid-cols-3 gap-8">
            {inCourse && (
              <div className="field">
                <label htmlFor="requiredSkill">Required skills</label>
                <div className="input-field">
                  <div className="input">
                    <Select
                      isMulti
                      options={skills}
                      isDisabled={type === "new"}
                      value={selectedSkills}
                      onChange={(e: any) => setSelectedSkills(e)}
                      placeholder="Select required skill(s)"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="w-full grid grid-cols-2 gap-8">
            <div className="">
              <label htmlFor="picture">Course banner</label>
              <div className="input-field">
                <UploadImage image={image} setImage={setImage} width="100%" />
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <label htmlFor="title">Title</label>
                <div className="input-field">
                  <div className="input">
                    <Input
                      type="text"
                      label="Title"
                      readOnly
                      fullWidth
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label htmlFor="description">Description</label>
                <div className="input-field">
                  <textarea
                    className="bio resize-none"
                    id="description"
                    placeholder="Description"
                    value={description}
                    readOnly
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col justify-start items-start gap-8">
            <div className="w-full">
              <label htmlFor="syllabus">Syllabus</label>
              {isOldSyllabus && (
                <div className="input-field">
                  <div className="input_container">
                    <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                      <Editor
                        editorState={syllabusEditorState}
                        onEditorStateChange={handleSyllabusEditorChange}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}
              {!isOldSyllabus && (
                <div className="field">
                  {courseContents.map((content, index) => (
                    <div key={index} className="content-item mt-2">
                      <div className="w-full flex justify-start items-center space-x-2">
                        <button
                          type="button"
                          onClick={() => toggleExpandCollapse(index)}
                          style={{
                            color: "var(--Primary-ParadisePink-500)",
                            border: "1px solid",
                            borderColor: "var(--Primary-Gray-200)",
                            borderRadius: "12px",
                            width: "50px",
                            height: "50px",
                            fontSize: "40px",
                          }}
                          className="flex justify-center items-center"
                        >
                          {content.expanded ? "-" : "+"}
                        </button>
                        <input
                          type="text"
                          name="content title"
                          placeholder="Content Title"
                          value={content.title}
                          style={{
                            padding: "12px 16px",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "var(--black)",
                            outline: "1px solid var(--Primary-Gray-200)",
                            borderRadius: "12px",
                            width: "100%",
                          }}
                          disabled={true}
                        />
                      </div>
                      {content.expanded && (
                        <div className="input-field">
                          <div
                            className="bio"
                            dangerouslySetInnerHTML={{
                              __html: content.content,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="field">
              <label htmlFor="domain">Domain</label>
              <div className="input-field">
                <div className="input">
                  <Select
                    isMulti
                    options={domains}
                    isDisabled={type === "new"}
                    value={selectedDomains}
                    closeMenuOnSelect={false}
                    onChange={(e: any) => setSelectedDomains(e)}
                    placeholder="Select domain(s)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full grid grid-cols-4 gap-x-8 gap-y-4">
            <div className="field">
              <label htmlFor="price">Price per person</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="language">Language</label>
              <div className="input-field">
                <div className="input">
                  <RSelect
                    label="Language"
                    fullWidth
                    options={languageOptions}
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="currency">Currency</label>
              <div className="input-field">
                <div className="input">
                  <RSelect
                    label="Currency"
                    fullWidth
                    options={currencyOptions}
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="currency">Course level</label>
              <div className="input-field">
                <div className="input">
                  <RSelect
                    options={courseLevels}
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                    label="Select Course Level"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="maxPlace">Maximum Participants Number</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    fullWidth
                    min={1}
                    value={maxPlace}
                    onChange={(e) => setMaxPlace(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="discount">Session Discount</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="number"
                    min={0}
                    max={100}
                    fullWidth
                    value={newSessionDiscount.toString()}
                    onChange={(e) => setNewSessionDiscount(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field">
            <label>Timetable</label>
            <div className="flex justify-center items-center gap-3">
              <div className="flex flex-col items-center">
                <label>Start date</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-30"
                  type="date"
                  value={start}
                  onChange={(e) => onStartDateChange(e.target.value)}
                />
              </div>

              <div className="flex flex-col items-center">
                <label>End date</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-30"
                  type="date"
                  value={end}
                  onChange={(e) => onEndDateChange(e.target.value)}
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Num days</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={numDays}
                  onChange={(e) => {
                    const val = e.target.value;

                    if (val === "") {
                      setNumdays("");
                      return;
                    }
                    const parsed = parseInt(val, 10);

                    if (!isNaN(parsed) && parsed >= 1 && parsed <= 180) {
                      setNumdays(parsed);
                    }
                  }}
                  onBlur={() => {
                    let val = numDays;

                    if (val === "") {
                      val = 1;
                    }

                    if (val < 1) val = 1;
                    if (val > 180) val = 180;

                    setNumdays(val);
                    setScheduleData((prevData: any) => ({
                      ...prevData,
                      numDays: val,
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Min time</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={scheduleData.minTime}
                  onChange={(e) => {
                    const val = e.target.value;

                    if (val === "") {
                      setScheduleData({ ...scheduleData, minTime: "" });
                      return;
                    }

                    // Check if the value is strictly numeric and does not contain '-'
                    // Using a regex to ensure only digits are allowed
                    if (!/^\d+$/.test(val)) {
                      return;
                    }

                    const parsed = parseInt(val, 10);

                    if (!isNaN(parsed) && parsed >= 1 && parsed <= 24) {
                      setScheduleData({ ...scheduleData, minTime: parsed });
                    }
                  }}
                  onBlur={() => {
                    let val = scheduleData.minTime;

                    if (val === "") {
                      val = 1;
                    }

                    if (typeof val !== "number") {
                      val = parseInt(val, 10);
                    }

                    if (isNaN(val) || val < 1) val = 1;
                    if (val > 24) val = 24;

                    setScheduleData({ ...scheduleData, minTime: val });
                  }}
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Max time</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={scheduleData.maxTime}
                  onChange={(e) => {
                    const val = e.target.value;

                    if (val === "") {
                      setScheduleData({ ...scheduleData, maxTime: "" });
                      return;
                    }

                    // Check that value is digits only (no '-' allowed)
                    if (!/^\d+$/.test(val)) {
                      return;
                    }

                    const parsed = parseInt(val, 10);

                    if (!isNaN(parsed) && parsed >= 1 && parsed <= 24) {
                      setScheduleData({ ...scheduleData, maxTime: parsed });
                    }
                  }}
                  onBlur={() => {
                    let val = scheduleData.maxTime;

                    if (val === "") {
                      val = 1;
                    }

                    if (typeof val !== "number") {
                      val = parseInt(val, 10);
                    }

                    if (isNaN(val) || val < 1) val = 1;
                    if (val > 24) val = 24;

                    setScheduleData({ ...scheduleData, maxTime: val });
                  }}
                />
              </div>
              <div className="flex flex-col items-center">
                <label>Hourly chunks</label>
                <input
                  className="border border-input rounded-lg p-1.5 w-20"
                  type="number"
                  value={scheduleData.hourlyChunks}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val === "") {
                      setScheduleData({ ...scheduleData, hourlyChunks: "" });
                      return;
                    }
                    const parsed = parseInt(val, 10);
                    if (!isNaN(parsed) && parsed > 0) {
                      setScheduleData({ ...scheduleData, hourlyChunks: val });
                    }
                  }}
                  onBlur={() => {
                    let val = scheduleData.hourlyChunks;
                    if (val === "") {
                      val = "1";
                    }
                    const parsed = parseInt(val, 10);
                    if (isNaN(parsed) || parsed < 1) {
                      val = "1";
                    }
                    setScheduleData({ ...scheduleData, hourlyChunks: val });
                  }}
                />
              </div>
            </div>
            <div className="input-field">
              <div className="input">
                <ScheduleSelector
                  selection={localScheduleDates}
                  onChange={handleScheduleChange}
                  startDate={start?.split("T")[0]}
                  numDays={numDays || 7}
                  minTime={finalMinTime || 1}
                  maxTime={finalMaxTime || 24}
                  hourlyChunks={scheduleData.hourlyChunks}
                  dateFormat="ddd M/D"
                  timeFormat="h:mma"
                  selectedColor="#CA2D5D"
                />
                {/* <SchedulePlanner
                  readOnly={false}
                  setPlanning={setPlanning}
                  setSelectedTimeZone={setTimeZone}
                ></SchedulePlanner> */}
              </div>
            </div>
          </div>

          <div className="buttons">
            <Button
              text="Cancel"
              type="primary"
              customColor="#373737"
              width="400px"
              preventDefault={true}
              onClick={() => navigate(-1)}
            />
            <Button
              text="Next"
              type="primary"
              width="400px"
              preventDefault={true}
              onClick={handleNext}
              isDisabled={newSessionDiscount < 0 || newSessionDiscount > 100}
            />
          </div>
        </div>
      )}
    </>
  );
}
