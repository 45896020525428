import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "src/components/shared";
import Container from "src/components/shared/Container";
import { useTranslation } from "react-i18next";

function ResetPasswordSuccess() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const RedirectToLogin = () => {
    const path = `/login`;
    navigate(path);
  };

  return (
    <>
      <Navbar />
      <ToastContainer theme="dark" />
      <Container>
        <div className="flex items-center justify-center py-2 px-4">
          <div className="bg-white rounded-xl border border-gray-200 w-full max-w-[482px] px-6 py-8 sm:px-8 md:px-10 lg:px-12 text-center ">
            <svg
              width="120"
              height="120"
              viewBox="0 0 120 120"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto mb-6"
            >
              <circle
                opacity="0.5"
                cx="60"
                cy="60"
                r="60"
                fill="url(#paint0_linear_2011_12562)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M82.3441 44.5771C84.5494 46.6799 84.5494 50.0893 82.3441 52.1921L56.9323 76.4229C55.8733 77.4327 54.4369 78 52.9392 78C51.4415 78 50.0052 77.4327 48.9461 76.4229L37.652 65.6537C35.4467 63.5508 35.4467 60.1415 37.652 58.0387C39.8573 55.9358 43.4329 55.9358 45.6382 58.0387L52.9392 65.0004L74.3579 44.5771C76.5632 42.4743 80.1388 42.4743 82.3441 44.5771Z"
                fill="url(#paint1_linear_2011_12562)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2011_12562"
                  x1="60"
                  y1="0"
                  x2="60"
                  y2="120"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#E8FFE9" stopOpacity="0" />
                  <stop offset="1" stopColor="#E8FFE9" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2011_12562"
                  x1="59.998"
                  y1="43"
                  x2="59.998"
                  y2="78"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#A4F9B1" />
                  <stop offset="0.785" stopColor="#4AE26B" />
                </linearGradient>
              </defs>
            </svg>

            <h4 className="text-xl md:text-2xl font-semibold text-primary-paradise-pink-850 py-3">
              {t("resetPasswordSuccess.heading")}
            </h4>
            <p className="px-6 text-primary-paradise-pink-850 mb-6 leading-[25px] text-base sm:text-sm text-center ">
              {t("resetPasswordSuccess.description")}
              <span> {t("resetPasswordSuccess.description2")}</span>
            </p>

            <button
              type="button"
              onClick={RedirectToLogin}
              className="bg-primary-paradise-pink-900 text-sm leading-[26px] font-semibold text-white w-full rounded-lg py-3 transition duration-200"
            >
              {t("resetPasswordSuccess.loginButton")}
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}

export default observer(ResetPasswordSuccess);
