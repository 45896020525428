import { useEffect } from "react";

import styles from "./courses.module.css";
import { Footer, HomeComponents, Navbar } from "../../../components";
import { observer } from "mobx-react";
import { useStore } from "../../../store";
import { SearchBar } from "src/components/pages/home/Header/searchBar";

function Courses() {
  const { courseStore, userStore } = useStore();
  const token = localStorage.getItem("token");

  useEffect(() => {
    courseStore.getTopRatedCourses(4);
    courseStore.getDomains();
    courseStore.getCategories();
    if (!courseStore.isNew && courseStore.searchedCourses.length === 0) {
      courseStore.searchCourses(8, {});
    }
    if (!userStore.user) {
      userStore.getUser();
    }
  }, [courseStore, userStore]);

  return (
    <div>
      <Navbar />
      <div className="w-full max-w-[1440px] mx-auto px-4">
        <div className={styles.container}>
          <div className="flex justify-center">
            <SearchBar />
          </div>

          <HomeComponents.PopularCourses inSearch={true} />
          <hr />
          <HomeComponents.UpcomingCohorts page="search" />
          {!token && <HomeComponents.BecomeMember />}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default observer(Courses);
