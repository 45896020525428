import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Loading } from "src/components/shared";
import { getAvailableClassrooms } from "src/store/Classroom";
import Classrooms from "./classrooms";
import { duplicateSession, editSession } from "src/store/session";
import Container from "src/components/shared/Container";
import Filters from "./Filters";
import { toast } from "react-toastify";

interface IProps {
  data: any;
  isOnline: boolean | undefined;
  setIsOnline: (prop: boolean) => void;
  setClassroomSelection: (prop: boolean) => void;
}

export default function ClassroomSelection({
  data,
  isOnline,
  setIsOnline,
  setClassroomSelection,
}: IProps) {
  const { type } = useParams();
  const navigate = useNavigate();

  const start = type === "new" ? data?.session.start : data.start;
  const end = type === "new" ? data?.session.end : data.end;
  const startTime = type === "new" ? data?.session.startTime : data.startTime;
  const endTime = type === "new" ? data?.session.endTime : data.endTime;

  const [loading, setLoading] = useState(false);
  const [classrooms, setClassrooms] = useState<any[]>([]);
  const [filteredClassrooms, setFilteredClassrooms] = useState<any[]>([]);
  const [requestedClassrooms, setRequestedClassrooms] = useState<any[]>([]);

  const formatDate = (date: Date, options: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat("en-US", {
      ...options,
      timeZone: "UTC",
    }).format(date);
  };

  const formattedStartDate = formatDate(new Date(start), {
    month: "long",
    day: "2-digit",
  });

  const formattedEndDate = formatDate(new Date(end), {
    month: "long",
    day: "2-digit",
  });

  const formattedStartTime = formatDate(new Date(startTime), {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedEndTime = formatDate(new Date(endTime), {
    hour: "2-digit",
    minute: "2-digit",
  });

  const formattedDateRange = `From ${formattedStartDate} To ${formattedEndDate}, ${formattedStartTime} to ${formattedEndTime}`;

  const generateRandom = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getAvailableClassrooms(isOnline || false, start, end, startTime, endTime)
      .then((response) => {
        if (response !== null) {
          console.log("response", response);
          const rooms = response.rooms;
          rooms?.map((classroom: any) => {
            classroom.rating = classroom.rating || generateRandom(4.5, 5);
            classroom.ratingsCount =
              classroom.ratingsCount || generateRandom(5, 10);
          });
          setClassrooms(rooms);
          setFilteredClassrooms(rooms);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleSave = async () => {
    const newSessionData = {
      ...data,
      room: requestedClassrooms,
      actionBy: "teacher",
    };
    console.log("Payload being sent to API:", newSessionData);

    if (type === "new") {
      await duplicateSession(newSessionData).then((response) => {
        if (response !== null) {
          navigate("/teaching");
        }
      });
    } else {
      await editSession(newSessionData).then((response) => {
        if (response !== null) {
          navigate("/teaching");
        }
      });
    }
  };

  const setOnline = () => {
    setClassroomSelection(false);
    setIsOnline(true);
    toast.success("Switched to Online Class");
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <div className="flex flex-col gap-3.5 sm:gap-5 py-2.5 sm:py-5">
          <div className="flex items-center justify-between">
            <h3 className="text-body-large font-body-large-bold sm:text-subheading sm:font-subheading">
              Select a Classroom
            </h3>

            <Filters
              classrooms={classrooms}
              filteredClassrooms={filteredClassrooms}
              setFilteredClassrooms={setFilteredClassrooms}
            />
          </div>

          <Classrooms
            classrooms={filteredClassrooms}
            requestedClassrooms={requestedClassrooms}
            setRequestedClassrooms={setRequestedClassrooms}
            isOnline={isOnline}
            setOnline={setOnline}
          />

          <div className="flex gap-3 justify-center">
            <Button
              type="secondary"
              text="Cancel"
              width="fit-content"
              onClick={() => setClassroomSelection(false)}
            />
            <Button
              type="primary"
              text="Save"
              width="fit-content"
              onClick={handleSave}
            />
          </div>
        </div>
      </Container>
    </>
  );
}
