import { useEffect, useState, useCallback, FormEvent } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
}

export const Search = ({ onPlaceSelect }: Props) => {
  const map = useMap();
  const places = useMapsLibrary("places");

  const defaultLocation = localStorage.getItem("country") || "United States";

  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (!places || !map) return;

    const service = new places.PlacesService(map);

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(service);
    setSessionToken(new places.AutocompleteSessionToken());

    // Select the default location on initial load
    const detailRequestOptions = {
      query: defaultLocation,
      fields: ["geometry", "name", "formatted_address"],
    };
    const detailsRequestCallback = (
      placeDetails: google.maps.places.PlaceResult | null
    ) => {
      onPlaceSelect(placeDetails);
    };
    service?.findPlaceFromQuery(detailRequestOptions, (results) => {
      const result = results ? results[0] : null;
      detailsRequestCallback(result);
    });

    return () => setAutocompleteService(null);
  }, [map, places, onPlaceSelect]);

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }

      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;

      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ["geometry", "name", "formatted_address"],
        sessionToken,
      };

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null
      ) => {
        onPlaceSelect(placeDetails);
        setPredictionResults([]);
        setInputValue(placeDetails?.formatted_address ?? "");
        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken]
  );

  const clear = () => {
    setInputValue("");
    setPredictionResults([]);
  };

  return (
    <div className="absolute top-0 left-0 z-[1] p-2.5 w-full sm:w-[300px]">
      <div className="flex items-center justify-between p-2.5 bg-white border border-[#DADCE0] rounded-full">
        <div className="flex items-center gap-2.5">
          <img src="/images/icons/arrow-left1.svg" className="cursor-pointer" />
          <input
            className="body-small outline-none"
            value={inputValue}
            onInput={(event: FormEvent<HTMLInputElement>) =>
              onInputChange(event)
            }
            placeholder="Search for a place"
          />
        </div>
        <img
          src="/images/icons/close-black.svg"
          className="cursor-pointer"
          onClick={clear}
        />
      </div>

      {predictionResults.length > 0 && (
        <ul className="mt-2 ml-0 w-full custom_scrollbar caption-text flex flex-col gap-2 px-5 py-3 bg-white border border-primary-gray-100 rounded-xl">
          {predictionResults.map(({ place_id, description }) => {
            return (
              <li
                key={place_id}
                className="flex gap-3 cursor-pointer hover:text-primary-paradise-pink-500 transition-all duration-300"
                onClick={() => handleSuggestionClick(place_id)}
              >
                <img src="/images/icons/location-black.svg" />
                {description}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
