import { useState } from "react";
import { observer } from "mobx-react";

import { Button, Input } from "../../../../components";
import ImageInput from "src/components/shared/Input/ImageInput";
import PhoneInput from "src/components/shared/Input/PhoneInput";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  user: any;
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function HostDetails({ user, setUser, setStep }: IProps) {
  const [image, setImage] = useState<File>(user?.image || null);
  const [bio, setBio] = useState<string>(user?.description || "");
  const [phone, setPhone] = useState<number>(user?.phone || null);
  const [linkedinUrl, setLinkedinUrl] = useState<string>(
    user?.linkedinUrl || ""
  );

  const handleNextStep = async () => {
    setUser((user: any) => {
      const newUser = { ...user, image, description: bio, phone };
      if (linkedinUrl !== "") newUser.linkedinUrl = linkedinUrl;
      return newUser;
    });
    setStep(3);
  };
  HubSpotsignupStore.setPhone(phone);

  const handleBack = () => {
    setStep(1);
  };

  return (
    <div className="flex flex-col gap-5 sm:gap-6 w-full">
      <h3 className="body-large">Host Details</h3>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 p-5 sm:px-8 sm:py-6 bg-primary-purple-50 border border-primary-purple-200 rounded-3xl sm:rounded-2xl">
          <img src="/images/icons/info-black.svg" />
          <p className="text-body-small sm:text-body-medium">
            Your contact information will help us reach out to you for any
            additional information that we might need in the future.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full sm:w-[125px]">
            <ImageInput
              image={image}
              setImage={setImage}
              label="Profile Picture"
              required
            />
          </div>
          <div className="w-full sm:w-[447px]">
            <Input
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              type="textarea"
              required
              label="Bio"
              placeholder="Briefly introduce yourself to potential learners."
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <Input
            type="text"
            label="Linkedin URL (Optional)"
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
            placeholder="Paste Linkedin URL"
          />
          <PhoneInput
            value={phone}
            setValue={setPhone}
            label="Phone Number"
            required
          />
        </div>
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          type="pale"
          text="Back"
          width="fit-content"
          onClick={handleBack}
        />
        <div className="w-full sm:w-[130px]">
          <Button
            type="primary_dark"
            text="Continue"
            onClick={handleNextStep}
            isDisabled={!image || !bio || !phone}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(HostDetails);
