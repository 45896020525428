import React, { useEffect } from "react";

import { format } from "date-fns";
import { Input, Button } from "src/components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

function UpcomingClasses() {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();
  const { t } = useTranslation();

  const user: any = userStore.user;
  const course: any = courseStore.courseDetails;
  const courseId = course?.id;
  const image = course?.image?.name;

  const sessions: any[] = courseStore.searchedCourses;

  const numberOfEnrolled = sessions.reduce(
    (sum, session) => sum + (session?.subscriptionsSession?.length || 0),
    0
  );

  const [isButtonDisabled, setIsButtonDisabled] =
    React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [price, setPrice] = React.useState<number>(course?.price || 0);

  useEffect(() => {
    if (user?.email) {
      setEmail(user?.email);
    }
  }, [courseId]);

  function formatDate(isoString?: string) {
    if (!isoString) return "";
    const [rawDate] = isoString.split("T");
    const [year, month, day] = rawDate.split("-");
    const pinnedDate = new Date(+year, +month - 1, +day);
    return format(pinnedDate, "dd MMM").toUpperCase();
  }

  const getDates = (start: string | undefined, end: string | undefined) => {
    const startDate = formatDate(start);
    const endDate = formatDate(end);
    if (startDate === endDate) {
      return <span>{startDate}</span>;
    }
    return (
      <>
        <span>{startDate}</span>
        <img src="/images/icons/arrow-right-black.svg" />
        <span>{endDate}</span>
      </>
    );
  };

  const redirectToSession = (sessionId: any) => {
    if (sessionId) {
      navigate(`/class/${sessionId}`);
    }
  };

  const notifyMe = async () => {
    setIsButtonDisabled(true);
    await HubSpotsignupStore.notifyMeHubspot(email, course.title);
    setIsButtonDisabled(false);
  };

  function parseLocation(address: string): string {
    if (!address) return "";
    const parts = address
      .split(",")
      .map((part) => part.trim())
      .filter(Boolean);

    //Return last 3 parts
    return parts.slice(-3).join(", ");
  }

  return (
    <div className="border border-black border-opacity-5 rounded-[26px] text-primary-purple-850 bg-primary-gray-50">
      <img
        className="rounded-tl-[26px] rounded-tr-[26px] object-cover w-full"
        src={image ? `${env.IMAGE_API}${image}` : "/images/course-default.png"}
        alt={course?.title}
      />
      <div className="relative flex flex-col sm:gap-2 lg:gap-2.5 sm:p-3 lg:p-4">
        {numberOfEnrolled > 10 && (
          <div className="caption-text-bold px-4 py-1 text-primary-paradise-pink-600 text-center bg-primary-paradise-pink-100 border border-primary-paradise-pink-200 rounded-full">
            {numberOfEnrolled} Enrolled Last Week
          </div>
        )}
        <div className="flex justify-between items-center">
          <span className="text-caption-text font-caption-text lg:text-body-medium lg:font-body-medium">
            Upcoming Classes
          </span>
          {price !== 0 && (
            <span className="body-medium-bold">
              {formatCurrency(price, userStore.currency)}
            </span>
          )}
        </div>

        {sessions.length > 0 ? (
          <div className="flex flex-col sm:gap-2 lg:gap-4 sm:max-h-[585px] lg:max-h-[491px] custom_scrollbar">
            {sessions.map((session) => {
              return (
                <div
                  key={session?.id}
                  className="flex items-center justify-between sm:px-6 lg:px-[30px] sm:py-6 lg:py-5 h-full bg-primary-purple-50 border border-primary-purple-200 hover:border-primary-purple-300 rounded-2xl cursor-pointer group"
                  onClick={() => redirectToSession(session?.id)}
                  onMouseEnter={() => setPrice(session?.price || 0)}
                >
                  <div>
                    <div className="flex items-center gap-2.5">
                      <div className="flex items-center gap-1.5">
                        <img src="/images/icons/location-purple.svg" />
                        <span className="caption-text">
                          {session?.isOnline
                            ? "Online"
                            : parseLocation(
                                session?.sessionBooking?.[0]?.bookingRoom
                                  ?.address?.name || ""
                              )}
                        </span>
                      </div>
                      {session?.newSessionDiscount !== undefined &&
                        session?.newSessionDiscount > 0 && (
                          <span className="body-small-bold text-primary-paradise-pink-500">
                            {session?.newSessionDiscount}% OFF
                          </span>
                        )}
                    </div>
                    <div className="flex items-center gap-2 sm:text-body-medium sm:font-body-medium-bold lg:text-body-large lg:font-body-large">
                      {getDates(session?.start, session?.end)}
                    </div>
                  </div>
                  <ArrowRight className="sm:size-[18px] lg:size-6 group-hover:text-primary-purple-500 group-hover:block group-hover:translate-x-1.5 transition-all duration-500" />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-[88px] lg:gap-[43px]">
            <div className="flex flex-col items-center justify-center gap-2.5 px-5 lg:px-10 py-10 text-center">
              <img src="/images/icons/no-classes.svg" />
              <div className="flex flex-col items-center gap-1.5">
                <h3 className="body-large text-primary-paradise-pink-800">
                  No Upcoming Classes Yet...
                </h3>
                <p className="body-medium text-primary-paradise-pink-850">
                  There aren’t any classes scheduled for this course yet...
                </p>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2.5">
              <div className="flex flex-col items-center justify-center gap-2">
                <div className="text-caption-text font-caption-text lg:text-body-medium lg:font-body-medium text-center">
                  Get notified about Upcoming Classes
                </div>
                <div className="w-full">
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email Address"
                  />
                </div>
              </div>
              <Button
                type="primary_dark"
                text="Notify me"
                onClick={notifyMe}
                isDisabled={isButtonDisabled}
              />
            </div>
            {/* <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-primary-purple-50 to-transparent" /> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(UpcomingClasses);
