import { useState } from "react";
import { observer } from "mobx-react";
import { Availibility, Button, Input, Switch } from "../../../../components";
import LocationInput from "src/components/shared/Input/LocationInput";
import NumberInput from "src/components/shared/Input/NumberInput";
import MultiSelectInput from "src/components/shared/Input/MultiSelectInput";
import FilesInput from "src/components/shared/Input/FilesInput";

interface IProps {
  classroom: any;
  setClassroom: (classroom: any) => void;
  setStep: (step: number) => void;
}

interface Dates {
  name: string;
  start: string;
  end: string;
  enabled: boolean;
}

function ClassroomDetails({ classroom, setClassroom, setStep }: IProps) {
  const [name, setName] = useState<string>(classroom?.name || "");
  const [description, setDescription] = useState<string>(
    classroom?.description || ""
  );
  const [location, setLocation] = useState<any>(classroom?.location);
  const [capacity, setCapacity] = useState<number>(classroom?.capacity || 5);
  const [equipments, setEquipments] = useState<string[]>(
    classroom?.equipments || []
  );
  const [files, setFiles] = useState<File[]>(classroom?.files || []);
  const [hourlyCharge, setHourlyCharge] = useState<boolean>(
    classroom?.hourlyCharge || false
  );
  const [charge, setCharge] = useState<number>(classroom?.charge || 12);
  const [days, setDays] = useState<Dates[]>(
    classroom?.days || [
      {
        name: "Sunday",
        start: "09:00",
        end: "16:00",
        enabled: false,
      },
      {
        name: "Monday",
        start: "09:00",
        end: "16:00",
        enabled: true,
      },
      {
        name: "Tuesday",
        start: "09:00",
        end: "16:00",
        enabled: true,
      },
      {
        name: "Wednesday",
        start: "09:00",
        end: "16:00",
        enabled: true,
      },
      {
        name: "Thursday",
        start: "09:00",
        end: "16:00",
        enabled: true,
      },
      {
        name: "Friday",
        start: "09:00",
        end: "16:00",
        enabled: true,
      },
      {
        name: "Saturday",
        start: "09:00",
        end: "16:00",
        enabled: false,
      },
    ]
  );

  const [currency, setCurrency] = useState<string>("USD");

  const handleNextStep = async () => {
    setClassroom((classroom: any) => ({
      ...classroom,
      ...location,
      name,
      description,
      capacity,
      equipments,
      files,
      hourlyCharge,
      charge,
      days,
    }));
    setStep(4);
  };

  const handleBack = () => {
    setStep(2);
  };

  return (
    <div className="flex flex-col gap-5 sm:gap-6 w-full">
      <h3 className="body-large">Classroom Details</h3>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 p-5 sm:px-8 sm:py-6 bg-primary-purple-50 border border-primary-purple-200 rounded-3xl sm:rounded-2xl">
          <img src="/images/icons/info-black.svg" />
          <p className="text-body-small sm:text-body-medium">
            You can add more classrooms later as well.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <Input
            type="text"
            label="Classroom Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name of the space"
          />
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="textarea"
            required
            label="Classroom Description"
            placeholder="Write a short description about the space and it’s location."
            maxLength={300}
          />
          <div className="flex flex-col sm:flex-row gap-4 w-full">
            <div className="w-full sm:w-[352px]">
              <LocationInput
                label="Location"
                required
                value={location}
                setValue={setLocation}
                placeholder="Search for a location"
              />
            </div>
            <div className="w-full sm:w-[220px]">
              <NumberInput
                value={capacity}
                setValue={setCapacity}
                label="Max student capacity"
                required
                max={200}
                min={5}
              />
            </div>
          </div>
          <Availibility
            label="Availibity"
            days={days}
            setDays={setDays}
            required
          />
          <MultiSelectInput
            label="Available Amenities"
            values={equipments}
            setValues={setEquipments}
            required
          />
          <FilesInput
            required
            label="Classroom Images (Minimum 3)"
            files={files}
            setFiles={setFiles}
            maxFileSize={5}
            minFiles={3}
            maxFiles={6}
            types=".jpg, .jpeg, .png"
          />
          <div className="flex flex-col gap-4 p-5 w-full sm:w-[410px] mx-auto rounded-[32px] bg-primary-purple-50 border border-primary-purple-100">
            <div className="flex items-center gap-4">
              <div className={`${!hourlyCharge && "opacity-50"}`}>
                <Switch enabled={hourlyCharge} setEnabled={setHourlyCharge} />
              </div>
              <span className="body-medium">
                Charge hourly for this classroom.
              </span>
            </div>
            {hourlyCharge && (
              <div className="flex flex-col gap-0.5">
                <label
                  className="caption-text text-primary-gray-800"
                  htmlFor="charge"
                >
                  Rate per Hour
                  <span className="text-tertiary-severe-600"> *</span>
                  <div className="body-small flex flex-wrap items-center gap-0 p-2 w-[140px] text-primary-gray-800 outline outline-1 transition-all duration-300 outline-primary-gray-200 rounded-xl hover:outline-primary-paradise-pink-500">
                    <input
                      className="w-[50px] text-center outline-none"
                      type="number"
                      value={charge}
                      onChange={(e) => setCharge(+e.target.value)}
                    />
                    <select
                      className="caption-text-bold flex items-center gap-1 px-2.5 py-1 bg-primary-purple-50 border border-primary-purple-100 rounded-[7px] cursor-pointer focus:outline-none"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="CAD">CAD</option>
                      <option value="TND">TND</option>
                    </select>
                  </div>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          type="pale"
          text="Back"
          width="fit-content"
          onClick={handleBack}
        />
        <div className="w-full sm:w-[130px]">
          <Button
            type="primary_dark"
            text="Continue"
            onClick={handleNextStep}
            isDisabled={
              !name ||
              !description ||
              !location ||
              !capacity ||
              !equipments.length ||
              files.length < 3
            }
          />
        </div>
      </div>
    </div>
  );
}

export default observer(ClassroomDetails);
