import React, { useMemo } from "react";

import styles from "./steps.module.css";
import StepItem from "./StepItem";
import { useTranslation } from "react-i18next";

const Steps: React.FC = () => {
  const { t } = useTranslation();

  const steps = useMemo(
    () => [
      {
        index: 1,
        title: t("steps.title1"),
        subTitle: t("steps.subTitle1"),
        icon: "/images/icons/steps/step1.svg",
        bgColor: "var(-Primary-PradisePink-50)",
        colored: false,
      },
      {
        index: 2,
        title: t("steps.title2"),
        subTitle: t("steps.subTitle2"),
        icon: "/images/icons/steps/step2.svg",
        bgColor: "var(-Primary-PradisePink-50)",
        colored: false,
      },
      {
        index: 3,
        title: t("steps.title3"),
        subTitle: t("steps.subTitle3"),
        icon: "/images/icons/steps/step3.svg",
        bgColor: "var(-Primary-Purple-50)",
        colored: true,
      },
    ],
    [t]
  );

  return (
    <div className={styles.steps_container}>
      <div className={styles.steps}>
        <div className={styles.steps_list}>
          {steps.map((step) => (
            <StepItem
              key={step.index}
              title={step.title}
              subTitle={step.subTitle}
              icon={step.icon}
              index={step.index}
              colored={step.colored}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
