import { useMap } from "@vis.gl/react-google-maps";
import React, { useEffect, useRef } from "react";

interface Props {
  place: google.maps.places.PlaceResult | null;
  classrooms: any[];
  setViewportClassrooms?: (value: any) => void;
}

const MapHandler = ({ place, classrooms, setViewportClassrooms }: Props) => {
  const map = useMap();

  const classroomsRef = useRef(classrooms);

  useEffect(() => {
    classroomsRef.current = classrooms;
  }, [classrooms]);

  // Filter classrooms within the viewport
  useEffect(() => {
    if (!map || !setViewportClassrooms) return;

    const handleBoundsChanged = () => {
      const bounds = map.getBounds();
      if (bounds) {
        const filteredClassrooms = classroomsRef.current.filter((classroom) => {
          const { lat, lng } = classroom.location;
          return bounds.contains(new google.maps.LatLng(lat, lng));
        });
        setViewportClassrooms(filteredClassrooms);
      }
    };
    map.addListener("bounds_changed", handleBoundsChanged);

    return () => {
      google.maps.event.clearListeners(map, "bounds_changed");
    };
  }, [map, setViewportClassrooms]);

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
};

export default React.memo(MapHandler);
