import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Input } from "../../../../components";
import PhoneInput from "src/components/shared/Input/PhoneInput";
import Select from "src/components/shared/Input/Select";
import { useStore } from "../../../../store";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  user: any;
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function ProfessionalDetails({ user, setUser, setStep }: IProps) {
  const { courseStore } = useStore();
  // const categories = courseStore?.categories;

  const [phone, setPhone] = useState<number>(user?.phone || null);
  const [linkedinUrl, setLinkedinUrl] = useState<string>(
    user?.linkedinUrl || ""
  );
  const [position, setPosition] = useState<string>(user?.position || "");
  const [expertise, setExpertise] = useState<any>(user?.expertise || null);
  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    courseStore.getCategories();
    courseStore.getAllDomains().then((response) => {
      if (response) {
        console.log("response", response);
        
        setCategories(response);
      }
    });
  }, []);

  const handleNextStep = async () => {

    setUser((user: any) => ({
      ...user,
      phone,
      linkedinUrl,
      position,
      expertise,
    }));
    setStep(4);
    HubSpotsignupStore.setPhone(phone);

  };

  const handleBack = () => {
    setStep(2);
  };

  return (
    <div className="flex flex-col gap-5 sm:gap-6 w-full">
      <h3 className="body-large">Professional Details</h3>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 p-5 sm:px-8 sm:py-6 bg-primary-purple-50 border border-primary-purple-200 rounded-3xl sm:rounded-2xl">
          <img src="/images/icons/info-black.svg" />
          <p className="text-body-small sm:text-body-medium">
            Only your{" "}
            <span className="font-body-small-bold sm:font-body-medium-bold">
              Position & LinkedinUrl
            </span>{" "}
            will be visible to the public. The rest of the details you provide
            here will be private.
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <Input
              type="text"
              label="Linkedin Profile URL"
              required
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              placeholder="Paste your Linkedin URL"
            />
            <PhoneInput
              value={phone}
              setValue={setPhone}
              label="Phone Number"
              required
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:w-1/2">
              <Input
                type="text"
                label="Position"
                required
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                placeholder="Your work position"
              />
            </div>
            <div className="w-full sm:w-1/2">
              <Select
                label="Area of Expertise"
                placeholder="Choose a field"
                options={categories}
                value={expertise}
                setValue={setExpertise}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          type="pale"
          text="Back"
          width="fit-content"
          onClick={handleBack}
        />
        <div className="w-full sm:w-[130px]">
          <Button
            type="primary_dark"
            text="Continue"
            onClick={handleNextStep}
            isDisabled={!linkedinUrl || !phone || !position || !expertise}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(ProfessionalDetails);
