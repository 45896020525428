import React, { KeyboardEventHandler, useRef, useState } from "react";

interface InputProps {
  label: string;
  required?: boolean;
  values: string[];
  setValues: (values: string[]) => void;
}

const MultiSelectInput: React.FC<InputProps> = ({
  label,
  required,
  values,
  setValues,
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleDelete = (index: number) => {
    setValues(values.filter((_, i) => i !== index));
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === "Backspace" && !inputValue) {
      setInputValue(values[values.length - 1] || "");
      setValues(values.slice(0, values.length - 1));
      return;
    }
    if (!inputValue) return;
    switch (e.key) {
      case "Enter":
      case "Tab":
        setValues([...values, inputValue]);
        setInputValue("");
        e.preventDefault();
        break;
    }
  };

  return (
    <div className="flex flex-col gap-0.5">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}
      </label>
      <div
        onClick={() => inputRef.current?.focus()}
        className="body-small flex flex-wrap items-center gap-1 p-1 w-full min-h-12 text-primary-gray-800 outline outline-1 transition-all duration-300 outline-primary-gray-200 rounded-xl hover:outline-primary-paradise-pink-500 cursor-text"
      >
        {values.map((value, index) => (
          <div
            key={index}
            className="flex items-center justify-center gap-1.5 px-3 py-2 bg-primary-paradise-pink-100 hover:bg-primary-paradise-pink-200 rounded-lg transition-all duration-300"
          >
            <span className="caption-text text-primary-paradise-pink-500">
              {value}
            </span>
            <img
              src="/images/icons/close-pink.svg"
              className="cursor-pointer"
              onClick={() => handleDelete(index)}
            />
          </div>
        ))}

        <input
          ref={inputRef}
          type="text"
          className="outline-none w-fit px-1 py-2"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default MultiSelectInput;
