import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Button from "../Button";

interface InputProps {
  value: number | undefined;
  label: string;
  required?: boolean;
  setValue: (value: number) => void;
}

type CountryType = {
  value: number;
  label: string;
  iso: string;
  emoji: string;
  phoneCode: string;
};

const PhoneInput: React.FC<InputProps> = ({
  value,
  label,
  required,
  setValue,
}) => {
  const defaultCountry = {
    value: 224,
    label: "Tunisia",
    iso: "tn",
    emoji: "🇹🇳",
    phoneCode: "+216 ",
  };

  const [search, setSearch] = useState<string>("");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] =
    useState<CountryType>(defaultCountry);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    defaultCountry.value
  );

  useEffect(() => {
    const countriesFromLocalStorage = localStorage.getItem("countries");
    if (countriesFromLocalStorage) {
      const countries = JSON.parse(countriesFromLocalStorage).map(
        (country: any) => {
          return {
            value: country.c_id,
            label: country.name,
            iso: country.iso2.toLowerCase(),
            emoji: country.emoji,
            phoneCode: "+" + country.phone_code + " ",
          };
        }
      );
      setCountryList(countries);
    }
  }, []);

  const handleChange = (value: string) => {
    const phone = parseInt(value.split(" ")[1]);
    setValue(phone);
  };

  const handleCountrySelection = () => {
    const country: any = countryList.find(
      (country: any) => country.value.toString() === selectedIndex.toString()
    );

    if (country) {
      setSelectedCountry(country);
    }
    setSearch("");
    setIsPopupOpen(false);
  };

  const closePopup = () => {
    setSelectedIndex(defaultCountry.value);
    setSearch("");
    setIsPopupOpen(false);
  };

  return (
    <div className="flex flex-col gap-0.5">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div className="flex gap-3 sm:gap-2">
        <div
          className="body-small flex items-center gap-2 px-7 py-3 text-primary-gray-800 outline outline-1 outline-primary-gray-200 rounded-xl transition-all duration-200 ease-in-out hover:outline-primary-paradise-pink-500 cursor-pointer"
          onClick={() => setIsPopupOpen(true)}
        >
          <span>{selectedCountry.emoji}</span>
          <span>{selectedCountry.label}</span>
        </div>
        <input
          className="body-small flex items-center gap-2 w-full sm:w-[184px] px-4 py-3 text-primary-gray-800 outline outline-1 outline-primary-gray-200 rounded-xl hover:outline-primary-paradise-pink-500 focus:outline-2 focus:outline-primary-paradise-pink-500"
          type="text"
          required
          value={selectedCountry.phoneCode + (value ? value : "")}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>

      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        style={modalStyles}
      >
        <div className="w-[350px] sm:w-[478px]">
          <div className="flex justify-between items-center px-5 py-3.5 border-b border-primary-gray-100">
            <h3 className="body-medium-bold text-primary-purple-800">
              Select a Country
            </h3>
            <img
              className="cursor-pointer"
              src="/images/icons/close.svg"
              onClick={closePopup}
            />
          </div>
          <div className="flex flex-col gap-3.5 sm:gap-4 p-3.5 sm:px-5">
            <div className="relative">
              <input
                type="text"
                className="body-medium flex items-center gap-2 w-full pl-14 px-4 py-3 text-primary-gray-800 outline outline-1 outline-primary-gray-200 rounded-full hover:outline-primary-paradise-pink-500 focus:outline-2 focus:outline-primary-paradise-pink-500"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autoFocus
              />
              <img
                className="absolute top-3.5 left-5 cursor-text"
                src="/images/icons/search-black.svg"
                width={20}
                height={20}
              />
            </div>
            <div className="custom_scrollbar flex flex-col gap-1 max-h-[314px] overflow-y-scroll">
              {countryList
                .filter((country: any) =>
                  country.label.toLowerCase().includes(search.toLowerCase())
                )
                .map((country: any, index: number) => (
                  <div
                    key={index}
                    className={`body-medium flex items-center gap-4 px-4 py-3 text-primary-gray-800 cursor-pointer border rounded-xl ${
                      selectedIndex === country.value
                        ? "border-primary-purple-200 bg-primary-purple-50"
                        : "border-primary-gray-50 "
                    }`}
                    onClick={() => setSelectedIndex(country.value)}
                  >
                    <span>{country.emoji}</span>
                    <span>{country.label}</span>
                    <span className="body-small text-primary-gray-500 ml-auto">
                      ({country.phoneCode})
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="p-5 border-t border-primary-gray-200">
            <Button
              type="primary_dark"
              text="Select"
              onClick={handleCountrySelection}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "24px",
    border: "1px solid var(--Primary-Gray-100, rgba(245, 245, 245, 1))",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "var(--Primary-Gray-50)",
    // margin: "20px",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: "30",
  },
};

export default PhoneInput;
